import { axios } from 'data';
import { Uuid } from 'types/common';
import { CostParams, CostsParams, CostsResponse } from 'types/services';

import { preparePaginatableParams, preparePaginatedData, prepareTimezonableParams } from './helpers';

const getCosts = async (params: CostsParams = {}) => {
  const response = await axios.get<CostsResponse>('/payments/admin/cost', {
    params: preparePaginatableParams(params),
  });

  return preparePaginatedData(response.data);
};

const createCost = async (params: CostParams) => {
  params = prepareTimezonableParams(params);

  await axios.post<never>('/payments/admin/cost', params);
};

const updateCost = async (id: Uuid, params: Partial<CostParams>) => {
  params = prepareTimezonableParams(params);

  await axios.patch<never>(`/payments/admin/cost/${id}`, params);
};

const activateCost = async (id: Uuid) => {
  await updateCost(id, { active: true });
};

const deactivateCost = async (id: Uuid) => {
  await updateCost(id, { active: false });
};

const deleteCost = async (id: Uuid) => {
  await axios.delete<never>(`/payments/admin/cost/${id}`);
};

const costService = {
  getCosts,
  createCost,
  updateCost,
  activateCost,
  deactivateCost,
  deleteCost,
};

export default costService;

import React, { FC, ReactNode } from 'react';

import { Override } from 'types/common';
import { ButtonProps } from 'types/components';

import Button from '../Button';
import Flex from '../Flex';
import Tooltip from '../Tooltip';

import styles from './styles.module.css';

type ActionsButton = Override<ButtonProps, {
  title: string;
  icon: ReactNode;
}>;

type ActionsProps = {
  buttons: ActionsButton[];
};

const Actions: FC<ActionsProps> = ({ buttons }) => (
  <Flex className={styles.actions} gap="small" align="center">
    {buttons.map(({ title, ...props }, index) => (
      <Tooltip key={index} title={title}>
        <Button type="default" size="middle" {...props} />
      </Tooltip>
    ))}
  </Flex>
);

export default Actions;

import { config } from 'data';
import { userService } from 'services';
import { useQuery } from 'hooks';
import { UsersParams } from 'types/services';

const useUsersQuery = (params?: UsersParams) => useQuery({
  queryKey: [config.USERS_QUERY_KEY, params],
  queryFn: () => userService.getUsers(params),
});

export default useUsersQuery;

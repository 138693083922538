import { config } from 'data';
import { providerService } from 'services';
import { useQuery } from 'hooks';
import { Uuid } from 'types/common';

const useProviderQuery = (id?: Uuid) => useQuery({
  queryKey: [config.PROVIDER_QUERY_KEY, id],
  queryFn: () => id ? providerService.getProvider(id) : null,
});

export default useProviderQuery;

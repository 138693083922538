import React, { FC } from 'react';
import { Spin } from 'antd';

import { Logo } from 'components/icons';

import styles from './styles.module.css';

type PreloaderProps = {
  active?: boolean;
};

const Preloader: FC<PreloaderProps> = ({ active }) => (
  <Spin
    rootClassName={styles.root}
    indicator={<Logo className={styles.logo} />}
    size="large"
    spinning={active}
    fullscreen
  />
);

export default Preloader;

import React, { ChangeEventHandler, FC, useState } from 'react';
import { Country, getCountryCallingCode, isSupportedCountry, parsePhoneNumber } from 'react-phone-number-input';

import { config } from 'data';
import { useFormInstance, useLang } from 'hooks';
import { useAllCountriesQuery } from 'hooks/queries';
import { Nullish } from 'types/common';
import { InputProps } from 'types/components';

import Select from '../Select';
import Space from '../Space';

import styles from './styles.module.css';

import Input from '.';

const getPhonePrefix = (country: string) => `+${getCountryCallingCode(country as Country)}`;

const getPhoneCountry = (phone: string) => {
  const number = parsePhoneNumber(phone, config.DEFAULT_COUNTRY as Country);
  let country = number?.country;

  if (!country) {
    country = number?.getPossibleCountries().shift();
  }

  return country ?? config.DEFAULT_COUNTRY;
};

const formatPhoneValue = (value: string, country: string) => {
  const prefix = getPhonePrefix(country);
  let phone = value.replace(/[^\d+]/g, '');

  if (phone && !phone.startsWith(prefix) && !phone.startsWith('+')) {
    phone = `${prefix}${phone}`;
  }

  if (phone && !phone.startsWith('+')) {
    phone = `+${phone}`;
  }

  return phone;
};

const PhoneInput: FC<InputProps> = ({
  id,
  className,
  type = 'tel',
  value,
  placeholder,
  disabled,
  readOnly,
  onChange,
  ...props
}) => {
  const form = useFormInstance();
  const lang = useLang();

  const [country, setCountry] = useState(getPhoneCountry(value as string ?? ''));

  const countriesQuery = useAllCountriesQuery();

  const handleCountryChange = (country: string) => {
    const prefix = getPhonePrefix(country);
    const phone = form.getFieldValue(id) as Nullish<string>;

    if (!phone || !phone.startsWith(prefix)) {
      form.setFieldValue(id, prefix);
    }

    setCountry(country);
  };

  const handlePhoneChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    event.target.value = formatPhoneValue(event.target.value, country);

    if (onChange) {
      onChange(event);
    }

    setCountry(getPhoneCountry(event.target.value));
  };

  return (
    <Space.Compact className={className} block>
      <Select
        className={styles.phone__select}
        value={country}
        options={countriesQuery.data?.filter((country) => isSupportedCountry(country.code)).map((country) => ({
          value: country.code,
          label: (
            <div className={styles.phone__select__option}>
              <span className={styles.phone__select__name}>{country.name}</span>
              <span className={styles.phone__select__code}>{country.code}</span>
              <span className={styles.phone__select__prefix}>{getPhonePrefix(country.code)}</span>
            </div>
          ),
          title: country.name,
        }))}
        loading={countriesQuery.isFetching}
        disabled={disabled || readOnly}
        popupMatchSelectWidth={false}
        onChange={handleCountryChange}
      />
      <Input
        id={id}
        className={styles.phone__input}
        type={type}
        value={value}
        placeholder={placeholder ?? lang.get('common.form.phone.placeholder')}
        disabled={disabled}
        readOnly={readOnly}
        onChange={handlePhoneChange}
        {...props}
      />
    </Space.Compact>
  );
};

export default PhoneInput;

import React, { FC } from 'react';

import { useLang } from 'hooks';
import { Mail } from 'components/icons';
import { InputProps } from 'types/components';

import Input from '.';

const EmailInput: FC<InputProps> = ({
  type = 'email',
  placeholder,
  prefix = <Mail />,
  ...props
}) => {
  const lang = useLang();

  return (
    <Input
      type={type}
      placeholder={placeholder ?? lang.get('common.form.email.placeholder')}
      prefix={prefix}
      {...props}
    />
  );
};

export default EmailInput;

import { addMethod, StringSchema } from 'yup';

import { createError } from '../helpers';

addMethod(StringSchema, 'country', function validate() {
  const COUNTRY_REGEXP = /^[a-z]{2}$/i;

  return this.trim()
    .uppercase()
    .test((value, context) => {
      if (!value || value.match(COUNTRY_REGEXP)) {
        return true;
      }

      return createError(context, 'country');
    });
});

import React, { FC } from 'react';
import cx from 'classnames';

import { config } from 'data';
import { Calendar } from 'components/icons';
import { DateRangePickerProps } from 'types/components';

import { CustomDatePicker } from '../DatePicker';

import styles from './styles.module.css';

const DateRangePicker: FC<DateRangePickerProps> = ({
  className,
  format = config.DATE_FORMAT,
  size = 'large',
  separator = '–',
  suffixIcon = <Calendar />,
  inputReadOnly = true,
  ...props
}) => (
  <CustomDatePicker.RangePicker
    className={cx(styles.root, className)}
    format={format}
    size={size}
    separator={separator}
    suffixIcon={suffixIcon}
    inputReadOnly={inputReadOnly}
    {...props}
  />
);

export default DateRangePicker;

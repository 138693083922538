import { config, moment } from 'data';
import { Paginatable, PaginatedData, PaginatedResponse, Timezonable } from 'types/services';

const fetchPaginatedResponseFully = async <DataType, ParamsType>(
  callback: (params: ParamsType & Paginatable) => Promise<PaginatedData<DataType>>,
  params: ParamsType & Paginatable = {} as ParamsType & Paginatable,
): Promise<DataType[]> => {
  let data: DataType[] = [];
  let total = 0;
  let previousLength = 0;

  params.page = 1;
  params.size = 100;

  do {
    const response = await callback(params);

    total = response.total;
    previousLength = data.length;
    data = data.concat(response.list);

    params.page++;
  } while (data.length !== previousLength && data.length < total);

  return data;
};

const preparePaginatableParams = <Type extends Paginatable = Paginatable>(params: Type): Type => {
  const page = params.page ?? 1;
  const size = params.size ?? config.DEFAULT_PAGINATION_LIMIT;

  return {
    ...params,
    page,
    size,
  };
};

const preparePaginatedData = <Type>(response: PaginatedResponse<Type>): PaginatedData<Type> => {
  return {
    list: response.content,
    page: response.pageable.pageNumber,
    size: response.pageable.pageSize,
    total: response.totalElements,
  };
};

const prepareTimezonableParams = <Type>(params: Type & Timezonable): Type & Timezonable => {
  if (!params.timezone) {
    params.timezone = moment.tz(moment.tz.guess()).format('Z');
  }

  return params;
};

export {
  fetchPaginatedResponseFully,
  preparePaginatableParams,
  preparePaginatedData,
  prepareTimezonableParams,
};

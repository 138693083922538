import { AuthChallenge } from 'types/services';

import ServiceException from './service-exception';

class NewPasswordRequiredException extends ServiceException {
  data: AuthChallenge;

  constructor(message: string, data: AuthChallenge) {
    super(message);

    this.data = data;
  }
}

export default NewPasswordRequiredException;

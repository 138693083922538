import React, { FC } from 'react';

import { IconProps } from 'types/components';

import Icon from './Icon';

const Upload: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path d="M13.333 13.333 9.999 10m0 0-3.333 3.333M9.999 10v7.5m6.992-2.175A4.167 4.167 0 0 0 14.999 7.5h-1.05A6.666 6.666 0 1 0 2.5 13.583" />
  </Icon>
);

export default Upload;

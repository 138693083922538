import isURL, { IsURLOptions } from 'validator/es/lib/isURL';
import { addMethod, StringSchema } from 'yup';

import { config } from 'data';

import { createError } from '../helpers';

addMethod(StringSchema, 'website', function validate() {
  const options: IsURLOptions = {
    protocols: ['http', 'https'],
    allow_query_components: false,
    require_protocol: true,
  };

  return this.trim()
    .max(config.WEBSITE_MAX_LENGTH)
    .test((value, context) => {
      if (!value || isURL(value, options)) {
        return true;
      }

      return createError(context, 'website');
    });
});

import React, { FC } from 'react';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/es/input';

const TextArea: FC<TextAreaProps> = ({
  size = 'large',
  autoSize = { minRows: 5 },
  ...props
}) => (
  <Input.TextArea
    size={size}
    autoSize={autoSize}
    {...props}
  />
);

export default TextArea;

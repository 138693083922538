import React, { FC, useEffect } from 'react';
import map from 'lodash/map';

import { config } from 'data';
import { businessAccountService } from 'services';
import { useForm, useLang, useModal, useMutation, useQueryInvalidate } from 'hooks';
import { useAllCurrenciesQuery, useBusinessAccountCurrenciesQuery } from 'hooks/queries';
import { CurrenciesModal } from 'components/layout';
import { Button, Form, Input, Modal, PopconfirmButton, Select } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { BusinessAccount, BusinessAccountStatus, BusinessAccountType, CurrencyCode } from 'types/models';

import ApiModal from './ApiModal';
import FeeModal from './FeeModal';

type BusinessAccountModalProps = ModalBaseProps & {
  businessAccount: BusinessAccount;
};

type FormValues = BusinessAccount;

const BusinessAccountModal: FC<BusinessAccountModalProps> = ({
  businessAccount,
  open,
  onClose,
}) => {
  const form = useForm<FormValues>();
  const lang = useLang();
  const apiModal = useModal();
  const feeModal = useModal();
  const currenciesModal = useModal();
  const queryInvalidate = useQueryInvalidate();

  const businessAccountCurrenciesQuery = useBusinessAccountCurrenciesQuery(businessAccount.id);
  const currenciesQuery = useAllCurrenciesQuery({ active: true });

  const invalidateBusinessAccountQueries = async () => {
    await queryInvalidate([config.BUSINESS_ACCOUNTS_QUERY_KEY]);
    await queryInvalidate([config.BUSINESS_ACCOUNT_QUERY_KEY, businessAccount.id]);
  };

  const activateBusinessAccountMutation = useMutation({
    mutationFn: () => businessAccountService.activateBusinessAccount(businessAccount.id),
    onSuccess: invalidateBusinessAccountQueries,
    successNotification: lang.get('businessAccount.modal.activateSuccess', { name: businessAccount.name }),
  });

  const deactivateBusinessAccountMutation = useMutation({
    mutationFn: () => businessAccountService.deactivateBusinessAccount(businessAccount.id),
    onSuccess: invalidateBusinessAccountQueries,
    successNotification: lang.get('businessAccount.modal.deactivateSuccess', { name: businessAccount.name }),
  });

  const enableBusinessAccountApiMutation = useMutation({
    mutationFn: () => businessAccountService.enableBusinessAccountApi(businessAccount.id),
    onSuccess: invalidateBusinessAccountQueries,
    successNotification: lang.get('businessAccount.modal.api.enableSuccess', { name: businessAccount.name }),
  });

  const disableBusinessAccountApiMutation = useMutation({
    mutationFn: () => businessAccountService.disableBusinessAccountApi(businessAccount.id),
    onSuccess: invalidateBusinessAccountQueries,
    successNotification: lang.get('businessAccount.modal.api.disableSuccess', { name: businessAccount.name }),
  });

  const updateBusinessAccountCurrenciesMutation = useMutation({
    mutationFn: (codes: CurrencyCode[]) => businessAccountService.updateBusinessAccountCurrencies(businessAccount.id, codes),
    onSuccess: () => queryInvalidate([config.BUSINESS_ACCOUNT_CURRENCIES_QUERY_KEY, businessAccount.id]),
    successNotification: lang.get('businessAccount.modal.currencies.success', { name: businessAccount.name }),
  });

  const handleActivate = async () => {
    await activateBusinessAccountMutation.mutateAsync();

    onClose();
  };

  const handleDeactivate = async () => {
    await deactivateBusinessAccountMutation.mutateAsync();

    onClose();
  };

  const handleEnableApi = async () => {
    await enableBusinessAccountApiMutation.mutateAsync();

    onClose();
  };

  const handleDisableApi = async () => {
    await disableBusinessAccountApiMutation.mutateAsync();

    onClose();
  };

  useEffect(() => {
    if (open && businessAccount) {
      form.setFieldsValue(businessAccount);
    }
  }, [businessAccount, open, form]);

  useEffect(() => {
    if (!open) {
      apiModal.close();
      feeModal.close();
      currenciesModal.close();
    }
  }, [open, apiModal, feeModal, currenciesModal]);

  return (
    <Modal
      title={lang.get('businessAccount.modal.viewTitle', { name: businessAccount.name })}
      caption={lang.get('businessAccount.modal.viewCaption')}
      cancelText={lang.get('common.close')}
      okButtonProps={{ hidden: true }}
      width="sm"
      open={open}
      onCancel={onClose}
    >

      <Form form={form}>

        <Form.ActionsItem>
          <Button type="default" onClick={feeModal.open}>
            {lang.get('businessAccount.modal.actions.manageFee')}
          </Button>
          {businessAccount.status === BusinessAccountStatus.ACTIVE ? (
            <PopconfirmButton
              title={lang.get('businessAccount.modal.deactivateTitle')}
              danger
              loading={deactivateBusinessAccountMutation.isPending}
              onConfirm={handleDeactivate}
            >
              {lang.get('businessAccount.modal.actions.deactivate')}
            </PopconfirmButton>
          ) : (
            <PopconfirmButton
              title={lang.get('businessAccount.modal.activateTitle')}
              type="primary"
              ghost
              loading={activateBusinessAccountMutation.isPending}
              onConfirm={handleActivate}
            >
              {lang.get('businessAccount.modal.actions.activate')}
            </PopconfirmButton>
          )}
        </Form.ActionsItem>
        <Form.ActionsItem label={lang.choice('businessAccount.modal.currencies.label', businessAccountCurrenciesQuery.data?.length ?? 0)}>
          <Button type="default" loading={businessAccountCurrenciesQuery.isFetching} onClick={currenciesModal.open}>
            {lang.get('businessAccount.modal.currencies.button')}
          </Button>
        </Form.ActionsItem>
        <Form.ActionsItem label={lang.get('businessAccount.modal.api.label')}>
          {businessAccount.enabledApi ? (
            <>
              <Button type="default" onClick={apiModal.open}>
                {lang.get('businessAccount.modal.actions.manageApi')}
              </Button>
              <PopconfirmButton
                title={lang.get('businessAccount.modal.api.disableTitle')}
                description={lang.get('businessAccount.modal.api.disableCaption')}
                danger
                loading={disableBusinessAccountApiMutation.isPending}
                onConfirm={handleDisableApi}
              >
                {lang.get('businessAccount.modal.api.disableButton')}
              </PopconfirmButton>
            </>
          ) : (
            <PopconfirmButton
              title={lang.get('businessAccount.modal.api.enableTitle')}
              description={lang.get('businessAccount.modal.api.enableCaption')}
              type="primary"
              ghost
              loading={enableBusinessAccountApiMutation.isPending}
              onConfirm={handleEnableApi}
            >
              {lang.get('businessAccount.modal.api.enableButton')}
            </PopconfirmButton>
          )}
        </Form.ActionsItem>

        <Form.Divider />

        <Form.Item name="name" label={lang.get('businessAccount.modal.name.label')}>
          <Input placeholder={lang.get('businessAccount.modal.name.placeholder')} readOnly />
        </Form.Item>
        <Form.Item name="type" label={lang.get('businessAccount.modal.type.label')}>
          <Select
            placeholder={lang.get('businessAccount.modal.type.placeholder')}
            options={Object.values(BusinessAccountType).map((type) => ({
              value: type,
              label: lang.get(`businessAccount.types.${type.toLowerCase()}`),
            }))}
            disabled
          />
        </Form.Item>
        <Form.Item name="email" label={lang.get('common.form.email.label')}>
          <Input.Email readOnly />
        </Form.Item>

      </Form>

      <ApiModal
        businessAccount={businessAccount}
        open={apiModal.opened}
        onClose={apiModal.close}
      />

      <FeeModal
        businessAccount={businessAccount}
        open={feeModal.opened}
        onClose={feeModal.close}
      />

      <CurrenciesModal
        currencyCodes={map(currenciesQuery.data, 'code')}
        initialCurrencyCodes={businessAccountCurrenciesQuery.data ?? []}
        open={currenciesModal.opened}
        onSubmit={updateBusinessAccountCurrenciesMutation.mutateAsync}
        onClose={currenciesModal.close}
      />

    </Modal>
  );
};

export default BusinessAccountModal;

import React, { ChangeEventHandler, FC } from 'react';
import debounce from 'lodash/debounce';
import cx from 'classnames';

import { useLang } from 'hooks';
import { Search as SearchIcon } from 'components/icons';
import { Input } from 'components/ui';
import { InputProps } from 'types/components';

import styles from './styles.module.css';

const Search: FC<InputProps> = ({
  className,
  type = 'search',
  placeholder,
  prefix = <SearchIcon />,
  onChange,
  ...props
}) => {
  const lang = useLang();

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    event.target.value = event.target.value.trim();

    if (onChange) {
      onChange(event);
    }
  };

  return (
    <Input
      className={cx(styles.search, className)}
      type={type}
      placeholder={placeholder ?? lang.get('common.search')}
      prefix={prefix}
      allowClear
      onChange={debounce(handleChange, 500)}
      {...props}
    />
  );
};

export default Search;

import { axios, config } from 'data';
import { event, storage } from 'helpers';
import { Nullable, Uuid } from 'types/common';

import {
  AuthChallenge,
  AuthChangePasswordParams,
  AuthCredentials,
  AuthEndpoint,
  AuthEvent,
  AuthLoginParams,
  AuthLoginResponse,
  AuthRefreshAccessTokenParams,
  AuthRefreshAccessTokenResponse,
  AuthResetPasswordConfirmParams,
  AuthResetPasswordParams,
} from 'types/services';

import { NewPasswordRequiredException, ServiceException } from './exceptions';

const listener = event.createListener();

const getCredentials = () => {
  return storage.get<AuthCredentials>(config.AUTH_STORAGE_KEY);
};

const setCredentials = (credentials: Nullable<AuthCredentials>) => {
  storage.set(config.AUTH_STORAGE_KEY, credentials);

  listener.trigger(AuthEvent.CREDENTIALS);
};

const getAccessToken = () => {
  const credentials = getCredentials();

  return credentials && credentials.accessToken;
};

const getRefreshToken = () => {
  const credentials = getCredentials();

  return credentials && credentials.refreshToken;
};

const getAuthChallenge = async (hash: Uuid) => {
  const response = await axios.get<AuthChallenge>(`/core/admin/auth/${hash}`);

  return response.data;
};

const login = async (params: AuthLoginParams) => {
  const response = await axios.post<AuthLoginResponse>(AuthEndpoint.LOGIN, params);
  const { data } = response;

  if ('challengeName' in data) {
    switch (data.challengeName) {
      case 'NEW_PASSWORD_REQUIRED':
        throw new NewPasswordRequiredException('New password is required.', data);
      default:
        throw new ServiceException(`Unsupported challenge "${data.challengeName}".`);
    }
  }

  setCredentials(data);
};

const logout = async () => {
  try {
    await axios.get<never>(AuthEndpoint.LOGOUT);
  } catch (error) {
    // ignore
  } finally {
    localStorage.clear();
    sessionStorage.clear();

    setCredentials(null);
  }
};

const refreshAccessToken = async () => {
  const params: AuthRefreshAccessTokenParams = { refreshToken: getRefreshToken() ?? '' };
  const response = await axios.post<AuthRefreshAccessTokenResponse>(AuthEndpoint.REFRESH_ACCESS_TOKEN, params);

  setCredentials({ ...params, ...response.data });
};

const changePassword = async (params: AuthChangePasswordParams) => {
  const response = await axios.post<AuthCredentials>(AuthEndpoint.CHANGE_PASSWORD, params);

  setCredentials(response.data);
};

const resetPassword = async (params: AuthResetPasswordParams) => {
  await axios.post<never>(AuthEndpoint.RESET_PASSWORD, params);
};

const resetPasswordConfirm = async (params: AuthResetPasswordConfirmParams) => {
  const response = await axios.post<AuthCredentials>(AuthEndpoint.RESET_PASSWORD_CONFIRM, params);

  setCredentials(response.data);
};

const authService = {
  on: listener.on,
  off: listener.off,
  getAccessToken,
  getAuthChallenge,
  login,
  logout,
  refreshAccessToken,
  changePassword,
  resetPassword,
  resetPasswordConfirm,
};

export default authService;

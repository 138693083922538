import { useEffect } from 'react';

import { PaginatedData } from 'types/services';

import useQuery from './query';
import useTable from './table';

const useTableQuery = <
  DataType,
  QueryData extends PaginatedData<DataType> = PaginatedData<DataType>,
>(
  table: ReturnType<typeof useTable<DataType>>,
  query: ReturnType<typeof useQuery<QueryData>>,
) => {
  useEffect(() => {
    if (!query.isFetching && query.data) {
      const { list, page, size, total } = query.data;

      table.setData(list);

      table.setPagination({
        current: page,
        pageSize: size,
        total: total,
      });
    }
  }, [table, query.isFetching, query.data]);
};

export default useTableQuery;

import React, { ChangeEventHandler, useState } from 'react';

import { config, moment } from 'data';
import { formatter } from 'helpers';
import { useLang, useModal, useTable, useTableQuery } from 'hooks';
import { useAllUsersQuery, useAuditLogsQuery } from 'hooks/queries';
import { DashboardLayout } from 'layouts';
import { Eye } from 'components/icons';
import { TableView, UserInfo } from 'components/layout';
import { DateRangePicker, Flex, Select, Table, Text } from 'components/ui';
import { Uuid } from 'types/common';
import { DateRangePickerChangeHandler, SelectChangeHandler, TableColumns } from 'types/components';
import { AuditLog, AuditLogAction, AuditLogObject } from 'types/models';

import styles from './styles.module.css';

import Modal from './Modal';

type TableParams = {
  dateFrom: string;
  dateTo: string;
  userId: Uuid;
  search: string;
  action: '' | AuditLogAction;
  object: '' | AuditLogObject;
};

const initialTableParams: TableParams = {
  dateFrom: '',
  dateTo: '',
  userId: '',
  search: '',
  action: '',
  object: '',
};

const AuditLogsPage = () => {
  const lang = useLang();
  const modal = useModal();
  const table = useTable<AuditLog, TableParams>([config.AUDIT_LOGS_QUERY_KEY], initialTableParams);

  const [log, setLog] = useState<AuditLog>();

  const usersQuery = useAllUsersQuery();

  const logsQuery = useAuditLogsQuery({
    page: table.page,
    dateFrom: table.params.dateFrom || undefined,
    dateTo: table.params.dateTo || undefined,
    userId: table.params.userId || undefined,
    search: table.params.search || undefined,
    action: table.params.action || undefined,
    objectType: table.params.object || undefined,
  });

  const handleFilterSearchChange: ChangeEventHandler<HTMLInputElement> = (event) => table.setParam('search', event.target.value);

  const handleFilterUserIdChange: SelectChangeHandler = (value) => table.setParam('userId', value);

  const handleFilterActionChange: SelectChangeHandler = (value) => table.setParam('action', value);

  const handleFilterObjectChange: SelectChangeHandler = (value) => table.setParam('object', value);

  const handleFilterDatesChange: DateRangePickerChangeHandler = (dates) => {
    const [dateFrom, dateTo] = dates ?? [];

    table.setParams({
      dateFrom: dateFrom ? dateFrom.startOf('day').toISOString() : '',
      dateTo: dateTo ? dateTo.endOf('day').toISOString() : '',
    });
  };

  const handleViewClick = (log: AuditLog) => () => {
    setLog(log);

    modal.open();
  };

  useTableQuery(table, logsQuery);

  const columns: TableColumns<AuditLog> = [
    {
      className: styles.table__date,
      key: 'date',
      title: lang.get('auditLog.list.date'),
      render: (_, log) => formatter.formatDateTime(log.createdAt),
    }, {
      key: 'user',
      title: lang.get('auditLog.list.user'),
      render: (_, log) => (
        <Flex vertical>
          <Text>{formatter.formatName(log.creator.firstName, log.creator.lastName)}</Text>
          <Text type="secondary">{log.creator.email}</Text>
        </Flex>
      ),
    }, {
      className: styles.table__action,
      key: 'action',
      title: lang.get('auditLog.list.action'),
      render: (_, log) => lang.get(`auditLog.actions.${log.action}`),
    }, {
      key: 'object',
      title: lang.get('auditLog.list.object'),
      render: (_, log) => lang.get(`auditLog.objects.${log.objectType}`),
    }, {
      key: 'actions',
      title: lang.get('common.list.actions'),
      render: (_, log) => (
        <Table.Actions
          buttons={[{
            title: lang.get('common.view'),
            icon: <Eye />,
            onClick: handleViewClick(log),
          }]}
        />
      ),
    },
  ];

  return (
    <DashboardLayout title={lang.get('auditLog.list.title')}>
      <TableView
        title={lang.get('auditLog.list.title')}
        filters={(
          <Flex gap="small" align="center" wrap="wrap">
            <TableView.Search
              defaultValue={table.params.search}
              onChange={handleFilterSearchChange}
            />
            <Select
              defaultValue={table.params.userId}
              options={[{
                value: '',
                label: <>{lang.get('auditLog.filters.users')}</>,
              }].concat((usersQuery.data ?? []).map((user) => ({
                value: user.id,
                label: (
                  <UserInfo
                    name={formatter.formatName(user.firstName, user.lastName)}
                    email={user.email}
                  />
                ),
                title: [user.firstName, user.lastName, user.email].join(' '),
              })))}
              loading={usersQuery.isFetching}
              popupMatchSelectWidth={false}
              showSearch
              onChange={handleFilterUserIdChange}
            />
            <Select
              defaultValue={table.params.action}
              options={[{
                value: '',
                label: lang.get('auditLog.filters.actions'),
              }].concat(Object.values(AuditLogAction).map((action) => ({
                value: action,
                label: lang.get(`auditLog.actions.${action}`),
              })))}
              popupMatchSelectWidth={false}
              onChange={handleFilterActionChange}
            />
            <Select
              defaultValue={table.params.object}
              options={[{
                value: '',
                label: lang.get('auditLog.filters.objects'),
              }].concat(Object.values(AuditLogObject).map((object) => ({
                value: object,
                label: lang.get(`auditLog.objects.${object}`),
              })))}
              popupMatchSelectWidth={false}
              onChange={handleFilterObjectChange}
            />
            <DateRangePicker
              defaultValue={[
                table.params.dateFrom ? moment(table.params.dateFrom) : null,
                table.params.dateTo ? moment(table.params.dateTo) : null,
              ]}
              maxDate={moment()}
              allowEmpty
              onChange={handleFilterDatesChange}
            />
          </Flex>
        )}
      >

        <Table<AuditLog>
          columns={columns}
          dataSource={table.data}
          pagination={table.pagination}
          rowKey={(log) => log.id}
          loading={logsQuery.isFetching}
          clickable
          onRow={(log) => ({ onClick: handleViewClick(log) })}
          onChange={table.onChange}
        />
      </TableView>

      {log && (
        <Modal
          log={log}
          open={modal.opened}
          onClose={modal.close}
        />
      )}

    </DashboardLayout>
  );
};

export default AuditLogsPage;

import { addMethod, MixedSchema } from 'yup';

import { file } from 'helpers';
import { Nullish } from 'types/common';

import { createError } from '../helpers';

addMethod(MixedSchema<Nullish<object>>, 'file', function validate() {
  return this.test((value, context) => {
    if (!value || file.isUploadFileLike(value)) {
      return true;
    }

    return createError(context, 'file');
  });
});

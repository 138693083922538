import { fileService } from 'services';
import { Uuid } from 'types/common';
import { UploadFile, UploadFileLike } from 'types/components';
import { UploadedFile } from 'types/models';

const isUploadFileLike = (value: unknown): value is UploadFileLike => Boolean(
  value
  && typeof value === 'object'
  && 'uid' in value
  && 'name' in value,
);

const isUploadFile = (value: unknown): value is UploadFile => Boolean(
  isUploadFileLike(value) && value.originFileObj instanceof File,
);

const mapUploadedFileToUploadFile = (file: UploadedFile): UploadFileLike => ({
  uid: file.id,
  name: file.originFileName,
  url: file.bucketUrl,
});

const handleFilesUpload = (files: UploadFileLike[]) => {
  // Upload new files and keep existing
  const promises: Promise<Uuid>[] = [];

  for (const file of files) {
    let promise: Promise<Uuid> = Promise.resolve(file.uid);

    if (isUploadFile(file)) {
      promise = new Promise((resolve) => {
        fileService.uploadFile(file).then((uploadedFile) => resolve(uploadedFile.id));
      });
    }

    promises.push(promise);
  }

  return Promise.all(promises);
};

const file = {
  isUploadFileLike,
  isUploadFile,
  mapUploadedFileToUploadFile,
  handleFilesUpload,
};

export default file;

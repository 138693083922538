import React, { FC } from 'react';

import { IconProps } from 'types/components';

import Icon from './Icon';

const Download: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path d="m7 13.79 3 3 3-3M10 10v6.81m6.73-2.2a3.79 3.79 0 0 0-2.19-6.89h-.95a6.06 6.06 0 1 0-10.4 5.52" />
  </Icon>
);

export default Download;

import React, { FC } from 'react';

import { IconProps } from 'types/components';

import Icon from './Icon';

const User: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path d="M16.667 17.5v-1.667a3.333 3.333 0 0 0-3.333-3.333H6.667a3.333 3.333 0 0 0-3.333 3.333V17.5m10-11.667a3.333 3.333 0 1 1-6.667 0 3.333 3.333 0 0 1 6.667 0Z" />
  </Icon>
);

export default User;

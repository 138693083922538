import { addMethod, NumberSchema } from 'yup';

import { createError } from '../helpers';

addMethod(NumberSchema, 'decimal', function validate(decimal = 2) {
  const DECIMAL_REGEXP = new RegExp(`^[+-]?\\d*\\.?\\d{0,${decimal}}$`);

  return this.test((value, context) => {
    if (!value || value.toString().match(DECIMAL_REGEXP)) {
      return true;
    }

    return createError(context, 'decimal', { decimal });
  });
});

import { TestContext, ValidationError } from 'yup';

import { lang } from 'helpers';
import { PrimitiveType } from 'types/common';

type MessageKey = string;

type MessageValues = Record<string, PrimitiveType> | {
  path: string;
};

type CreateMessage = (
  key: MessageKey,
  values?: MessageValues,
  group?: string,
) => string;

type CreateError = (
  context: TestContext<unknown>,
  key: MessageKey,
  values?: MessageValues,
) => ValidationError;

const createMessage: CreateMessage = (key, values, group = 'custom') => lang.get(`common.validation.${group}.${key}`, values);

const createError: CreateError = (context, key, values) => context.createError({
  message: createMessage(key, {
    ...values,
    path: context.path,
  }),
});

export {
  createMessage,
  createError,
};

import { axios } from 'data';
import { CountriesResponse } from 'types/services';

const getCountries = async () => {
  const response = await axios.get<CountriesResponse>('/core/references/countries');

  return response.data.list;
};

const countryService = {
  getCountries,
};

export default countryService;

import { config } from 'data';
import { feeCommissionService } from 'services';
import { fetchPaginatedResponseFully } from 'services/helpers';
import { useQuery } from 'hooks';
import { FeeCommissionsParams } from 'types/services';

const useAllFeeCommissionsQuery = (params?: FeeCommissionsParams) => useQuery({
  queryKey: [config.FEE_COMMISSIONS_QUERY_KEY, 'all', params],
  queryFn: () => fetchPaginatedResponseFully(feeCommissionService.getFeeCommissions, params),
});

export default useAllFeeCommissionsQuery;

import React, { FC } from 'react';
import { Tag as BaseTag, TagProps } from 'antd';
import cx from 'classnames';

import styles from './styles.module.css';

const Tag: FC<TagProps> = ({
  className,
  bordered = false,
  ...props
}) => (
  <BaseTag
    className={cx(styles.root, className)}
    bordered={bordered}
    {...props}
  />
);

export default Tag;

import React, { FC } from 'react';

import { IconProps } from 'types/components';

import Icon from './Icon';

const Eye: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path d="M1.67 10S4.7 3.94 10 3.94 18.33 10 18.33 10s-3 6.06-8.33 6.06S1.67 10 1.67 10ZM10 7.73A2.27 2.27 0 1 1 7.73 10 2.26 2.26 0 0 1 10 7.73Z" />
  </Icon>
);

export default Eye;

import React, { FC, ReactNode } from 'react';
import cx from 'classnames';

import { Override } from 'types/common';
import { CardViewProps } from 'types/components';

import CardView from '../CardView';

import styles from './styles.module.css';

import Search from './Search';

type TableViewProps = Override<CardViewProps, {
  filters?: ReactNode;
  children: ReactNode;
}>;

const TableView: FC<TableViewProps> = ({
  className,
  filters,
  children,
  ...props
}) => {
  return (
    <CardView className={cx(styles.root, className)} {...props}>
      {filters && <div className={styles.filters}>{filters}</div>}
      {children}
    </CardView>
  );
};

export default Object.assign(TableView, { Search });

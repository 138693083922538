import { config } from 'data';
import { userService } from 'services';
import { fetchPaginatedResponseFully } from 'services/helpers';
import { useQuery } from 'hooks';
import { UserRolesParams } from 'types/services';

const useAllUserRolesQuery = (params?: UserRolesParams) => useQuery({
  queryKey: [config.USER_ROLES_QUERY_KEY, 'all', params],
  queryFn: () => fetchPaginatedResponseFully(userService.getRoles, params),
});

export default useAllUserRolesQuery;

import React, { FC } from 'react';

import { formatter } from 'helpers';
import { useLang } from 'hooks';
import { UserInfo } from 'components/layout';
import { DiffViewer, Form, Input, Modal, Select } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { AuditLog, AuditLogAction, AuditLogObject } from 'types/models';

type AuditLogModalProps = ModalBaseProps & {
  log: AuditLog;
};

const AuditLogModal: FC<AuditLogModalProps> = ({
  log,
  open,
  onClose,
}) => {
  const lang = useLang();

  return (
    <Modal
      title={lang.get('auditLog.modal.title')}
      caption={lang.get('auditLog.modal.caption')}
      cancelText={lang.get('common.close')}
      okButtonProps={{ hidden: true }}
      width="lg"
      open={open}
      onCancel={onClose}
    >
      <Form>
        <Form.Columns>
          <Form.Item label={lang.get('auditLog.modal.date.label')}>
            <Input value={formatter.formatDateTime(log.createdAt)} disabled />
          </Form.Item>
          <Form.Item label={lang.get('auditLog.modal.user.label')}>
            <Select
              value={{
                value: log.creator.id,
                label: (
                  <UserInfo
                    name={formatter.formatName(log.creator.firstName, log.creator.lastName)}
                    email={log.creator.email}
                  />
                ),
              }}
              disabled
            />
          </Form.Item>
        </Form.Columns>
        <Form.Columns>
          <Form.Item label={lang.get('auditLog.modal.action.label')}>
            <Select
              value={log.action}
              options={Object.values(AuditLogAction).map((action) => ({
                value: action,
                label: lang.get(`auditLog.actions.${action}`),
              }))}
              disabled
            />
          </Form.Item>
          <Form.Item label={lang.get('auditLog.modal.object.label')}>
            <Select
              value={log.objectType}
              options={Object.values(AuditLogObject).map((object) => ({
                value: object,
                label: lang.get(`auditLog.objects.${object}`),
              }))}
              disabled
            />
          </Form.Item>
        </Form.Columns>
        <DiffViewer
          leftTitle={lang.get('auditLog.modal.oldValue.label')}
          rightTitle={lang.get('auditLog.modal.newValue.label')}
          oldValue={log.oldValue}
          newValue={log.newValue}
        />
      </Form>
    </Modal>
  );
};

export default AuditLogModal;

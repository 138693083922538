import React, { FC } from 'react';

import { IconProps } from 'types/components';

import Icon from './Icon';

const ArrowLeft: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path d="M15.835 10H4.168m0 0 5.833 5.833M4.168 10l5.833-5.833" />
  </Icon>
);

export default ArrowLeft;

import React, { useState } from 'react';

import { config } from 'data';
import { formatter, xlsx } from 'helpers';
import { feeCommissionService } from 'services';
import { fetchPaginatedResponseFully } from 'services/helpers';
import { useLang, useModal, useTable, useTableQuery } from 'hooks';
import { useFeeCommissionsQuery } from 'hooks/queries';
import { Eye, Plus, Table as TableIcon } from 'components/icons';
import { ActivityStatus, TableView } from 'components/layout';
import { Button, Flex, Table } from 'components/ui';
import { XlsxHeader, XlsxRow } from 'types/common';
import { TableColumns } from 'types/components';
import { FeeCommission, TransactionFeeType } from 'types/models';
import { FeeCommissionsParams } from 'types/services';

import styles from './styles.module.css';

import Modal from './Modal';

const EXPORT_FILE_NAME = 'fee-configurations';

const FeeCommissionsPage = () => {
  const lang = useLang();
  const modal = useModal();
  const table = useTable<FeeCommission>([config.FEE_COMMISSIONS_QUERY_KEY]);

  const [feeCommission, setFeeCommission] = useState<FeeCommission>();

  const feeCommissionsParams: FeeCommissionsParams = {
    page: table.page,
  };

  const feeCommissionsQuery = useFeeCommissionsQuery(feeCommissionsParams);

  const handleExportClick = async () => {
    try {
      table.setExporting(true);

      const data = await fetchPaginatedResponseFully(feeCommissionService.getFeeCommissions, feeCommissionsParams);

      const headers: XlsxHeader[] = [
        lang.get('feeCommission.list.name'),
        lang.get('feeCommission.list.level'),
        lang.get('feeCommission.list.currency'),
        lang.get('feeCommission.list.transactionFee'),
        lang.get('feeCommission.list.fxMarkup'),
        lang.get('feeCommission.list.minTransactionFeeAmount'),
        lang.get('feeCommission.list.maxTransactionFeeAmount'),
        lang.get('feeCommission.list.startDate'),
        lang.get('feeCommission.list.endDate'),
        lang.get('feeCommission.list.status'),
      ];

      const rows: XlsxRow[] = data.map((feeCommission) => ({
        name: formatNameColumn(feeCommission),
        level: formatLevelColumn(feeCommission),
        currency: formatCurrencyColumn(feeCommission),
        transactionFee: formatTransactionFeeColumn(feeCommission),
        fxMarkup: formatFxMarkupColumn(feeCommission),
        minTransactionFeeAmount: formatMinTransactionFeeAmountColumn(feeCommission),
        maxTransactionFeeAmount: formatMaxTransactionFeeAmountColumn(feeCommission),
        startDate: feeCommission.startDate ? formatter.formatDate(feeCommission.startDate) : '-',
        endaDate: feeCommission.endDate ? formatter.formatDate(feeCommission.endDate) : '-',
        status: lang.get(feeCommission.active ? 'common.activityStatuses.active' : 'common.activityStatuses.inactive'),
      }));

      xlsx.exportFile(EXPORT_FILE_NAME, headers, rows);
    } finally {
      table.setExporting(false);
    }
  };

  const handleCreateClick = () => {
    setFeeCommission(undefined);

    modal.open();
  };

  const handleViewClick = (feeCommission: FeeCommission) => () => {
    setFeeCommission(feeCommission);

    modal.open();
  };

  useTableQuery(table, feeCommissionsQuery);

  const formatNameColumn = (feeCommission: FeeCommission) => feeCommission.name;

  const formatLevelColumn = (feeCommission: FeeCommission) => lang.get(`feeCommission.levels.${feeCommission.level.toLowerCase()}`);

  const formatCurrencyColumn = (feeCommission: FeeCommission) => feeCommission.currency;

  const formatTransactionFeeColumn = (feeCommission: FeeCommission) => {
    if (feeCommission.transactionFeeType === TransactionFeeType.PERCENT) {
      return formatter.formatPercent(feeCommission.transactionFee);
    }

    return formatter.formatCurrency(feeCommission.transactionFee, feeCommission.currency);
  };

  const formatFxMarkupColumn = (feeCommission: FeeCommission) => formatter.formatPercent(feeCommission.fxMarkup);

  const formatMinTransactionFeeAmountColumn = (feeCommission: FeeCommission) => {
    if (feeCommission.transactionFeeType === TransactionFeeType.PERCENT) {
      return formatter.formatCurrency(feeCommission.minTransactionFeeAmount, feeCommission.currency);
    }

    return '-';
  };

  const formatMaxTransactionFeeAmountColumn = (feeCommission: FeeCommission) => {
    if (feeCommission.transactionFeeType === TransactionFeeType.PERCENT) {
      return formatter.formatCurrency(feeCommission.maxTransactionFeeAmount, feeCommission.currency);
    }

    return '-';
  };

  const columns: TableColumns<FeeCommission> = [
    {
      className: styles.table__name,
      key: 'name',
      title: lang.get('feeCommission.list.name'),
      render: (_, feeCommission) => <Table.Truncate>{formatNameColumn(feeCommission)}</Table.Truncate>,
    }, {
      key: 'level',
      title: lang.get('feeCommission.list.level'),
      render: (_, feeCommission) => formatLevelColumn(feeCommission),
    }, {
      key: 'currency',
      title: lang.get('feeCommission.list.currency'),
      render: (_, feeCommission) => formatCurrencyColumn(feeCommission),
    }, {
      className: styles.table__amount,
      key: 'transactionFee',
      title: lang.get('feeCommission.list.transactionFee'),
      render: (_, feeCommission) => formatTransactionFeeColumn(feeCommission),
    }, {
      className: styles.table__amount,
      key: 'fxMarkup',
      title: lang.get('feeCommission.list.fxMarkup'),
      render: (_, feeCommission) => formatFxMarkupColumn(feeCommission),
    }, {
      className: styles.table__amount,
      key: 'minTransactionFeeAmount',
      title: lang.get('feeCommission.list.minTransactionFeeAmount'),
      render: (_, feeCommission) => formatMinTransactionFeeAmountColumn(feeCommission),
    }, {
      className: styles.table__amount,
      key: 'maxTransactionFeeAmount',
      title: lang.get('feeCommission.list.maxTransactionFeeAmount'),
      render: (_, feeCommission) => formatMaxTransactionFeeAmountColumn(feeCommission),
    }, {
      key: 'status',
      title: lang.get('feeCommission.list.status'),
      render: (_, feeCommission) => <ActivityStatus active={feeCommission.active} />,
    }, {
      key: 'actions',
      title: lang.get('common.list.actions'),
      render: (_, feeCommission) => (
        <Table.Actions
          buttons={[{
            title: lang.get('common.view'),
            icon: <Eye />,
            onClick: handleViewClick(feeCommission),
          }]}
        />
      ),
    },
  ];

  return (
    <TableView
      title={lang.get('feeCommission.list.title')}
      actions={(
        <Flex gap="small" align="center" wrap="wrap">
          <Button
            type="default"
            icon={<TableIcon />}
            loading={table.exporting}
            disabled={!table.data.length}
            onClick={handleExportClick}
          >
            {lang.get('common.exportXlsx')}
          </Button>
          <Button icon={<Plus />} onClick={handleCreateClick}>
            {lang.get('feeCommission.list.create')}
          </Button>
        </Flex>
      )}
    >

      <Table<FeeCommission>
        columns={columns}
        dataSource={table.data}
        pagination={table.pagination}
        rowKey={(feeCommission) => feeCommission.id}
        loading={feeCommissionsQuery.isFetching}
        clickable
        onRow={(feeCommission) => ({ onClick: handleViewClick(feeCommission) })}
        onChange={table.onChange}
      />

      <Modal
        feeCommission={feeCommission}
        open={modal.opened}
        onClose={modal.close}
      />

    </TableView>
  );
};

export default FeeCommissionsPage;

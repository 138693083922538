import { config } from 'data';
import { providerService } from 'services';
import { fetchPaginatedResponseFully } from 'services/helpers';
import { useQuery } from 'hooks';
import { ProvidersParams } from 'types/services';

const useAllProvidersQuery = (params?: ProvidersParams) => useQuery({
  queryKey: [config.PROVIDERS_QUERY_KEY, 'all', params],
  queryFn: () => fetchPaginatedResponseFully(providerService.getProviders, params),
});

export default useAllProvidersQuery;

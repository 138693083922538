import React from 'react';
import { Navigate } from 'react-router-dom';

import { url } from 'helpers';

const DashboardPage = () => (
  <Navigate to={url.toClients()} />
);

export default DashboardPage;

import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import { config } from 'data';
import { BaseLayoutProps } from 'types/layouts';

const BaseLayout: FC<BaseLayoutProps> = ({ title, children }) => (
  <>
    <Helmet>
      <title>{title} — {config.NAME}</title>
    </Helmet>
    {children}
  </>
);

export default BaseLayout;

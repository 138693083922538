import React, { FC } from 'react';

import { IconProps } from 'types/components';

const Icon: FC<IconProps> = ({
  xmlns = 'http://www.w3.org/2000/svg',
  viewBox = '0 0 20 20',
  width = '1em',
  height = '1em',
  fill = 'none',
  stroke = 'currentColor',
  strokeWidth = 1.5,
  strokeLinecap = 'round',
  strokeLinejoin = 'round',
  ...props
}) => (
  <svg
    xmlns={xmlns}
    viewBox={viewBox}
    width={width}
    height={height}
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeLinecap={strokeLinecap}
    strokeLinejoin={strokeLinejoin}
    {...props}
  />
);

export default Icon;

import React, { FC, ReactNode } from 'react';
import { RowProps } from 'antd';
import cx from 'classnames';

import { Override } from 'types/common';

import styles from './styles.module.css';

import Grid from '../Grid';

type ColumnsProps = Override<RowProps, {
  children: ReactNode[];
}>;

const Columns: FC<ColumnsProps> = ({
  className,
  gutter = 8,
  children,
  ...props
}) => (
  <Grid.Row
    className={cx(styles.columns, className)}
    gutter={gutter}
    {...props}
  >
    {children.map((child, index) => (
      <Grid.Col key={index} span={24} sm={12}>
        {child}
      </Grid.Col>
    ))}
  </Grid.Row>
);

export default Columns;

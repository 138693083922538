import React, { FC } from 'react';

import { config, moment } from 'data';
import { formatter, xlsx } from 'helpers';
import { reportService } from 'services';
import { fetchPaginatedResponseFully } from 'services/helpers';
import { useLang, useQuery, useTable, useTableQuery } from 'hooks';
import { Table as TableIcon } from 'components/icons';
import { TableView } from 'components/layout';
import { Button, DateRangePicker, Flex, Table } from 'components/ui';
import { XlsxHeader, XlsxRow } from 'types/common';
import { DateRangePickerChangeHandler, TableColumns } from 'types/components';
import { BusinessAccount, ReportDailyBalanceItem } from 'types/models';
import { ReportDailyBalanceParams } from 'types/services';

import styles from './styles.module.css';

const EXPORT_FILE_NAME = 'report-daily-balance';

type TableParams = {
  dateFrom: string;
  dateTo: string;
};

const initialTableParams: TableParams = {
  dateFrom: moment().subtract(1, 'day').startOf('day').toISOString(),
  dateTo: moment().subtract(1, 'day').endOf('day').toISOString(),
};

type DailyBalanceViewProps = {
  businessAccount: BusinessAccount;
};

const DailyBalanceView: FC<DailyBalanceViewProps> = ({ businessAccount }) => {
  const lang = useLang();
  const table = useTable<ReportDailyBalanceItem, TableParams>([config.BUSINESS_ACCOUNT_DAILY_BALANCE_QUERY_KEY, businessAccount.id], initialTableParams);

  const reportParams: ReportDailyBalanceParams = {
    clientId: businessAccount.id,
    page: table.page,
    dateFrom: table.params.dateFrom,
    dateTo: table.params.dateTo,
  };

  const reportQuery = useQuery({
    queryKey: [config.BUSINESS_ACCOUNT_DAILY_BALANCE_QUERY_KEY, reportParams],
    queryFn: () => reportService.getDailyBalance(reportParams),
  });

  const handleFilterDatesChange: DateRangePickerChangeHandler = (dates) => {
    const [dateFrom, dateTo] = dates ?? [];

    table.setParams({
      dateFrom: dateFrom ? dateFrom.startOf('day').toISOString() : '',
      dateTo: dateTo ? dateTo.endOf('day').toISOString() : '',
    });
  };

  const handleExportClick = async () => {
    try {
      table.setExporting(true);

      const fileName = [
        EXPORT_FILE_NAME,
        businessAccount.name,
        moment(table.params.dateFrom).format(config.DATE_RAW_FORMAT),
        moment(table.params.dateTo).format(config.DATE_RAW_FORMAT),
      ].join('-');

      const data = await fetchPaginatedResponseFully(reportService.getDailyBalance, reportParams);

      const headers: XlsxHeader[] = [
        lang.get('businessAccount.dailyBalance.date'),
        lang.get('businessAccount.dailyBalance.currency'),
        lang.get('businessAccount.dailyBalance.incoming'),
        lang.get('businessAccount.dailyBalance.amount'),
        lang.get('businessAccount.dailyBalance.transactionFee'),
        lang.get('businessAccount.dailyBalance.fxMarkup'),
        lang.get('businessAccount.dailyBalance.providerFee'),
        lang.get('businessAccount.dailyBalance.outgoing'),
      ];

      const rows: XlsxRow[] = data.map((item) => ({
        date: formatDateColumn(item),
        currency: formatCurrencyColumn(item),
        incoming: formatIncomingColumn(item),
        amount: formatAmountColumn(item),
        transactionFee: formatTransactionFeeColumn(item),
        fxMarkup: formatFxMarkupColumn(item),
        providerFee: formatProviderFeeColumn(item),
        outgoing: formatOutgoingColumn(item),
      }));

      xlsx.exportFile(fileName, headers, rows);
    } finally {
      table.setExporting(false);
    }
  };

  useTableQuery(table, reportQuery);

  const formatDateColumn = (item: ReportDailyBalanceItem) => formatter.formatDate(item.date);

  const formatCurrencyColumn = (item: ReportDailyBalanceItem) => item.currency;

  const formatIncomingColumn = (item: ReportDailyBalanceItem) => formatter.formatNumber(item.startingBalance);

  const formatAmountColumn = (item: ReportDailyBalanceItem) => formatter.formatNumber(item.totalAmount);

  const formatTransactionFeeColumn = (item: ReportDailyBalanceItem) => formatter.formatNumber(item.totalTransactionFees);

  const formatFxMarkupColumn = (item: ReportDailyBalanceItem) => formatter.formatNumber(item.totalFxMarkupFees);

  const formatProviderFeeColumn = (item: ReportDailyBalanceItem) => formatter.formatNumber(item.totalProviderFees);

  const formatOutgoingColumn = (item: ReportDailyBalanceItem) => formatter.formatNumber(item.endingBalance);

  const columns: TableColumns<ReportDailyBalanceItem> = [
    {
      className: styles.table__date,
      key: 'date',
      title: lang.get('businessAccount.dailyBalance.date'),
      render: (_, item) => formatDateColumn(item),
    }, {
      key: 'currency',
      title: lang.get('businessAccount.dailyBalance.currency'),
      render: (_, item) => formatCurrencyColumn(item),
    }, {
      className: styles.table__balance,
      key: 'incoming',
      title: lang.get('businessAccount.dailyBalance.incoming'),
      render: (_, item) => formatIncomingColumn(item),
    }, {
      className: styles.table__amount,
      key: 'amount',
      title: lang.get('businessAccount.dailyBalance.amount'),
      render: (_, item) => formatAmountColumn(item),
    }, {
      className: styles.table__balance,
      key: 'transactionFee',
      title: lang.get('businessAccount.dailyBalance.transactionFee'),
      render: (_, item) => formatTransactionFeeColumn(item),
    }, {
      className: styles.table__balance,
      key: 'fxMarkup',
      title: lang.get('businessAccount.dailyBalance.fxMarkup'),
      render: (_, item) => formatFxMarkupColumn(item),
    }, {
      className: styles.table__balance,
      key: 'providerFee',
      title: lang.get('businessAccount.dailyBalance.providerFee'),
      render: (_, item) => formatProviderFeeColumn(item),
    }, {
      className: styles.table__balance,
      key: 'outgoing',
      title: lang.get('businessAccount.dailyBalance.outgoing'),
      render: (_, item) => formatOutgoingColumn(item),
    },
  ];

  return (
    <TableView
      title={lang.get('businessAccount.dailyBalance.title', { name: businessAccount.name })}
      actions={(
        <Button
          icon={<TableIcon />}
          loading={table.exporting}
          disabled={!table.data.length}
          onClick={handleExportClick}
        >
          {lang.get('common.exportXlsx')}
        </Button>
      )}
      filters={(
        <Flex gap="small" align="center" justify="flex-end" wrap="wrap">
          <DateRangePicker
            defaultValue={[moment(table.params.dateFrom), moment(table.params.dateTo)]}
            maxDate={moment().subtract(1, 'day').endOf('day')}
            allowClear={false}
            onChange={handleFilterDatesChange}
          />
        </Flex>
      )}
    >
      <Table<ReportDailyBalanceItem>
        columns={columns}
        dataSource={table.data}
        pagination={table.pagination}
        rowKey={(item) => item.date}
        loading={reportQuery.isFetching}
        onChange={table.onChange}
      />
    </TableView>
  );
};

export default DailyBalanceView;

import { config } from 'data';
import { businessAccountService } from 'services';
import { useQuery } from 'hooks';

const useAllBusinessAccountRolesQuery = () => useQuery({
  queryKey: [config.BUSINESS_ACCOUNT_ROLES_QUERY_KEY, 'all'],
  queryFn: businessAccountService.getRoles,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
});

export default useAllBusinessAccountRolesQuery;

import React, { FC } from 'react';

import { FormItemProps, UploadEvent } from 'types/components';

import Item from './Item';

const UploadItem: FC<FormItemProps> = ({
  valuePropName = 'fileList',
  getValueFromEvent = (event: UploadEvent) => event.fileList,
  ...props
}) => (
  <Item
    valuePropName={valuePropName}
    getValueFromEvent={getValueFromEvent}
    {...props}
  />
);

export default UploadItem;

import React, { FC } from 'react';

import { config, yup } from 'data';
import { profileService } from 'services';
import { useForm, useLang, useMutation } from 'hooks';
import { Form, Input, Modal } from 'components/ui';
import { ModalBaseProps } from 'types/components';

type FormValues = {
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
};

const initialValues: FormValues = {
  currentPassword: '',
  password: '',
  passwordConfirmation: '',
};

const validationSchema = yup.object({
  currentPassword: yup.string().required().min(config.PASSWORD_CURRENT_MIN_LENGTH),
  password: yup.string().required().password(),
  passwordConfirmation: yup.string().required().confirmation(yup.ref('password')),
});

const PasswordChangeModal: FC<ModalBaseProps> = ({ open, onClose }) => {
  const form = useForm<FormValues>();
  const lang = useLang();

  const updatePasswordMutation = useMutation({
    mutationFn: (values: FormValues) => profileService.updatePassword(values.currentPassword, values.password),
    successNotification: lang.get('profile.passwordChange.success'),
  });

  const handleSubmit = async (values: FormValues) => {
    await updatePasswordMutation.mutateAsync(values);

    onClose();
  };

  return (
    <Modal
      title={lang.get('profile.passwordChange.title')}
      caption={lang.get('profile.passwordChange.caption')}
      okText={lang.get('common.save')}
      width="sm"
      open={open}
      confirmLoading={updatePasswordMutation.isPending}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onFinish={handleSubmit}
      >
        <Form.Item name="currentPassword" label={lang.get('profile.passwordChange.currentPassword.label')}>
          <Input type="password" placeholder={lang.get('profile.passwordChange.currentPassword.placeholder')} />
        </Form.Item>
        <Form.Item name="password" label={lang.get('profile.passwordChange.password.label')}>
          <Input type="password" placeholder={lang.get('profile.passwordChange.password.placeholder')} />
        </Form.Item>
        <Form.Item name="passwordConfirmation" label={lang.get('profile.passwordChange.passwordConfirmation.label')}>
          <Input type="password" placeholder={lang.get('profile.passwordChange.passwordConfirmation.placeholder')} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PasswordChangeModal;

import React, { FC } from 'react';

import { config } from 'data';
import { formatter } from 'helpers';
import { useLang } from 'hooks';
import { useBusinessAccountBalanceQuery } from 'hooks/queries';
import { Uuid } from 'types/common';

import styles from './styles.module.css';

type BusinessAccountBalanceProps = {
  businessAccountId: Uuid;
};

const BusinessAccountBalance: FC<BusinessAccountBalanceProps> = ({ businessAccountId }) => {
  const lang = useLang();

  const balanceQuery = useBusinessAccountBalanceQuery(businessAccountId);
  const balance = balanceQuery.data;
  const currency = balance?.currency ?? config.DEFAULT_CURRENCY;

  return (
    <div className={styles.root}>
      <span className={styles.item}>
        {lang.get('businessAccount.balance.current')}
        <strong>{formatter.formatCurrency(balance?.balance ?? 0, currency)}</strong>
      </span>
      <span className={styles.separator} />
      <span className={styles.item}>
        <span>{formatter.formatCurrency(balance?.transfersPendingBalance ?? 0, currency)}</span>
        {lang.get('businessAccount.balance.transfersPending')}
      </span>
      <span className={styles.separator} />
      <span className={styles.item}>
        <span>{formatter.formatCurrency(balance?.topUpsPendingBalance ?? 0, currency)}</span>
        {lang.get('businessAccount.balance.topUpsPending')}
      </span>
    </div>
  );
};

export default BusinessAccountBalance;

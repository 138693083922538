import { config } from 'data';
import { userService } from 'services';
import { useQuery } from 'hooks';
import { Uuid } from 'types/common';

const useUserQuery = (id?: Uuid) => useQuery({
  queryKey: [config.USER_QUERY_KEY, id],
  queryFn: () => id ? userService.getUser(id) : null,
});

export default useUserQuery;

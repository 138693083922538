import React, { FC, useEffect } from 'react';

import { config, yup } from 'data';
import { businessAccountService } from 'services';
import { useForm, useLang, useMutation, useQueryInvalidate } from 'hooks';
import { useBusinessAccountApiQuery } from 'hooks/queries';
import { Form, Input, Modal, PopconfirmButton } from 'components/ui';
import { Uuid } from 'types/common';
import { ModalBaseProps } from 'types/components';
import { BusinessAccount } from 'types/models';
import { BusinessAccountUpdateApiParams } from 'types/services';

type ApiModalProps = ModalBaseProps & {
  businessAccount: BusinessAccount;
};

type FormValues = BusinessAccountUpdateApiParams & {
  id?: Uuid;
};

const initialValues: FormValues = {
  webhookUrl: null,
};

const validationSchema = yup.object({
  webhookUrl: yup.string().notRequired().website().default(initialValues.webhookUrl),
});

const ApiModal: FC<ApiModalProps> = ({
  businessAccount,
  open,
  onClose,
}) => {
  const form = useForm<FormValues>();
  const lang = useLang();
  const queryInvalidate = useQueryInvalidate();

  const businessAccountApiQuery = useBusinessAccountApiQuery(businessAccount.id);

  const invalidateBusinessAccountQueries = async () => {
    await queryInvalidate([config.BUSINESS_ACCOUNT_API_QUERY_KEY, businessAccount.id]);
  };

  const updateBusinessAccountApiMutation = useMutation({
    mutationFn: (values: FormValues) => businessAccountService.updateBusinessAccountApi(businessAccount.id, values),
    onSuccess: invalidateBusinessAccountQueries,
    successNotification: lang.get('businessAccount.api.modal.success', { name: businessAccount.name }),
  });

  const resetBusinessAccountCredentialsMutation = useMutation({
    mutationFn: () => businessAccountService.resetBusinessAccountCredentials(businessAccount.id),
    onSuccess: invalidateBusinessAccountQueries,
    successNotification: lang.get('businessAccount.api.modal.resetCredentialsSuccess', { name: businessAccount.name }),
  });

  const handleSubmit = async (values: FormValues) => {
    await updateBusinessAccountApiMutation.mutateAsync(values);

    onClose();
  };

  useEffect(() => {
    if (open && !businessAccountApiQuery.isFetching && businessAccountApiQuery.data) {
      form.setFieldsValue(businessAccountApiQuery.data);
    }
  }, [open, form, businessAccountApiQuery.isFetching, businessAccountApiQuery.data]);

  return (
    <Modal
      title={lang.get('businessAccount.api.modal.title', { name: businessAccount.name })}
      caption={lang.get('businessAccount.api.modal.caption')}
      okText={lang.get('common.save')}
      width="sm"
      open={open}
      confirmLoading={updateBusinessAccountApiMutation.isPending}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onFinish={handleSubmit}
      >

        <Form.ActionsItem>
          <PopconfirmButton
            title={lang.get('businessAccount.api.modal.resetCredentialsTitle')}
            description={lang.get('businessAccount.api.modal.resetCredentialsCaption')}
            loading={resetBusinessAccountCredentialsMutation.isPending}
            onConfirm={() => resetBusinessAccountCredentialsMutation.mutateAsync()}
          >
            {lang.get('businessAccount.api.modal.resetCredentialsButton')}
          </PopconfirmButton>
        </Form.ActionsItem>

        <Form.Divider />

        <Form.Item name="id" label={lang.get('businessAccount.api.modal.id.label')}>
          <Input placeholder={lang.get('businessAccount.api.modal.id.placeholder')} readOnly />
        </Form.Item>
        <Form.Item name="webhookUrl" label={lang.get('businessAccount.api.modal.webhookUrl.label')}>
          <Input type="url" placeholder={lang.get('businessAccount.api.modal.webhookUrl.placeholder')} />
        </Form.Item>

      </Form>
    </Modal>
  );
};

export default ApiModal;

type EventArguments = unknown[];

type EventListener = (...args: EventArguments) => void;

const createListener = () => {
  const listeners: Record<string, EventListener[]> = {};

  const on = (event: string, handler: EventListener) => {
    if (!listeners[event]) {
      listeners[event] = [];
    }

    listeners[event].push(handler);
  };

  const off = (event: string, handler: EventListener) => {
    listeners[event] = listeners[event].filter((listener) => listener !== handler);
  };

  const trigger = (event: string, ...args: EventArguments) => {
    listeners[event].forEach((listener) => listener(...args));
  };

  return {
    on,
    off,
    trigger,
  };
};

const event = {
  createListener,
};

export default event;

import React, { FC } from 'react';

import { IconProps } from 'types/components';

import Icon from './Icon';

const Setting: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path d="M9.999 12.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
    <path d="M16.165 12.5a1.374 1.374 0 0 0 .275 1.517l.05.05a1.666 1.666 0 0 1-.54 2.72 1.666 1.666 0 0 1-1.818-.362l-.05-.05a1.375 1.375 0 0 0-1.517-.275 1.375 1.375 0 0 0-.833 1.258v.142a1.667 1.667 0 0 1-3.333 0v-.075a1.375 1.375 0 0 0-.9-1.258 1.375 1.375 0 0 0-1.517.275l-.05.05a1.667 1.667 0 1 1-2.358-2.359l.05-.05a1.375 1.375 0 0 0 .275-1.516 1.376 1.376 0 0 0-1.259-.834H2.5a1.667 1.667 0 0 1 0-3.333h.075a1.375 1.375 0 0 0 1.258-.9 1.375 1.375 0 0 0-.275-1.517l-.05-.05a1.667 1.667 0 1 1 2.358-2.358l.05.05a1.375 1.375 0 0 0 1.517.275h.067a1.375 1.375 0 0 0 .833-1.258V2.5a1.667 1.667 0 0 1 3.333 0v.075a1.374 1.374 0 0 0 .834 1.258 1.375 1.375 0 0 0 1.516-.275l.05-.05a1.667 1.667 0 1 1 2.359 2.359l-.05.05a1.375 1.375 0 0 0-.275 1.516V7.5a1.375 1.375 0 0 0 1.258.833h.142a1.667 1.667 0 0 1 0 3.334h-.075a1.376 1.376 0 0 0-1.259.833Z" />
  </Icon>
);

export default Setting;

import React, { FC } from 'react';
import { Form } from 'antd';
import cx from 'classnames';

import { FormItemProps } from 'types/components';

import styles from './styles.module.css';

const Item: FC<FormItemProps> = ({
  className,
  labelCol = {},
  colon = false,
  ...props
}) => (
  <Form.Item
    className={cx(className, styles.item)}
    labelCol={labelCol}
    colon={colon}
    {...props}
  />
);

export default Item;

import React, { FC, useEffect } from 'react';

import { config } from 'data';
import { clientService } from 'services';
import { useForm, useLang, useMutation, useQueryInvalidate } from 'hooks';
import { Form, Input, Modal, PopconfirmButton, Select } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { Client, ClientStatus } from 'types/models';

type ClientModalProps = ModalBaseProps & {
  client: Client;
};

type FormValues = Client;

const ClientModal: FC<ClientModalProps> = ({
  client,
  open,
  onClose,
}) => {
  const form = useForm<FormValues>();
  const lang = useLang();
  const queryInvalidate = useQueryInvalidate();

  const clientId = client.id;
  const clientEmail = client.email;

  const invalidateClientQueries = async () => {
    await queryInvalidate([config.CLIENTS_QUERY_KEY]);
  };

  const activateClientMutation = useMutation({
    mutationFn: () => clientService.activateClient(clientId),
    onSuccess: invalidateClientQueries,
    successNotification: lang.get('client.modal.activateSuccess', { email: clientEmail }),
  });

  const deactivateClientMutation = useMutation({
    mutationFn: () => clientService.deactivateClient(clientId),
    onSuccess: invalidateClientQueries,
    successNotification: lang.get('client.modal.deactivateSuccess', { email: clientEmail }),
  });

  const handleActivate = async () => {
    await activateClientMutation.mutateAsync();

    onClose();
  };

  const handleDeactivate = async () => {
    await deactivateClientMutation.mutateAsync();

    onClose();
  };

  useEffect(() => {
    if (open && client) {
      form.setFieldsValue(client);
    }
  }, [client, open, form]);

  return (
    <Modal
      title={lang.get('client.modal.viewTitle', { email: clientEmail })}
      caption={lang.get('client.modal.viewCaption')}
      cancelText={lang.get('common.close')}
      okButtonProps={{ hidden: true }}
      open={open}
      onCancel={onClose}
    >
      <Form form={form}>

        <Form.ActionsItem>
          {client.status === ClientStatus.ACTIVE ? (
            <PopconfirmButton
              title={lang.get('client.modal.deactivateTitle')}
              danger
              loading={deactivateClientMutation.isPending}
              onConfirm={handleDeactivate}
            >
              {lang.get('client.modal.actions.deactivate')}
            </PopconfirmButton>
          ) : (
            <PopconfirmButton
              title={lang.get('client.modal.activateTitle')}
              type="primary"
              ghost
              loading={activateClientMutation.isPending}
              onConfirm={handleActivate}
            >
              {lang.get('client.modal.actions.activate')}
            </PopconfirmButton>
          )}
        </Form.ActionsItem>

        <Form.Divider />

        <Form.Columns>
          <Form.Item name="firstName" label={lang.get('common.form.firstName.label')}>
            <Input placeholder={lang.get('common.form.firstName.placeholder')} readOnly />
          </Form.Item>
          <Form.Item name="lastName" label={lang.get('common.form.lastName.label')}>
            <Input placeholder={lang.get('common.form.lastName.placeholder')} readOnly />
          </Form.Item>
        </Form.Columns>
        <Form.Columns>
          <Form.Item name="email" label={lang.get('common.form.email.label')}>
            <Input.Email readOnly />
          </Form.Item>
          <Form.Item name="phone" label={lang.get('common.form.phone.label')}>
            <Input.Phone readOnly />
          </Form.Item>
        </Form.Columns>
        <Form.Item name="residenceCountryCode" label={lang.get('common.form.country.label')}>
          <Select.Country disabled />
        </Form.Item>

      </Form>
    </Modal>
  );
};

export default ClientModal;

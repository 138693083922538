import React, { FC, WheelEventHandler } from 'react';

import { InputProps } from 'types/components';

import Input from '.';

const NumberInput: FC<InputProps> = ({
  type = 'number',
  ...props
}) => {
  const handleWheel: WheelEventHandler<HTMLInputElement> = (event) => {
    event.currentTarget.blur(); // prevent numbers changing
  };

  return (
    <Input
      type={type}
      onWheel={handleWheel}
      {...props}
    />
  );
};

export default NumberInput;

import { axios } from 'data';

import {
  ReportDailyBalanceParams,
  ReportDailyBalanceResponse,
  ReportDailyRemittanceParams,
  ReportDailyRemittanceResponse,
  ReportDailyTransactionsParams,
  ReportDailyTransactionsResponse,
} from 'types/services';

import { preparePaginatableParams, preparePaginatedData, prepareTimezonableParams } from './helpers';

const getDailyBalance = async (params: ReportDailyBalanceParams) => {
  params = prepareTimezonableParams(params);
  params = preparePaginatableParams(params);

  const response = await axios.get<ReportDailyBalanceResponse>('/payments/admin/transaction/report/daily', { params });

  return preparePaginatedData(response.data);
};

const getDailyRemittance = async (params: ReportDailyRemittanceParams) => {
  params = prepareTimezonableParams(params);
  params = preparePaginatableParams(params);

  const response = await axios.get<ReportDailyRemittanceResponse>('/payments/admin/reports', { params });

  return preparePaginatedData(response.data);
};

const getDailyTransactions = async (params: ReportDailyTransactionsParams) => {
  params = prepareTimezonableParams(params);
  params = preparePaginatableParams(params);

  const response = await axios.get<ReportDailyTransactionsResponse>('/payments/admin/transaction/report', { params });

  return preparePaginatedData(response.data);
};

const reportService = {
  getDailyBalance,
  getDailyRemittance,
  getDailyTransactions,
};

export default reportService;

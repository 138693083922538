import React, { FC } from 'react';

import { IconProps } from 'types/components';

import Icon from './Icon';

const Chart: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path d="M7.5 2.5H4.17A1.67 1.67 0 0 0 2.5 4.17V7.5m5-5h8.33a1.67 1.67 0 0 1 1.67 1.67V7.5m-10-5v15m0 0h8.33a1.67 1.67 0 0 0 1.67-1.67V7.5m-10 10H4.17a1.67 1.67 0 0 1-1.67-1.67V7.5m0 0h15" />
  </Icon>
);

export default Chart;

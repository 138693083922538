import React, { useState } from 'react';
import map from 'lodash/map';

import { config } from 'data';
import { useLang, useModal, useTable, useTableQuery } from 'hooks';
import { useUserQuery, useUsersQuery } from 'hooks/queries';
import { DashboardLayout } from 'layouts';
import { Eye, Plus } from 'components/icons';
import { TableView } from 'components/layout';
import { Button, Table } from 'components/ui';
import { Uuid } from 'types/common';
import { TableColumns } from 'types/components';
import { User } from 'types/models';

import styles from './styles.module.css';

import Modal from './Modal';

const UsersPage = () => {
  const lang = useLang();
  const modal = useModal();
  const table = useTable<User>([config.USERS_QUERY_KEY]);

  const [userId, setUserId] = useState<Uuid>();

  const userQuery = useUserQuery(userId);
  const usersQuery = useUsersQuery({ page: table.page });

  const handleCreateClick = () => {
    setUserId(undefined);

    modal.open();
  };

  const handleViewClick = (user: User) => () => {
    setUserId(user.id);

    modal.open();
  };

  const handleModalClose = () => {
    setUserId(undefined);

    modal.close();
  };

  useTableQuery(table, usersQuery);

  const columns: TableColumns<User> = [
    {
      className: styles.table__email,
      key: 'email',
      title: lang.get('user.list.email'),
      render: (_, user) => <Table.Truncate width="md">{user.email}</Table.Truncate>,
    }, {
      key: 'firstName',
      title: lang.get('user.list.firstName'),
      render: (_, user) => <Table.Truncate>{user.firstName || '-'}</Table.Truncate>,
    }, {
      key: 'lastName',
      title: lang.get('user.list.lastName'),
      render: (_, user) => <Table.Truncate>{user.lastName || '-'}</Table.Truncate>,
    }, {
      key: 'roles',
      title: lang.get('user.list.roles'),
      render: (_, user) => map(user.roles, 'name').join(', ') || '-',
    }, {
      key: 'actions',
      title: lang.get('common.list.actions'),
      render: (_, user) => (
        <Table.Actions
          buttons={[{
            title: lang.get('common.view'),
            icon: <Eye />,
            loading: userQuery.isFetching,
            onClick: handleViewClick(user),
          }]}
        />
      ),
    },
  ];

  return (
    <DashboardLayout title={lang.get('user.list.title')}>
      <TableView
        title={lang.get('user.list.title')}
        actions={(
          <Button icon={<Plus />} onClick={handleCreateClick}>
            {lang.get('user.list.create')}
          </Button>
        )}
      >

        <Table<User>
          columns={columns}
          dataSource={table.data}
          pagination={table.pagination}
          rowKey={(user) => user.id}
          loading={usersQuery.isFetching}
          clickable
          onRow={(user) => ({ onClick: handleViewClick(user) })}
          onChange={table.onChange}
        />
      </TableView>

      <Modal
        user={userQuery.data}
        open={!userQuery.isLoading && modal.opened}
        onClose={handleModalClose}
      />

    </DashboardLayout>
  );
};

export default UsersPage;

import { config } from 'data';
import { auditLogService } from 'services';
import { useQuery } from 'hooks';
import { AuditLogsParams } from 'types/services';

const useAuditLogsQuery = (params?: AuditLogsParams) => useQuery({
  queryKey: [config.AUDIT_LOGS_QUERY_KEY, params],
  queryFn: () => auditLogService.getLogs(params),
});

export default useAuditLogsQuery;

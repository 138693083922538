import React, { FC } from 'react';

import { IconProps } from 'types/components';

import Icon from './Icon';

const Plus: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path d="M10.001 4.167v11.666M4.168 10h11.667" />
  </Icon>
);

export default Plus;

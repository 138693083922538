import { addMethod, StringSchema } from 'yup';

import { createError } from '../helpers';

addMethod(StringSchema, 'currency', function validate() {
  const CURRENCY_REGEXP = /^[\da-z]{3,24}$/i;

  return this.trim()
    .uppercase()
    .test((value, context) => {
      if (!value || value.match(CURRENCY_REGEXP)) {
        return true;
      }

      return createError(context, 'currency');
    });
});

import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import * as locales from 'locales';

import config from './config';

i18next.use(initReactI18next).init({
  fallbackLng: config.DEFAULT_LOCALE,
  debug: !config.IS_PRODUCTION,
  interpolation: {
    escapeValue: false,
  },
  resources: { ...locales },
});

export default i18next;

import React, { FC } from 'react';

import { useLang } from 'hooks';
import { useAllBusinessAccountRolesQuery } from 'hooks/queries';
import { Flex, Select, Text } from 'components/ui';
import { SelectProps } from 'types/components';

import styles from './styles.module.css';

const RolesSelect: FC<SelectProps> = (props) => {
  const lang = useLang();

  const rolesQuery = useAllBusinessAccountRolesQuery();

  return (
    <Select
      {...props}
      placeholder={lang.get('businessAccount.member.modal.roles.placeholder')}
      options={rolesQuery.data?.map((role) => ({
        value: role.id,
        label: (
          <Flex className={styles.roles__option} vertical>
            <Text className={styles.roles__option__name}>{role.name}</Text>
            <Text className={styles.roles__option__caption} type="secondary">{role.description}</Text>
          </Flex>
        ),
      }))}
      mode="multiple"
      loading={rolesQuery.isFetching}
    />
  );
};

export default RolesSelect;

import React from 'react';

import { useLang } from 'hooks';
import { DashboardLayout } from 'layouts';
import { TransactionsView } from 'components/layout';

const TransactionsPage = () => {
  const lang = useLang();

  return (
    <DashboardLayout title={lang.get('transaction.list.title')}>
      <TransactionsView />
    </DashboardLayout>
  );
};

export default TransactionsPage;

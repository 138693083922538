import React, { createContext, FC, ReactNode, useMemo } from 'react';
import { App, ConfigProvider, message, notification } from 'antd';
import { MessageInstance } from 'antd/es/message/interface';
import { NotificationInstance } from 'antd/es/notification/interface';

import { config, theme } from 'data';

import 'antd/dist/reset.css';

type ContextValue = {
  message?: MessageInstance;
  notification?: NotificationInstance;
};

type ProviderProps = {
  children: ReactNode;
};

const ThemeContext = createContext<ContextValue>({
  message: undefined,
  notification: undefined,
});

const ThemeProvider: FC<ProviderProps> = ({ children }) => {
  const [messageApi, messageContext] = message.useMessage({
    maxCount: config.THEME_MESSAGE_MAX_COUNT,
  });

  const [notificationApi, notificationContext] = notification.useNotification({
    duration: config.THEME_NOTIFICATION_DURATION,
    maxCount: config.THEME_NOTIFICATION_MAX_COUNT,
    placement: 'bottomLeft',
  });

  const value = useMemo<ContextValue>(() => ({
    message: messageApi,
    notification: notificationApi,
  }), [messageApi, notificationApi]);

  return (
    <ThemeContext.Provider value={value}>
      <ConfigProvider theme={theme}>
        <App>
          {children}
          {messageContext}
          {notificationContext}
        </App>
      </ConfigProvider>
    </ThemeContext.Provider>
  );
};

const Theme = {
  Context: ThemeContext,
  Provider: ThemeProvider,
};

export default Theme;

import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { url } from 'helpers';
import { BaseLayout } from 'layouts';
import { Logo } from 'components/icons';
import { Container, Paragraph, Title } from 'components/ui';
import { Override } from 'types/common';
import { BaseLayoutProps } from 'types/layouts';

import styles from './styles.module.css';

type PageLayoutProps = Override<BaseLayoutProps, {
  heading: string;
  caption: string;
}>;

const PageLayout: FC<PageLayoutProps> = ({ heading, caption, children, ...props }) => (
  <BaseLayout {...props}>
    <Container className={styles.container}>
      <Link to={url.toDashboard()}>
        <Logo className={styles.logo} />
      </Link>
      <Title className={styles.title}>{heading}</Title>
      <Paragraph className={styles.caption}>{caption}</Paragraph>
      <div className={styles.content}>{children}</div>
    </Container>
  </BaseLayout>
);

export default PageLayout;

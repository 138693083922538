import React from 'react';

import { config, moment } from 'data';
import { formatter } from 'helpers';
import { useLang, useModal, useTable, useTableQuery } from 'hooks';
import { useAllCurrenciesQuery, useCurrencyRatesQuery } from 'hooks/queries';
import { Plus } from 'components/icons';
import { TableView } from 'components/layout';
import { Button, DateRangePicker, Flex, Select, Table } from 'components/ui';
import { DateRangePickerChangeHandler, SelectChangeHandler, TableColumns } from 'types/components';
import { CurrencyCode, CurrencyRate } from 'types/models';

import styles from './styles.module.css';

import Modal from './Modal';

type TableParams = {
  dateFrom: string;
  dateTo: string;
  currency: CurrencyCode;
};

const initialTableParams: TableParams = {
  dateFrom: moment().startOf('day').toISOString(),
  dateTo: moment().endOf('day').toISOString(),
  currency: '',
};

const CurrencyRatesView = () => {
  const lang = useLang();
  const modal = useModal();
  const table = useTable<CurrencyRate, TableParams>([config.CURRENCY_RATES_QUERY_KEY], initialTableParams);

  const currenciesQuery = useAllCurrenciesQuery();

  const currencyRatesQuery = useCurrencyRatesQuery({
    page: table.page,
    dateFrom: table.params.dateFrom || undefined,
    dateTo: table.params.dateTo || undefined,
    currency: table.params.currency || undefined,
  });

  const handleFilterCurrencyChange: SelectChangeHandler = (value) => table.setParam('currency', value);

  const handleFilterDatesChange: DateRangePickerChangeHandler = (dates) => {
    const [dateFrom, dateTo] = dates ?? [];

    table.setParams({
      dateFrom: dateFrom ? dateFrom.startOf('day').toISOString() : '',
      dateTo: dateTo ? dateTo.endOf('day').toISOString() : '',
    });
  };

  const handleCreateClick = modal.open;

  useTableQuery(table, currencyRatesQuery);

  const columns: TableColumns<CurrencyRate> = [
    {
      className: styles.table__date,
      key: 'date',
      title: lang.get('currencyRate.list.date'),
      render: (_, currencyRate) => formatter.formatDateTime(currencyRate.date),
    }, {
      className: styles.table__name,
      key: 'name',
      title: lang.get('currencyRate.list.currency'),
      render: (_, currencyRate) => currencyRate.targetCurrency,
    }, {
      className: styles.table__rate,
      key: 'rate',
      title: lang.get('currencyRate.list.rate'),
      render: (_, currencyRate) => formatter.formatNumber(currencyRate.rate, config.CURRENCY_RATE_PRECISION),
    },
  ];

  return (
    <TableView
      title={lang.get('currencyRate.list.title')}
      actions={(
        <Button icon={<Plus />} onClick={handleCreateClick}>
          {lang.get('currencyRate.list.create')}
        </Button>
      )}
      filters={(
        <Flex gap="small" align="center" wrap="wrap">
          <div className={styles.filter__pusher} />
          <Select
            defaultValue={table.params.currency}
            options={[{
              value: '',
              label: lang.get('currencyRate.filters.currencies'),
            }].concat((currenciesQuery.data ?? []).filter((currency) => currency.active).map((currency) => ({
              value: currency.code,
              label: currency.code,
            })))}
            loading={currenciesQuery.isFetching}
            popupMatchSelectWidth={false}
            showSearch
            onChange={handleFilterCurrencyChange}
          />
          <DateRangePicker
            defaultValue={[
              table.params.dateFrom ? moment(table.params.dateFrom) : null,
              table.params.dateTo ? moment(table.params.dateTo) : null,
            ]}
            maxDate={moment()}
            allowClear={false}
            onChange={handleFilterDatesChange}
          />
        </Flex>
      )}
    >

      <Table<CurrencyRate>
        columns={columns}
        dataSource={table.data}
        pagination={table.pagination}
        rowKey={(currencyRate) => currencyRate.id}
        loading={currencyRatesQuery.isFetching}
        onChange={table.onChange}
      />

      <Modal
        open={modal.opened}
        onClose={modal.close}
      />

    </TableView>
  );
};

export default CurrencyRatesView;

import { useCallback } from 'react';
import { QueryKey } from '@tanstack/react-query';

import useQueryClient from './query-client';

const useQueryInvalidate = () => {
  const queryClient = useQueryClient();

  return useCallback((queryKey: QueryKey) => queryClient.invalidateQueries({ queryKey }), [queryClient]);
};

export default useQueryInvalidate;

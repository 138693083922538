import React, { ChangeEventHandler, useState } from 'react';

import { config } from 'data';
import { formatter } from 'helpers';
import { useLang, useModal, useTable, useTableQuery } from 'hooks';
import { useAllCountriesQuery, useClientsQuery } from 'hooks/queries';
import { DashboardLayout } from 'layouts';
import { Eye } from 'components/icons';
import { TableView } from 'components/layout';
import { Flex, Select, Table, Tag } from 'components/ui';
import { SelectChangeHandler, TableColumns } from 'types/components';
import { Client, ClientStatus } from 'types/models';

import styles from './styles.module.css';

import Modal from './Modal';

type TableParams = {
  search: string;
  country: string;
  status: '' | ClientStatus;
};

const initialTableParams: TableParams = {
  search: '',
  country: '',
  status: ClientStatus.ACTIVE,
};

const ClientsPage = () => {
  const lang = useLang();
  const modal = useModal();
  const table = useTable<Client, TableParams>([config.CLIENTS_QUERY_KEY], initialTableParams);

  const [client, setClient] = useState<Client>();

  const countriesQuery = useAllCountriesQuery();

  const clientsQuery = useClientsQuery({
    page: table.page,
    search: table.params.search || undefined,
    country: table.params.country || undefined,
    status: table.params.status || undefined,
  });

  const handleFilterSearchChange: ChangeEventHandler<HTMLInputElement> = (event) => table.setParam('search', event.target.value);

  const handleFilterCountryChange: SelectChangeHandler = (value) => table.setParam('country', value);

  const handleFilterStatusChange: SelectChangeHandler = (value) => table.setParam('status', value);

  const handleViewClick = (client: Client) => () => {
    setClient(client);

    modal.open();
  };

  useTableQuery(table, clientsQuery);

  const columns: TableColumns<Client> = [
    {
      className: styles.table__email,
      key: 'email',
      title: lang.get('client.list.email'),
      render: (_, client) => <Table.Truncate width="md">{client.email}</Table.Truncate>,
    }, {
      key: 'firstName',
      title: lang.get('client.list.firstName'),
      render: (_, client) => <Table.Truncate>{client.firstName || '-'}</Table.Truncate>,
    }, {
      key: 'lastName',
      title: lang.get('client.list.lastName'),
      render: (_, client) => <Table.Truncate>{client.lastName || '-'}</Table.Truncate>,
    }, {
      key: 'country',
      title: lang.get('client.list.country'),
      render: (_, client) => client.residenceCountryCode
        ? formatter.formatCountry(countriesQuery.data ?? [], client.residenceCountryCode)
        : '-',
    }, {
      key: 'status',
      title: lang.get('client.list.status'),
      render: (_, client) => (
        <Tag color={client.status === ClientStatus.ACTIVE ? 'success' : 'default'}>
          {lang.get(`client.statuses.${client.status.toLowerCase()}`)}
        </Tag>
      ),
    }, {
      key: 'actions',
      title: lang.get('common.list.actions'),
      render: (_, client) => (
        <Table.Actions
          buttons={[{
            title: lang.get('common.view'),
            icon: <Eye />,
            onClick: handleViewClick(client),
          }]}
        />
      ),
    },
  ];

  return (
    <DashboardLayout title={lang.get('client.list.title')}>
      <TableView
        title={lang.get('client.list.title')}
        filters={(
          <Flex gap="small" align="center" wrap="wrap">
            <TableView.Search
              defaultValue={table.params.search}
              onChange={handleFilterSearchChange}
            />
            <Select
              defaultValue={table.params.country}
              options={[{
                value: '',
                label: lang.get('client.filters.country'),
              }].concat((countriesQuery.data ?? []).map((country) => ({
                value: country.code,
                label: country.name,
              })))}
              loading={countriesQuery.isFetching}
              popupMatchSelectWidth={false}
              showSearch
              onChange={handleFilterCountryChange}
            />
            <Select
              defaultValue={table.params.status}
              options={[{
                value: '',
                label: lang.get('client.filters.status'),
              }].concat(Object.values(ClientStatus).map((status) => ({
                value: status,
                label: lang.get(`client.statuses.${status.toLowerCase()}`),
              })))}
              popupMatchSelectWidth={false}
              onChange={handleFilterStatusChange}
            />
          </Flex>
        )}
      >

        <Table<Client>
          columns={columns}
          dataSource={table.data}
          pagination={table.pagination}
          rowKey={(client) => client.id}
          loading={clientsQuery.isFetching}
          clickable
          onRow={(client) => ({ onClick: handleViewClick(client) })}
          onChange={table.onChange}
        />
      </TableView>

      {client && (
        <Modal
          client={client}
          open={modal.opened}
          onClose={modal.close}
        />
      )}

    </DashboardLayout>
  );
};

export default ClientsPage;

import { config } from 'data';
import { currencyService } from 'services';
import { useQuery } from 'hooks';
import { CurrenciesParams } from 'types/services';

const useAllCurrenciesQuery = (params?: CurrenciesParams) => useQuery({
  queryKey: [config.CURRENCIES_QUERY_KEY, 'all', params],
  queryFn: () => currencyService.getCurrencies(params),
});

export default useAllCurrenciesQuery;

import React, { FC } from 'react';

import { Flex, Text } from 'components/ui';
import { Override } from 'types/common';
import { FlexProps } from 'types/components';

type UserInfoProps = Override<Omit<FlexProps, 'children'>, {
  nameClassName?: string;
  emailClassName?: string;
  name: string;
  email: string;
}>;

const UserInfo: FC<UserInfoProps> = ({
  nameClassName,
  emailClassName,
  name,
  email,
  ...props
}) => (
  <Flex vertical {...props}>
    <Text className={nameClassName}>{name}</Text>
    <Text className={emailClassName} type="secondary">{email}</Text>
  </Flex>
);

export default UserInfo;

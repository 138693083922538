import React, { FC } from 'react';

import { IconProps } from 'types/components';

import Icon from './Icon';

const Trash: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path d="M2.5 5h1.667m0 0H17.5M4.167 5v11.667a1.667 1.667 0 0 0 1.666 1.666h8.334a1.667 1.667 0 0 0 1.666-1.666V5H4.167Zm2.5 0V3.333a1.667 1.667 0 0 1 1.666-1.666h3.334a1.667 1.667 0 0 1 1.666 1.666V5m-5 4.167v5m3.334-5v5" />
  </Icon>
);

export default Trash;

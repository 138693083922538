import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';

import 'data/i18next';

import { OfflineNotification } from 'components/layout';
import { App, Auth, Lang, Preloader, Query, Theme } from 'context';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Theme.Provider>
    <Preloader.Provider>
      <Lang.Provider>
        <Query.Provider>
          <Auth.Provider>
            <App.Provider />
          </Auth.Provider>
        </Query.Provider>
        <OfflineNotification />
      </Lang.Provider>
    </Preloader.Provider>
  </Theme.Provider>,
);

import React, { FC } from 'react';

import { ErrorPage } from 'pages';
import { HttpStatus } from 'types/common';
import { ErrorPageProps } from 'types/pages';
import { RouteError } from 'types/routes';

const error = new Error('Page not found.') as RouteError;

error.status = HttpStatus.NOT_FOUND;

const NotFoundPage: FC<ErrorPageProps> = ({ customError = error, ...props }) => (
  <ErrorPage customError={customError} {...props} />
);

export default NotFoundPage;

import React, { FC } from 'react';

import { config, yup } from 'data';
import { businessAccountService, clientService } from 'services';
import { useForm, useLang, useMutation, useQueryInvalidate } from 'hooks';
import { Form, Modal, Select } from 'components/ui';
import { Uuid } from 'types/common';
import { ModalBaseProps } from 'types/components';
import { BusinessAccountRoleId } from 'types/models';
import { BusinessAccountAddMemberParams } from 'types/services';

import RolesSelect from './RolesSelect';

type AddMemberModalProps = ModalBaseProps & {
  businessAccountId: Uuid;
};

type FormValues = BusinessAccountAddMemberParams & {
  id: Uuid;
};

const roleIds = Object.values(BusinessAccountRoleId);

const initialValues: FormValues = {
  id: '',
  roleIds: [],
};

const validationSchema = yup.object({
  id: yup.string().required(),
  roleIds: yup.array().required().min(1).of(
    yup.string().required().oneOf(roleIds),
  ),
});

const AddMemberModal: FC<AddMemberModalProps> = ({
  businessAccountId,
  open,
  onClose,
}) => {
  const form = useForm<FormValues>();
  const lang = useLang();
  const queryInvalidate = useQueryInvalidate();

  const addMemberMutation = useMutation({
    mutationFn: (values: FormValues) => businessAccountService.addMember(businessAccountId, values.id, values),
    onSuccess: () => queryInvalidate([config.BUSINESS_ACCOUNT_MEMBERS_QUERY_KEY, businessAccountId]),
    successNotification: lang.get('businessAccount.member.modal.addSuccess'),
  });

  const handleClientSearch = async (query: string) => {
    const { list } = await clientService.getClients({ search: query || undefined });

    return list.map((client) => ({
      value: client.id,
      label: client.email,
    }));
  };

  const handleSubmit = async (values: FormValues) => {
    await addMemberMutation.mutateAsync(values);

    onClose();
  };

  return (
    <Modal
      title={lang.get('businessAccount.member.modal.addTitle')}
      caption={lang.get('businessAccount.member.modal.addCaption')}
      okText={lang.get('common.add')}
      width="sm"
      open={open}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onFinish={handleSubmit}
      >
        <Form.Item name="id" label={lang.get('businessAccount.member.modal.client.label')}>
          <Select.Ajax
            placeholder={lang.get('businessAccount.member.modal.client.placeholder')}
            onSearch={handleClientSearch}
          />
        </Form.Item>
        <Form.Item name="roleIds" label={lang.get('businessAccount.member.modal.roles.label')}>
          <RolesSelect />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddMemberModal;

import React, { FC } from 'react';

import { IconProps } from 'types/components';

import Icon from './Icon';

const Table: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path d="M7.5 3H4.2c-1 0-1.7.7-1.7 1.7V8m5-5h8.3c.9 0 1.7.7 1.7 1.7V8m-10-5v15m0 0h8.3c.9 0 1.7-.7 1.7-1.7V8m-10 10H4.2c-.9 0-1.7-.7-1.7-1.7V8m0 0h15" />
  </Icon>
);

export default Table;

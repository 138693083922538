import React, { FC } from 'react';

import { FormItemProps } from 'types/components';

import Item from './Item';

const CheckboxItem: FC<FormItemProps> = ({
  valuePropName = 'checked',
  ...props
}) => (
  <Item
    valuePropName={valuePropName}
    {...props}
  />
);

export default CheckboxItem;

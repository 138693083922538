import { axios } from 'data';
import { Uuid } from 'types/common';
import { UploadFile } from 'types/components';
import { UploadedFile } from 'types/models';

const uploadFile = async (file: UploadFile) => {
  const response = await axios.postForm<UploadedFile>('/payments/admin/file', { file });

  return response.data;
};

const deleteFile = async (id: Uuid) => {
  await axios.delete<never>(`/payments/admin/file/${id}`);
};

const fileService = {
  uploadFile,
  deleteFile,
};

export default fileService;

import React, { FC } from 'react';
import { FormItemProps } from 'antd';
import cx from 'classnames';

import DividerComponent from '../Divider';

import styles from './styles.module.css';

import Item from './Item';

const Divider: FC<FormItemProps> = ({ className, ...props }) => (
  <Item className={cx(styles.divider, className)} {...props}>
    <DividerComponent className={styles.divider__line} />
  </Item>
);

export default Divider;

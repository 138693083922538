import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { config } from 'data';
import { url } from 'helpers';
import { authService } from 'services';
import { useQuery } from 'hooks';
import { SinglePage } from 'components/layout';
import { Uuid } from 'types/common';

const AuthPage = () => {
  const params = useParams();

  const hash = params.hash as Uuid;

  const authChallengeQuery = useQuery({
    queryKey: [config.AUTH_CHALLENGE_QUERY_KEY, hash],
    queryFn: () => authService.getAuthChallenge(hash),
  });

  return (
    <SinglePage query={authChallengeQuery} render={(data) => (
      <Navigate to={url.toPasswordChange(hash)} state={data} />
    )} />
  );
};

export default AuthPage;

import React, { FC, ReactNode } from 'react';
import { Modal as BaseModal, ModalProps as BaseModalProps } from 'antd';
import cx from 'classnames';

import { useLang } from 'hooks';
import { Flex } from 'components/ui';
import { Override } from 'types/common';

import Paragraph from '../Paragraph';
import Title from '../Title';

import styles from './styles.module.css';

const widths = {
  sm: 420,
  md: 640,
  lg: 860,
};

type ModalProps = Override<BaseModalProps, {
  caption?: ReactNode;
  extraActions?: ReactNode;
  width?: keyof typeof widths;
}>;

const Modal: FC<ModalProps> = ({
  className,
  title,
  caption,
  cancelText,
  width = 'md',
  closeIcon = null,
  extraActions,
  okButtonProps,
  cancelButtonProps,
  destroyOnClose = true,
  ...props
}) => {
  const lang = useLang();

  return (
    <BaseModal
      className={cx(styles.root, className)}
      title={title && (
        <>
          <Title className={styles.title} level={3}>{title}</Title>
          <Paragraph className={styles.caption}>{caption}</Paragraph>
        </>
      )}
      footer={(originalNode) => (
        <Flex className={styles.footer} gap="small">
          {extraActions && <Flex className={styles.footer__extra} gap="small">{extraActions}</Flex>}
          <Flex className={styles.footer__actions} gap="small">{originalNode}</Flex>
        </Flex>
      )}
      cancelText={cancelText ?? lang.get('common.cancel')}
      width={widths[width]}
      closeIcon={closeIcon}
      okButtonProps={{ ...okButtonProps, size: 'large' }}
      cancelButtonProps={{ ...cancelButtonProps, size: 'large' }}
      destroyOnClose={destroyOnClose}
      {...props}
    />
  );
};

export default Modal;

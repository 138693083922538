import React, { FC } from 'react';
import { Radio as BaseRadio } from 'antd';

import { RadioProps } from 'types/components';

import Group from './Group';

const Radio: FC<RadioProps> = (props) => (
  <BaseRadio {...props} />
);

export default Object.assign(Radio, { Button: BaseRadio.Button, Group });

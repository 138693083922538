import { addMethod, MixedSchema } from 'yup';

import { config } from 'data';
import { file } from 'helpers';
import { UploadFile } from 'types/components';

import { createError } from '../helpers';

addMethod(MixedSchema<UploadFile>, 'fileSize', function validate(size: number = config.FILE_MAX_SIZE) {
  return this.test((value, context) => {
    if (!file.isUploadFile(value) || (value.size && value.size <= size)) {
      return true;
    }

    return createError(context, 'fileSize', {
      size: Math.round(size / 1024 / 1024),
    });
  });
});

import { config } from 'data';
import { feeCommissionService } from 'services';
import { useQuery } from 'hooks';
import { FeeCommissionsParams } from 'types/services';

const useFeeCommissionsQuery = (params?: FeeCommissionsParams) => useQuery({
  queryKey: [config.FEE_COMMISSIONS_QUERY_KEY, params],
  queryFn: () => feeCommissionService.getFeeCommissions(params),
});

export default useFeeCommissionsQuery;

import { config } from 'data';
import { feeGroupService } from 'services';
import { useQuery } from 'hooks';
import { FeeGroupsParams } from 'types/services';

const useFeeGroupsQuery = (params?: FeeGroupsParams) => useQuery({
  queryKey: [config.FEE_GROUPS_QUERY_KEY, params],
  queryFn: () => feeGroupService.getFeeGroups(params),
});

export default useFeeGroupsQuery;

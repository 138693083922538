import React from 'react';

import { config, moment } from 'data';
import { formatter, xlsx } from 'helpers';
import { reportService } from 'services';
import { fetchPaginatedResponseFully } from 'services/helpers';
import { useLang, useQuery, useTable, useTableQuery } from 'hooks';
import { DashboardLayout } from 'layouts';
import { Table as TableIcon } from 'components/icons';
import { TableView } from 'components/layout';
import { Button, DateRangePicker, Flex, Table } from 'components/ui';
import { XlsxHeader, XlsxRow } from 'types/common';
import { DateRangePickerChangeHandler, TableColumns } from 'types/components';
import { ReportDailyRemittanceItem } from 'types/models';
import { ReportDailyRemittanceParams } from 'types/services';

import styles from './styles.module.css';

const EXPORT_FILE_NAME = 'report-daily-remittance';

type TableParams = {
  dateFrom: string;
  dateTo: string;
};

const initialTableParams: TableParams = {
  dateFrom: moment().subtract(1, 'day').startOf('day').toISOString(),
  dateTo: moment().subtract(1, 'day').endOf('day').toISOString(),
};

const DailyRemittancePage = () => {
  const lang = useLang();
  const table = useTable<ReportDailyRemittanceItem, TableParams>([config.DAILY_REMITTANCE_QUERY_KEY], initialTableParams);

  const reportParams: ReportDailyRemittanceParams = {
    page: table.page,
    dateFrom: table.params.dateFrom,
    dateTo: table.params.dateTo,
  };

  const reportQuery = useQuery({
    queryKey: [config.DAILY_REMITTANCE_QUERY_KEY, reportParams],
    queryFn: () => reportService.getDailyRemittance(reportParams),
  });

  const handleFilterDatesChange: DateRangePickerChangeHandler = (dates) => {
    const [dateFrom, dateTo] = dates ?? [];

    table.setParams({
      dateFrom: dateFrom ? dateFrom.startOf('day').toISOString() : '',
      dateTo: dateTo ? dateTo.endOf('day').toISOString() : '',
    });
  };

  const handleExportClick = async () => {
    try {
      table.setExporting(true);

      const fileName = [
        EXPORT_FILE_NAME,
        moment(table.params.dateFrom).format(config.DATE_RAW_FORMAT),
        moment(table.params.dateTo).format(config.DATE_RAW_FORMAT),
      ].join('-');

      const data = await fetchPaginatedResponseFully(reportService.getDailyRemittance, reportParams);

      const headers: XlsxHeader[] = [
        lang.get('report.dailyRemittance.date'),
        lang.get('report.dailyRemittance.filesUploaded'),
        lang.get('report.dailyRemittance.transactionsAccepted'),
        lang.get('report.dailyRemittance.transactionsRejected'),
        lang.get('report.dailyRemittance.currency'),
        lang.get('report.dailyRemittance.amountRemitted'),
        lang.get('report.dailyRemittance.amountRejected'),
        lang.get('report.dailyRemittance.feesRemitted'),
      ];

      const rows: XlsxRow[] = data.map((item) => ({
        date: formatDateColumn(item),
        filesUploaded: formatFilesUploadedColumn(item),
        transactionsAccepted: formatTransactionsAcceptedColumn(item),
        transactionsRejected: formatTransactionsRejectedColumn(item),
        currency: formatCurrencyColumn(item),
        amountRemitted: formatAmountRemittedColumn(item),
        amountRejected: formatAmountRejectedColumn(item),
        feesRemitted: formatFeesRemittedColumn(item),
      }));

      xlsx.exportFile(fileName, headers, rows);
    } finally {
      table.setExporting(false);
    }
  };

  useTableQuery(table, reportQuery);

  const formatDateColumn = (item: ReportDailyRemittanceItem) => formatter.formatDate(item.date);

  const formatFilesUploadedColumn = (item: ReportDailyRemittanceItem) => formatter.formatNumber(item.uploadedFiles, 0);

  const formatTransactionsAcceptedColumn = (item: ReportDailyRemittanceItem) => formatter.formatNumber(item.acceptedTransactions, 0);

  const formatTransactionsRejectedColumn = (item: ReportDailyRemittanceItem) => formatter.formatNumber(item.rejectedTransactions, 0);

  const formatCurrencyColumn = (item: ReportDailyRemittanceItem) => item.currency;

  const formatAmountRemittedColumn = (item: ReportDailyRemittanceItem) => formatter.formatNumber(item.remittedAmount);

  const formatAmountRejectedColumn = (item: ReportDailyRemittanceItem) => formatter.formatNumber(item.rejectedAmount);

  const formatFeesRemittedColumn = (item: ReportDailyRemittanceItem) => formatter.formatNumber(item.remittedFees);

  const columns: TableColumns<ReportDailyRemittanceItem> = [
    {
      className: styles.table__date,
      key: 'date',
      title: lang.get('report.dailyRemittance.date'),
      render: (_, item) => formatDateColumn(item),
    }, {
      key: 'filesUploaded',
      title: lang.get('report.dailyRemittance.filesUploaded'),
      render: (_, item) => formatFilesUploadedColumn(item),
    }, {
      key: 'transactionsAccepted',
      title: lang.get('report.dailyRemittance.transactionsAccepted'),
      render: (_, item) => formatTransactionsAcceptedColumn(item),
    }, {
      key: 'transactionsRejected',
      title: lang.get('report.dailyRemittance.transactionsRejected'),
      render: (_, item) => formatTransactionsRejectedColumn(item),
    }, {
      key: 'currency',
      title: lang.get('report.dailyRemittance.currency'),
      render: (_, item) => formatCurrencyColumn(item),
    }, {
      className: styles.table__amount,
      key: 'amountRemitted',
      title: lang.get('report.dailyRemittance.amountRemitted'),
      render: (_, item) => formatAmountRemittedColumn(item),
    }, {
      className: styles.table__balance,
      key: 'amountRejected',
      title: lang.get('report.dailyRemittance.amountRejected'),
      render: (_, item) => formatAmountRejectedColumn(item),
    }, {
      className: styles.table__balance,
      key: 'feesRemitted',
      title: lang.get('report.dailyRemittance.feesRemitted'),
      render: (_, item) => formatFeesRemittedColumn(item),
    },
  ];

  const title = lang.get('report.dailyRemittance.title');

  return (
    <DashboardLayout title={title}>
      <TableView
        title={title}
        actions={(
          <Button
            icon={<TableIcon />}
            loading={table.exporting}
            disabled={!table.data.length}
            onClick={handleExportClick}
          >
            {lang.get('common.exportXlsx')}
          </Button>
        )}
        filters={(
          <Flex gap="small" align="center" justify="flex-end" wrap="wrap">
            <DateRangePicker
              defaultValue={[moment(table.params.dateFrom), moment(table.params.dateTo)]}
              maxDate={moment().subtract(1, 'day').endOf('day')}
              allowClear={false}
              onChange={handleFilterDatesChange}
            />
          </Flex>
        )}
      >
        <Table<ReportDailyRemittanceItem>
          columns={columns}
          dataSource={table.data}
          pagination={table.pagination}
          rowKey={(item) => item.date}
          loading={reportQuery.isFetching}
          onChange={table.onChange}
        />
      </TableView>
    </DashboardLayout>
  );
};

export default DailyRemittancePage;

import React, { FC } from 'react';

import { IconProps } from 'types/components';

import Icon from './Icon';

const Search: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path d="m17.5 17.5-3.625-3.625m1.958-4.708a6.667 6.667 0 1 1-13.333 0 6.667 6.667 0 0 1 13.333 0Z" />
  </Icon>
);

export default Search;

import React, { FC, useEffect } from 'react';

import { config } from 'data';
import { businessAccountService } from 'services';
import { useForm, useLang, useModal, useMutation, useQueryInvalidate } from 'hooks';
import { Trash } from 'components/icons';
import { Button, Form, Input, Modal, PopconfirmButton } from 'components/ui';
import { Uuid } from 'types/common';
import { ModalBaseProps } from 'types/components';
import { BusinessAccountMember } from 'types/models';

import RolesModal from './RolesModal';

type MemberModalProps = ModalBaseProps & {
  businessAccountId: Uuid;
  member: BusinessAccountMember;
};

type FormValues = BusinessAccountMember;

const MemberModal: FC<MemberModalProps> = ({
  businessAccountId,
  member,
  open,
  onClose,
}) => {
  const form = useForm<FormValues>();
  const lang = useLang();
  const rolesModal = useModal();
  const queryInvalidate = useQueryInvalidate();

  const removeMemberMutation = useMutation({
    mutationFn: () => businessAccountService.removeMember(businessAccountId, member.id),
    onSuccess: () => queryInvalidate([config.BUSINESS_ACCOUNT_MEMBERS_QUERY_KEY, businessAccountId]),
    successNotification: lang.get('businessAccount.member.modal.removeSuccess', { email: member.email }),
  });

  const handleRemove = async () => {
    await removeMemberMutation.mutateAsync();

    onClose();
  };

  useEffect(() => {
    if (open && member) {
      form.setFieldsValue(member);
    }
  }, [member, open, form]);

  useEffect(() => {
    if (!open) {
      rolesModal.close();
    }
  }, [open, rolesModal]);

  return (
    <Modal
      title={lang.get('businessAccount.member.modal.viewTitle', { email: member.email })}
      caption={lang.get('businessAccount.member.modal.viewCaption')}
      cancelText={lang.get('common.close')}
      okButtonProps={{ hidden: true }}
      extraActions={(
        <PopconfirmButton
          title={lang.get('businessAccount.member.modal.removeTitle')}
          icon={<Trash />}
          danger
          loading={removeMemberMutation.isPending}
          onConfirm={handleRemove}
        >
          {lang.get('common.remove')}
        </PopconfirmButton>
      )}
      open={open}
      onCancel={onClose}
    >

      <Form form={form}>

        <Form.ActionsItem label={lang.choice('businessAccount.member.modal.rolesLabel', member.roles.length)}>
          <Button type="default" onClick={rolesModal.open}>
            {lang.get('businessAccount.member.modal.rolesButton')}
          </Button>
        </Form.ActionsItem>

        <Form.Divider />

        <Form.Columns>
          <Form.Item name="firstName" label={lang.get('common.form.firstName.label')}>
            <Input placeholder={lang.get('common.form.firstName.placeholder')} readOnly />
          </Form.Item>
          <Form.Item name="lastName" label={lang.get('common.form.lastName.label')}>
            <Input placeholder={lang.get('common.form.lastName.placeholder')} readOnly />
          </Form.Item>
        </Form.Columns>
        <Form.Columns>
          <Form.Item name="email" label={lang.get('common.form.email.label')}>
            <Input.Email readOnly />
          </Form.Item>
          <Form.Item name="phone" label={lang.get('common.form.phone.label')}>
            <Input.Phone readOnly />
          </Form.Item>
        </Form.Columns>

      </Form>

      <RolesModal
        businessAccountId={businessAccountId}
        member={member}
        open={rolesModal.opened}
        onClose={rolesModal.close}
      />

    </Modal>
  );
};

export default MemberModal;

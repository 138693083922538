import { config } from 'data';
import { feeGroupService } from 'services';
import { fetchPaginatedResponseFully } from 'services/helpers';
import { useQuery } from 'hooks';
import { FeeGroupsParams } from 'types/services';

const useAllFeeGroupsQuery = (params?: FeeGroupsParams) => useQuery({
  queryKey: [config.FEE_GROUPS_QUERY_KEY, 'all', params],
  queryFn: () => fetchPaginatedResponseFully(feeGroupService.getFeeGroups, params),
});

export default useAllFeeGroupsQuery;

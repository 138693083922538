import { config } from 'data';
import { userService } from 'services';
import { fetchPaginatedResponseFully } from 'services/helpers';
import { useQuery } from 'hooks';
import { UsersParams } from 'types/services';

const useAllUsersQuery = (params?: UsersParams) => useQuery({
  queryKey: [config.USERS_QUERY_KEY, 'all', params],
  queryFn: () => fetchPaginatedResponseFully(userService.getUsers, params),
});

export default useAllUsersQuery;

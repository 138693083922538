import React, { useEffect } from 'react';

import { useLang, useTheme } from 'hooks';
import { WifiOff } from 'components/icons';

const KEY = 'offline';

const OfflineNotification = () => {
  const lang = useLang();
  const theme = useTheme();

  useEffect(() => {
    const showNotification = () => {
      theme.notification?.info({
        key: KEY,
        message: lang.get('common.offline.title'),
        description: lang.get('common.offline.caption'),
        icon: <WifiOff />,
        closeIcon: false,
        duration: 0,
      });
    };

    const hideNotification = () => {
      theme.notification?.destroy(KEY);
    };

    window.addEventListener('online', hideNotification);
    window.addEventListener('offline', showNotification);

    return () => {
      window.removeEventListener('online', hideNotification);
      window.removeEventListener('offline', showNotification);
    };
  }, [lang, theme.notification]);

  return null;
};

export default OfflineNotification;

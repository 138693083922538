import React, { FC } from 'react';

import { IconProps } from 'types/components';

import Icon from './Icon';

const Mail: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path d="M18.333 5c0-.917-.75-1.667-1.667-1.667H3.333c-.917 0-1.667.75-1.667 1.667m16.667 0v10c0 .917-.75 1.667-1.667 1.667H3.333c-.917 0-1.667-.75-1.667-1.667V5m16.667 0-8.334 5.833L1.666 5" />
  </Icon>
);

export default Mail;

import { addMethod, Reference, Schema } from 'yup';

import { createError } from '../helpers';

addMethod(Schema, 'confirmation', function validate(reference: Reference) {
  return this.test((value, context) => {
    const referenceValue = context.resolve(reference);

    if (!value || value === referenceValue) {
      return true;
    }

    return createError(context, 'confirmation');
  });
});

import React, { FC } from 'react';
import cx from 'classnames';

import { Flex, Paragraph, Title } from 'components/ui';
import { CardViewProps } from 'types/components';

import styles from './styles.module.css';

const CardView: FC<CardViewProps> = ({
  className,
  title,
  caption,
  actions,
  children,
}) => {
  return (
    <div className={cx(styles.root, className)}>
      <Flex
        className={styles.header}
        gap="small"
        align="center"
        justify="space-between"
        wrap="wrap"
      >
        <div>
          <Title className={styles.title} level={3}>{title}</Title>
          {caption && <Paragraph className={styles.caption}>{caption}</Paragraph>}
        </div>
        {actions && <div>{actions}</div>}
      </Flex>
      {children}
    </div>
  );
};

export default CardView;

import React, { FC } from 'react';
import { DatePicker as BaseDatePicker } from 'antd';
import { PickerProps } from 'antd/es/date-picker/generatePicker';
import { Moment } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import cx from 'classnames';

import { config, moment } from 'data';
import { Calendar } from 'components/icons';
import { Nullable, Override } from 'types/common';

import styles from './styles.module.css';

const CustomDatePicker = BaseDatePicker.generatePicker<Moment>(momentGenerateConfig);

type CustomDatePickerProps = PickerProps<Moment>;

type DatePickerProps = Override<CustomDatePickerProps, {
  value?: Nullable<Moment | string>;
}>;

const DatePicker: FC<DatePickerProps> = ({
  className,
  format = config.DATE_FORMAT,
  size = 'large',
  value,
  suffixIcon = <Calendar />,
  inputReadOnly = true,
  ...props
}) => (
  <CustomDatePicker
    className={cx(styles.root, className)}
    format={format}
    size={size}
    value={value ? moment(value) : null}
    suffixIcon={suffixIcon}
    inputReadOnly={inputReadOnly}
    {...props}
  />
);

export { CustomDatePicker };

export default DatePicker;

import React, { FC, useEffect } from 'react';
import map from 'lodash/map';

import { config, yup } from 'data';
import { userService } from 'services';
import { useAuth, useForm, useLang, useModal, useMutation, useQueryInvalidate } from 'hooks';
import { Trash } from 'components/icons';
import { Button, Form, Input, Modal, PopconfirmButton } from 'components/ui';
import { Nullable } from 'types/common';
import { ModalBaseProps } from 'types/components';
import { User } from 'types/models';
import { UserCreateParams } from 'types/services';

import RolesModal from './RolesModal';
import RoleSelect from './RoleSelect';

type UserModalProps = ModalBaseProps & {
  user?: Nullable<User>;
};

type FormValues = UserCreateParams;

const initialValues: FormValues = {
  email: '',
  roleIds: [],
};

const validationSchema = yup.object({
  firstName: yup.string().notRequired().trim().max(config.NAME_MAX_LENGTH),
  lastName: yup.string().notRequired().trim().max(config.NAME_MAX_LENGTH),
  email: yup.string().required().email(),
  phone: yup.string().notRequired().phone(),
  roleIds: yup.array().required().min(1).of(
    yup.string().required().uuid(),
  ),
});

const UserModal: FC<UserModalProps> = ({
  user,
  open,
  onClose,
}) => {
  const auth = useAuth();
  const form = useForm<FormValues>();
  const lang = useLang();
  const rolesModal = useModal();
  const queryInvalidate = useQueryInvalidate();

  const userId = user?.id ?? '';
  const userEmail = user?.email ?? '';

  const invalidateUserQueries = async () => {
    await queryInvalidate([config.USERS_QUERY_KEY]);
    await queryInvalidate([config.USER_QUERY_KEY, userId]);
  };

  const createUserMutation = useMutation({
    mutationFn: userService.createUser,
    onSuccess: invalidateUserQueries,
    successNotification: lang.get('user.modal.createSuccess'),
  });

  const deleteUserMutation = useMutation({
    mutationFn: () => userService.deleteUser(userId),
    onSuccess: invalidateUserQueries,
    successNotification: lang.get('user.modal.deleteSuccess', { email: userEmail }),
  });

  const handleSubmit = async (values: FormValues) => {
    await createUserMutation.mutateAsync(values);

    onClose();
  };

  const handleDelete = async () => {
    await deleteUserMutation.mutateAsync();

    onClose();
  };

  useEffect(() => {
    if (open && user) {
      form.setFieldsValue({
        ...user,
        roleIds: map(user.roles, 'id'),
      });
    }
  }, [user, open, form]);

  useEffect(() => {
    if (!open) {
      rolesModal.close();
    }
  }, [open, rolesModal]);

  const isOwned = user?.id === auth.profile?.id;
  const isViewing = Boolean(user);

  return (
    <Modal
      title={
        isViewing
          ? lang.get('user.modal.viewTitle', { email: userEmail })
          : lang.get('user.modal.createTitle')
      }
      caption={
        isViewing
          ? lang.get('user.modal.viewCaption')
          : lang.get('user.modal.createCaption')
      }
      okText={isViewing ? lang.get('common.save') : lang.get('common.create')}
      cancelText={isViewing ? lang.get('common.close') : null}
      okButtonProps={{ hidden: isViewing }}
      extraActions={isViewing && !isOwned && (
        <PopconfirmButton
          title={lang.get('user.modal.deleteTitle')}
          icon={<Trash />}
          danger
          loading={deleteUserMutation.isPending}
          onConfirm={handleDelete}
        >
          {lang.get('common.delete')}
        </PopconfirmButton>
      )}
      open={open}
      confirmLoading={createUserMutation.isPending}
      onOk={form.submit}
      onCancel={onClose}
    >

      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onFinish={handleSubmit}
      >

        <Form.ActionsItem
          label={lang.choice('user.modal.rolesLabel', user?.roles.length ?? 0)}
          hidden={!isViewing || isOwned}
        >
          <Button type="default" onClick={rolesModal.open}>
            {lang.get('user.modal.rolesButton')}
          </Button>
        </Form.ActionsItem>

        <Form.Divider hidden={!isViewing || isOwned} />

        <Form.Columns>
          <Form.Item name="firstName" label={lang.get('common.form.firstName.label')}>
            <Input placeholder={lang.get('common.form.firstName.placeholder')} readOnly={isViewing} />
          </Form.Item>
          <Form.Item name="lastName" label={lang.get('common.form.lastName.label')}>
            <Input placeholder={lang.get('common.form.lastName.placeholder')} readOnly={isViewing} />
          </Form.Item>
        </Form.Columns>
        <Form.Columns>
          <Form.Item name="email" label={lang.get('common.form.email.label')}>
            <Input.Email readOnly={isViewing} />
          </Form.Item>
          <Form.Item name="phone" label={lang.get('common.form.phone.label')}>
            <Input.Phone readOnly={isViewing} />
          </Form.Item>
        </Form.Columns>
        <Form.Item name="roleIds" label={lang.get('user.modal.roles.label')} hidden={isViewing}>
          <RoleSelect mode="multiple" disabled={isViewing} />
        </Form.Item>

      </Form>

      {user && (
        <RolesModal
          user={user}
          open={rolesModal.opened}
          onClose={rolesModal.close}
        />
      )}

    </Modal>
  );
};

export default UserModal;

import React, { FC, ReactNode } from 'react';

import { Container, Layout } from 'components/ui';

import styles from './styles.module.css';

type ContentProps = {
  children: ReactNode;
};

const Content: FC<ContentProps> = ({ children }) => (
  <Layout.Content className={styles.content}>
    <Container>{children}</Container>
  </Layout.Content>
);

export default Content;

import { config } from 'data';
import { businessAccountService } from 'services';
import { useQuery } from 'hooks';
import { BusinessAccountsParams } from 'types/services';

const useBusinessAccountsQuery = (params?: BusinessAccountsParams) => useQuery({
  queryKey: [config.BUSINESS_ACCOUNTS_QUERY_KEY, params],
  queryFn: () => businessAccountService.getBusinessAccounts(params),
});

export default useBusinessAccountsQuery;

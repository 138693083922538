import { config } from 'data';
import { clientService } from 'services';
import { useQuery } from 'hooks';
import { ClientsParams } from 'types/services';

const useClientsQuery = (params?: ClientsParams) => useQuery({
  queryKey: [config.CLIENTS_QUERY_KEY, params],
  queryFn: () => clientService.getClients(params),
});

export default useClientsQuery;

import { isValidPhoneNumber } from 'react-phone-number-input/input';
import { addMethod, StringSchema } from 'yup';

import { config } from 'data';

import { createError } from '../helpers';

addMethod(StringSchema, 'phone', function validate() {
  return this.trim()
    .max(config.PHONE_MAX_LENGTH)
    .test((value, context) => {
      if (!value || isValidPhoneNumber(value)) {
        return true;
      }

      return createError(context, 'phone');
    });
});

import { Nullish } from 'types/common';

const get = <Type>(key: string) => {
  const value = localStorage.getItem(key);

  return decodeValue<Type>(value);
};

const set = <Type>(key: string, value: Type) => {
  const encodedValue = encodeValue(value);

  if (encodedValue) {
    localStorage.setItem(key, encodedValue);
  } else {
    remove(key);
  }
};

const remove = (key: string) => {
  localStorage.removeItem(key);
};

const encodeValue = <Type>(value: Type) => {
  if (!value) {
    return null;
  }

  try {
    return JSON.stringify(value);
  } catch (err) {
    return null;
  }
};

const decodeValue = <Type>(value: Nullish<string>) => {
  if (!value) {
    return null;
  }

  try {
    return JSON.parse(value) as Type;
  } catch (err) {
    return null;
  }
};

const storage = {
  get,
  set,
  remove,
};

export default storage;

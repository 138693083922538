import { config } from 'data';
import { costService } from 'services';
import { useQuery } from 'hooks';
import { CostsParams } from 'types/services';

const useCostsQuery = (params?: CostsParams) => useQuery({
  queryKey: [config.COSTS_QUERY_KEY, params],
  queryFn: () => costService.getCosts(params),
});

export default useCostsQuery;

import React, { FC, useEffect, useState } from 'react';

import { config, yup } from 'data';
import { businessAccountService, transactionService } from 'services';
import { useForm, useFormWatch, useLang, useMutation, useQueryInvalidate } from 'hooks';
import { useBusinessAccountBalanceQuery, useBusinessAccountQuery } from 'hooks/queries';
import { Form, Input, Modal, PopconfirmButton, Select, Space, TextArea } from 'components/ui';
import { Uuid } from 'types/common';
import { ModalBaseProps } from 'types/components';
import { BusinessAccount, Transaction, TransactionStatus } from 'types/models';
import { TransactionCreateParams } from 'types/services';

import Balance from '../BusinessAccountBalance';
import UserSelect from '../UserSelect';

import styles from './styles.module.css';

type TransactionModalProps = ModalBaseProps & {
  businessAccount?: BusinessAccount;
  transaction?: Transaction;
};

type FormValues = TransactionCreateParams & {
  id?: Uuid;
  createdById?: Uuid;
  updatedById?: Uuid;
};

const initialValues: Omit<FormValues, 'amount'> = {
  clientId: '',
  currency: config.DEFAULT_CURRENCY,
};

const validationSchema = yup.object({
  clientId: yup.string().required().uuid(),
  referenceId: yup.string().notRequired().trim().max(config.STRING_MD_MAX_LENGTH),
  amount: yup.lazy((value) => {
    const schema = yup.number().required().decimal().transform((value) => value ? value : 0);

    return +value >= 0
      ? schema.min(config.TOP_UP_MIN).max(config.TOP_UP_MAX)
      : schema.min(-config.TOP_UP_MAX).max(-config.TOP_UP_MIN);
  }),
  currency: yup.string().required().currency(),
  notes: yup.string().notRequired().trim().max(config.TEXT_MAX_LENGTH),
});

const TransactionModal: FC<TransactionModalProps> = ({
  businessAccount,
  transaction,
  open,
  onClose,
}) => {
  const form = useForm<FormValues>();
  const currentCurrency = useFormWatch('currency', form);
  const lang = useLang();
  const queryInvalidate = useQueryInvalidate();

  const [businessAccountId, setBusinessAccountId] = useState<Uuid>();
  const [currentBusinessAccount, setCurrentBusinessAccount] = useState<BusinessAccount>();

  const transactionId = transaction?.id ?? '';

  const businessAccountQuery = useBusinessAccountQuery(businessAccountId);
  const businessAccountBalanceQuery = useBusinessAccountBalanceQuery(businessAccountId);

  const invalidateTransactionQueries = async () => {
    await queryInvalidate([config.TRANSACTIONS_QUERY_KEY]);
    await queryInvalidate([config.BUSINESS_ACCOUNT_BALANCE_QUERY_KEY, businessAccountId]);
  };

  const createTransactionMutation = useMutation({
    mutationFn: transactionService.createTransaction,
    onSuccess: invalidateTransactionQueries,
    successNotification: lang.get('transaction.modal.createSuccess'),
  });

  const updateTransactionMutation = useMutation({
    mutationFn: (values: FormValues) => transactionService.updateTransaction(transactionId, values),
    onSuccess: invalidateTransactionQueries,
    successNotification: lang.get('transaction.modal.updateSuccess'),
  });

  const approveTransactionMutation = useMutation({
    mutationFn: () => transactionService.approveTransaction(transactionId),
    onSuccess: invalidateTransactionQueries,
    successNotification: lang.get('transaction.modal.approveSuccess'),
  });

  const rejectTransactionMutation = useMutation({
    mutationFn: () => transactionService.rejectTransaction(transactionId),
    onSuccess: invalidateTransactionQueries,
    successNotification: lang.get('transaction.modal.rejectSuccess'),
  });

  const handleBusinessAccountChange = (businessAccountId: Uuid) => setBusinessAccountId(businessAccountId);

  const handleBusinessAccountSearch = async (query: string) => {
    const { list } = await businessAccountService.getBusinessAccounts({ search: query || undefined });

    return list.map((businessAccount) => ({
      value: businessAccount.id,
      label: businessAccount.name,
    }));
  };

  const handleSubmit = async (values: FormValues) => {
    transaction
      ? await updateTransactionMutation.mutateAsync(values)
      : await createTransactionMutation.mutateAsync(values);

    onClose();
  };

  const handleApprove = async () => {
    await approveTransactionMutation.mutateAsync();

    onClose();
  };

  const handleReject = async () => {
    await rejectTransactionMutation.mutateAsync();

    onClose();
  };

  useEffect(() => {
    if (open) {
      const clientId = transaction?.client.id || businessAccount?.id;

      form.setFieldsValue({
        ...transaction,
        clientId,
        id: transaction?.transferId || transaction?.id,
        currency: transaction?.currency || initialValues.currency,
        referenceId: transaction?.clientReferenceId,
        createdById: transaction?.createdBy?.id,
        updatedById: transaction?.updatedBy?.id,
      });

      setBusinessAccountId(clientId);
      setCurrentBusinessAccount(businessAccount);
    } else {
      setBusinessAccountId(undefined);
      setCurrentBusinessAccount(undefined);
    }
  }, [businessAccount, transaction, open, form]);

  useEffect(() => {
    if (!businessAccountQuery.isFetching && businessAccountQuery.data) {
      setCurrentBusinessAccount(businessAccountQuery.data);
    }
  }, [form, businessAccountQuery.isFetching, businessAccountQuery.data]);

  useEffect(() => {
    if (!businessAccountBalanceQuery.isFetching && businessAccountBalanceQuery.data) {
      form.setFieldsValue({ currency: businessAccountBalanceQuery.data.currency });
    }
  }, [form, businessAccountBalanceQuery.isFetching, businessAccountBalanceQuery.data]);

  const isTransfer = Boolean(transaction?.transferId);
  const isPendingStatus = transaction?.status === TransactionStatus.PENDING;
  const isEditing = Boolean(transaction);
  const isEditable = !isTransfer && isPendingStatus;

  let title = isTransfer
    ? lang.get('transaction.modal.transferTitle')
    : lang.get('transaction.modal.title');

  if (currentBusinessAccount) {
    title = isTransfer
      ? lang.get('transaction.modal.businessAccountTransferTitle', { name: currentBusinessAccount.name })
      : lang.get('transaction.modal.businessAccountTitle', { name: currentBusinessAccount.name });
  }

  return (
    <Modal
      title={title}
      caption={currentBusinessAccount && <Balance businessAccountId={currentBusinessAccount.id} />}
      okText={isEditing ? lang.get('common.save') : lang.get('transaction.modal.submit')}
      cancelText={isEditing && !isEditable ? lang.get('common.close') : null}
      okButtonProps={{ hidden: isEditing && !isEditable }}
      open={open}
      confirmLoading={createTransactionMutation.isPending || updateTransactionMutation.isPending}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onFinish={handleSubmit}
      >

        <Form.ActionsItem hidden={!isEditable}>
          <PopconfirmButton
            title={lang.get('transaction.modal.approveTitle')}
            type="primary"
            ghost
            loading={approveTransactionMutation.isPending}
            onConfirm={handleApprove}
          >
            {lang.get('transaction.modal.actions.approve')}
          </PopconfirmButton>
          <PopconfirmButton
            title={lang.get('transaction.modal.rejectTitle')}
            danger
            loading={rejectTransactionMutation.isPending}
            onConfirm={handleReject}
          >
            {lang.get('transaction.modal.actions.reject')}
          </PopconfirmButton>
        </Form.ActionsItem>

        <Form.Divider hidden={!isEditable} />

        <Form.Item
          name="clientId"
          label={lang.get('transaction.modal.businessAccount.label')}
          hidden={Boolean(businessAccount || transaction)}
        >
          <Select.Ajax
            placeholder={lang.get('transaction.modal.businessAccount.placeholder')}
            onChange={handleBusinessAccountChange}
            onSearch={handleBusinessAccountSearch}
          />
        </Form.Item>
        <Form.Item label={lang.get('transaction.modal.amount.label')}>
          <Space.Compact block>
            <Form.Item name="amount" noStyle>
              <Input.Number placeholder={lang.get('transaction.modal.amount.placeholder')} readOnly={isEditing && !isEditable} />
            </Form.Item>
            <Form.Item name="currency" noStyle>
              <Select.Currency className={styles.modal__currency} disabled />
            </Form.Item>
          </Space.Compact>
        </Form.Item>
        <Form.Columns hidden={!isTransfer}>
          <Form.Item name="transactionFee" label={lang.get('transaction.modal.transactionFee.label')}>
            <Input.Number suffix={currentCurrency ?? ' '} readOnly />
          </Form.Item>
          <Form.Item name="fxMarkupFee" label={lang.get('transaction.modal.fxMarkup.label')}>
            <Input.Number suffix={currentCurrency ?? ' '} readOnly />
          </Form.Item>
        </Form.Columns>
        <Form.Item name="providerFee" label={lang.get('transaction.modal.providerFee.label')} hidden={!isTransfer}>
          <Input.Number suffix={currentCurrency ?? ' '} readOnly />
        </Form.Item>

        <Form.Divider hidden={!isTransfer} />

        <Form.Item name="id" label={lang.get('transaction.modal.id.label')} hidden={!isEditing}>
          <Input placeholder={lang.get('transaction.modal.id.placeholder')} readOnly />
        </Form.Item>
        <Form.Columns hidden={!isTransfer}>
          <Form.Item name="externalId" label={lang.get('transaction.modal.externalId.label')}>
            <Input placeholder={lang.get('transaction.modal.externalId.placeholder')} readOnly />
          </Form.Item>
          <Form.Item name="providerReferenceId" label={lang.get('transaction.modal.providerReferenceId.label')}>
            <Input placeholder={lang.get('transaction.modal.providerReferenceId.placeholder')} readOnly />
          </Form.Item>
        </Form.Columns>
        <Form.Item name="clientReferenceId" label={lang.get('transaction.modal.clientReferenceId.label')} hidden={!isTransfer}>
          <Input placeholder={lang.get('transaction.modal.clientReferenceId.placeholder')} readOnly={isEditing && !isEditable} />
        </Form.Item>
        <Form.Item name="referenceId" label={lang.get('transaction.modal.referenceId.label')} hidden={isTransfer}>
          <Input placeholder={lang.get('transaction.modal.referenceId.placeholder')} readOnly={isEditing && !isEditable} />
        </Form.Item>
        <Form.Item name="notes" label={lang.get('common.form.notes.label')}>
          <TextArea placeholder={lang.get('common.form.notes.placeholder')} readOnly={isEditing && !isEditable} />
        </Form.Item>
        <Form.Columns hidden={isTransfer}>
          <Form.Item name="createdById" label={lang.get('transaction.modal.createdBy.label')}>
            <UserSelect disabled />
          </Form.Item>
          <Form.Item name="updatedById" label={lang.get('transaction.modal.updatedBy.label')}>
            <UserSelect disabled />
          </Form.Item>
        </Form.Columns>

      </Form>
    </Modal>
  );
};

export default TransactionModal;

import React, { FC } from 'react';

import { BaseLayout } from 'layouts';
import { BaseLayoutProps } from 'types/layouts';

import Content from './Content';
import Header from './Header';
import Layout from './Layout';

const DashboardLayout: FC<BaseLayoutProps> = ({ children, ...props }) => (
  <BaseLayout {...props}>
    <Layout>
      <Header />
      <Content>{children}</Content>
    </Layout>
  </BaseLayout>
);

export default DashboardLayout;

import React, { ChangeEventHandler } from 'react';
import { useNavigate } from 'react-router-dom';

import { config } from 'data';
import { url } from 'helpers';
import { useLang, useTable, useTableQuery } from 'hooks';
import { useBusinessAccountsQuery } from 'hooks/queries';
import { DashboardLayout } from 'layouts';
import { Eye } from 'components/icons';
import { TableView } from 'components/layout';
import { Flex, Select, Table, Tag } from 'components/ui';
import { SelectChangeHandler, TableColumns } from 'types/components';
import { BusinessAccount, BusinessAccountApiStatus, BusinessAccountStatus, BusinessAccountType } from 'types/models';

import styles from './styles.module.css';

type TableParams = {
  search: string;
  type: '' | BusinessAccountType;
  status: '' | BusinessAccountStatus;
  apiStatus: '' | BusinessAccountApiStatus;
};

const initialTableParams: TableParams = {
  search: '',
  type: '',
  status: BusinessAccountStatus.ACTIVE,
  apiStatus: '',
};

const BusinessAccountsPage = () => {
  const lang = useLang();
  const navigate = useNavigate();
  const table = useTable<BusinessAccount, TableParams>([config.BUSINESS_ACCOUNTS_QUERY_KEY], initialTableParams);

  const businessAccountsQuery = useBusinessAccountsQuery({
    page: table.page,
    search: table.params.search || undefined,
    type: table.params.type || undefined,
    status: table.params.status || undefined,
    enabledApi: table.params.apiStatus ? table.params.apiStatus === BusinessAccountApiStatus.ENABLED : undefined,
  });

  const handleFilterSearchChange: ChangeEventHandler<HTMLInputElement> = (event) => table.setParam('search', event.target.value);

  const handleFilterTypeChange: SelectChangeHandler = (value) => table.setParam('type', value);

  const handleFilterStatusChange: SelectChangeHandler = (value) => table.setParam('status', value);

  const handleFilterApiStatusChange: SelectChangeHandler = (value) => table.setParam('apiStatus', value);

  const handleViewClick = (businessAccount: BusinessAccount) => () => navigate(url.toBusinessAccount(businessAccount.id));

  useTableQuery(table, businessAccountsQuery);

  const columns: TableColumns<BusinessAccount> = [
    {
      className: styles.table__name,
      key: 'name',
      title: lang.get('businessAccount.list.name'),
      render: (_, businessAccount) => <Table.Truncate>{businessAccount.name}</Table.Truncate>,
    }, {
      key: 'type',
      title: lang.get('businessAccount.list.type'),
      render: (_, businessAccount) => lang.get(`businessAccount.types.${businessAccount.type.toLowerCase()}`),
    }, {
      className: styles.table__id,
      key: 'id',
      title: lang.get('businessAccount.list.id'),
      render: (_, businessAccount) => businessAccount.id,
    }, {
      key: 'status',
      title: lang.get('businessAccount.list.status'),
      render: (_, businessAccount) => (
        <Tag color={businessAccount.status === BusinessAccountStatus.ACTIVE ? 'success' : 'default'}>
          {lang.get(`businessAccount.statuses.${businessAccount.status.toLowerCase()}`)}
        </Tag>
      ),
    }, {
      key: 'apiAccess',
      title: lang.get('businessAccount.list.apiAccess'),
      render: (_, businessAccount) => (
        <Tag color={businessAccount.enabledApi ? 'success' : 'default'}>
          {lang.get(businessAccount.enabledApi ? 'businessAccount.apiStatuses.enabled' : 'businessAccount.apiStatuses.disabled')}
        </Tag>
      ),
    }, {
      key: 'actions',
      title: lang.get('common.list.actions'),
      render: (_, user) => (
        <Table.Actions
          buttons={[{
            title: lang.get('common.view'),
            icon: <Eye />,
            onClick: handleViewClick(user),
          }]}
        />
      ),
    },
  ];

  return (
    <DashboardLayout title={lang.get('businessAccount.list.title')}>
      <TableView
        title={lang.get('businessAccount.list.title')}
        filters={(
          <Flex gap="small" align="center" wrap="wrap">
            <TableView.Search
              defaultValue={table.params.search}
              onChange={handleFilterSearchChange}
            />
            <Select
              defaultValue={table.params.type}
              options={[{
                value: '',
                label: lang.get('businessAccount.filters.type'),
              }].concat(Object.values(BusinessAccountType).map((type) => ({
                value: type,
                label: lang.get(`businessAccount.types.${type.toLowerCase()}`),
              })))}
              popupMatchSelectWidth={false}
              onChange={handleFilterTypeChange}
            />
            <Select
              defaultValue={table.params.status}
              options={[{
                value: '',
                label: lang.get('businessAccount.filters.status'),
              }].concat(Object.values(BusinessAccountStatus).map((status) => ({
                value: status,
                label: lang.get(`businessAccount.statuses.${status.toLowerCase()}`),
              })))}
              popupMatchSelectWidth={false}
              onChange={handleFilterStatusChange}
            />
            <Select
              defaultValue={table.params.apiStatus}
              options={[{
                value: '',
                label: lang.get('businessAccount.filters.apiStatus'),
              }].concat(Object.values(BusinessAccountApiStatus).map((status) => ({
                value: status,
                label: lang.get(`businessAccount.apiStatuses.${status.toLowerCase()}`),
              })))}
              popupMatchSelectWidth={false}
              onChange={handleFilterApiStatusChange}
            />
          </Flex>
        )}
      >
        <Table<BusinessAccount>
          columns={columns}
          dataSource={table.data}
          pagination={table.pagination}
          rowKey={(businessAccount) => businessAccount.id}
          loading={businessAccountsQuery.isFetching}
          clickable
          onRow={(businessAccount) => ({ onClick: handleViewClick(businessAccount) })}
          onChange={table.onChange}
        />
      </TableView>
    </DashboardLayout>
  );
};

export default BusinessAccountsPage;

import React, { FC } from 'react';

import { IconProps } from 'types/components';

import Icon from './Icon';

const TopUp: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path d="M13.333 16.667H15.5a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2h-13m0 0V5.333a2 2 0 0 1 2-2h13m-15 3.334v8a2 2 0 0 0 2 2H8a2 2 0 0 0 2-2V10m0 0-2.5 2.5M10 10l2.5 2.5" />
  </Icon>
);

export default TopUp;

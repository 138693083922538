import React from 'react';

import { useLang } from 'hooks';
import { DashboardLayout } from 'layouts';
import { SinglePage, TransactionsView } from 'components/layout';

const BusinessAccountTransactionsPage = () => {
  const lang = useLang();

  return (
    <SinglePage.BusinessAccount render={(businessAccount) => (
      <DashboardLayout title={lang.get('businessAccount.transactions.title', { name: businessAccount.name })}>
        <TransactionsView businessAccount={businessAccount} />
      </DashboardLayout>
    )} />
  );
};

export default BusinessAccountTransactionsPage;

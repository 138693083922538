import { axios } from 'data';
import { Uuid } from 'types/common';

import {
  BusinessAccountApi,
  BusinessAccountBalance,
  BusinessAccountExtended,
  BusinessAccountFee,
  CurrencyCode,
} from 'types/models';

import {
  BusinessAccountAddMemberParams,
  BusinessAccountCurrenciesResponse,
  BusinessAccountMembersResponse,
  BusinessAccountRolesResponse,
  BusinessAccountsParams,
  BusinessAccountsResponse,
  BusinessAccountUpdateApiParams,
  BusinessAccountUpdateCurrenciesParams,
  BusinessAccountUpdateFeeParams,
  BusinessAccountUpdateMemberRolesParams,
} from 'types/services';

import { preparePaginatableParams, preparePaginatedData } from './helpers';

const getBusinessAccounts = async (params: BusinessAccountsParams = {}) => {
  const response = await axios.get<BusinessAccountsResponse>('/core/admin/business-profile', {
    params: preparePaginatableParams(params),
  });

  return preparePaginatedData(response.data);
};

const getBusinessAccount = async (id: Uuid) => {
  const response = await axios.get<BusinessAccountExtended>(`/core/admin/business-profile/${id}`);

  return response.data;
};

const activateBusinessAccount = async (id: Uuid) => {
  await axios.patch<never>(`/core/admin/business-profile/${id}/activate`);
};

const deactivateBusinessAccount = async (id: Uuid) => {
  await axios.patch<never>(`/core/admin/business-profile/${id}/deactivate`);
};

const getBusinessAccountApi = async (id: Uuid) => {
  const response = await axios.get<BusinessAccountApi>(`/payments/admin/client/${id}`);

  return response.data;
};

const updateBusinessAccountApi = async (id: Uuid, params: BusinessAccountUpdateApiParams) => {
  await axios.patch<never>(`/payments/admin/client/${id}`, params);
};

const enableBusinessAccountApi = async (id: Uuid) => {
  await axios.put<never>(`/core/admin/business-profile/${id}/enable-api`);
};

const disableBusinessAccountApi = async (id: Uuid) => {
  await axios.put<never>(`/core/admin/business-profile/${id}/disable-api`);
};

const resetBusinessAccountCredentials = async (id: Uuid) => {
  await axios.post<never>(`/payments/admin/client/${id}/reset-credentials`);
};

const getBusinessAccountBalance = async (id: Uuid) => {
  const response = await axios.get<BusinessAccountBalance>(`/payments/admin/client/${id}`);

  return response.data;
};

const getBusinessAccountCurrencies = async (id: Uuid) => {
  const response = await axios.get<BusinessAccountCurrenciesResponse>(`/payments/admin/client/${id}`);

  return response.data.currencies;
};

const updateBusinessAccountCurrencies = async (id: Uuid, codes: CurrencyCode[]) => {
  const params: BusinessAccountUpdateCurrenciesParams = { currencies: codes };

  await axios.patch<never>(`/payments/admin/client/${id}`, params);
};

const getBusinessAccountFee = async (id: Uuid) => {
  const response = await axios.get<BusinessAccountFee>(`/payments/admin/client/${id}`);

  return response.data;
};

const updateBusinessAccountFee = async (id: Uuid, params: BusinessAccountUpdateFeeParams) => {
  await axios.patch<never>(`/payments/admin/client/${id}`, params);
};

const getMembers = async (accountId: Uuid) => {
  const response = await axios.get<BusinessAccountMembersResponse>(`/core/admin/business-profile/${accountId}/members`);

  return response.data.list;
};

const addMember = async (accountId: Uuid, clientId: Uuid, params: BusinessAccountAddMemberParams) => {
  await axios.post<never>(`/core/admin/business-profile/${accountId}/member/${clientId}`, params);
};

const updateMemberRoles = async (accountId: Uuid, clientId: Uuid, params: BusinessAccountUpdateMemberRolesParams) => {
  await axios.put<never>(`/core/admin/business-profile/${accountId}/member/${clientId}/roles`, params);
};

const removeMember = async (accountId: Uuid, clientId: Uuid) => {
  await axios.delete<never>(`/core/admin/business-profile/${accountId}/member/${clientId}`);
};

const getRoles = async () => {
  const response = await axios.get<BusinessAccountRolesResponse>('/core/role/client');

  return response.data.list;
};

const businessAccountService = {
  getBusinessAccounts,
  getBusinessAccount,
  activateBusinessAccount,
  deactivateBusinessAccount,
  getBusinessAccountApi,
  updateBusinessAccountApi,
  enableBusinessAccountApi,
  disableBusinessAccountApi,
  resetBusinessAccountCredentials,
  getBusinessAccountBalance,
  getBusinessAccountCurrencies,
  updateBusinessAccountCurrencies,
  getBusinessAccountFee,
  updateBusinessAccountFee,
  getMembers,
  addMember,
  updateMemberRoles,
  removeMember,
  getRoles,
};

export default businessAccountService;

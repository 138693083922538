import React, { ChangeEventHandler, FC, useEffect, useState } from 'react';

import { config } from 'data';
import { formatter } from 'helpers';
import { useLang, useModal, useTable, useTableQuery } from 'hooks';
import { useProviderQuery, useProvidersQuery } from 'hooks/queries';
import { Eye } from 'components/icons';
import { ActivityStatus, TableView } from 'components/layout';
import { Flex, Modal, Select, Table } from 'components/ui';
import { Uuid } from 'types/common';
import { ModalBaseProps, SelectChangeHandler, TableColumns } from 'types/components';
import { ActivityStatus as TActivityStatus, ProviderSimple } from 'types/models';

import styles from './styles.module.css';

import SingleModal from './SingleModal';

type TableParams = {
  search: string;
  status: '' | TActivityStatus;
};

const initialTableParams: TableParams = {
  search: '',
  status: '',
};

const ProvidersModal: FC<ModalBaseProps> = ({ open, onClose }) => {
  const lang = useLang();
  const modal = useModal();
  const table = useTable<ProviderSimple, TableParams>([config.PROVIDERS_QUERY_KEY], initialTableParams);

  const [providerId, setProviderId] = useState<Uuid>();

  const activeProvidersQuery = useProvidersQuery({ active: true });
  const providerQuery = useProviderQuery(providerId);

  const providersQuery = useProvidersQuery({
    page: table.page,
    search: table.params.search || undefined,
    active: table.params.status ? table.params.status === TActivityStatus.ACTIVE : undefined,
  });

  const handleFilterSearchChange: ChangeEventHandler<HTMLInputElement> = (event) => table.setParam('search', event.target.value);

  const handleFilterStatusChange: SelectChangeHandler = (value) => table.setParam('status', value);

  const handleViewClick = (provider: ProviderSimple) => () => {
    setProviderId(provider.id);

    modal.open();
  };

  useTableQuery(table, providersQuery);

  useEffect(() => {
    if (!open) {
      modal.close();
    }
  }, [open, modal]);

  const columns: TableColumns<ProviderSimple> = [
    {
      className: styles.table__name,
      key: 'name',
      title: lang.get('provider.list.name'),
      render: (_, provider) => <Table.Truncate>{provider.name}</Table.Truncate>,
    }, {
      className: styles.table__date,
      key: 'date',
      title: lang.get('provider.list.date'),
      render: (_, provider) => formatter.formatDateTime(provider.updatedAt ?? provider.createdAt),
    }, {
      key: 'status',
      title: lang.get('provider.list.status'),
      render: (_, provider) => <ActivityStatus active={provider.active} />,
    }, {
      key: 'actions',
      title: lang.get('common.list.actions'),
      render: (_, provider) => (
        <Table.Actions
          buttons={[{
            title: lang.get('common.view'),
            icon: <Eye />,
            loading: providerQuery.isFetching,
            onClick: handleViewClick(provider),
          }]}
        />
      ),
    },
  ];

  return (
    <Modal
      cancelText={lang.get('common.close')}
      okButtonProps={{ hidden: true }}
      open={open}
      onCancel={onClose}
    >

      <TableView
        title={lang.get('provider.list.title')}
        caption={lang.choice('provider.list.caption', activeProvidersQuery.data?.total ?? 0)}
        filters={(
          <Flex gap="small" align="center" wrap="wrap">
            <TableView.Search
              defaultValue={table.params.search}
              onChange={handleFilterSearchChange}
            />
            <Select
              defaultValue={table.params.status}
              options={[{
                value: '',
                label: lang.get('common.activityStatuses.all'),
              }].concat(Object.values(TActivityStatus).map((status) => ({
                value: status,
                label: lang.get(`common.activityStatuses.${status.toLowerCase()}`),
              })))}
              popupMatchSelectWidth={false}
              onChange={handleFilterStatusChange}
            />
          </Flex>
        )}
      >
        <Table<ProviderSimple>
          columns={columns}
          dataSource={table.data}
          pagination={table.pagination}
          rowKey={(provider) => provider.id}
          loading={providersQuery.isFetching}
          clickable
          onRow={(provider) => ({ onClick: handleViewClick(provider) })}
          onChange={table.onChange}
        />
      </TableView>

      {providerQuery.data && (
        <SingleModal
          provider={providerQuery.data}
          open={!providerQuery.isLoading && modal.opened}
          onClose={modal.close}
        />
      )}

    </Modal>
  );
};

export default ProvidersModal;

import React, { FC } from 'react';
import { Empty as BaseEmpty, EmptyProps } from 'antd';

const Empty: FC<EmptyProps> = ({
  image = BaseEmpty.PRESENTED_IMAGE_SIMPLE,
  ...props
}) => (
  <BaseEmpty
    image={image}
    {...props}
  />
);

export default Empty;

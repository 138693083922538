import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { yup } from 'data';
import { url } from 'helpers';
import { authService } from 'services';
import { NewPasswordRequiredException } from 'services/exceptions';
import { useForm, useLang, useMutation } from 'hooks';
import { PageLayout } from 'layouts';
import { Button, Flex, Form, Input } from 'components/ui';

type FormValues = {
  email: string;
  password: string;
};

const initialValues: FormValues = {
  email: '',
  password: '',
};

const validationSchema = yup.object({
  email: yup.string().required().email(),
  password: yup.string().required(),
});

const LoginPage = () => {
  const form = useForm<FormValues>();
  const lang = useLang();
  const navigate = useNavigate();

  const loginMutation = useMutation({
    mutationFn: async (values: FormValues) => {
      try {
        await authService.login(values);
      } catch (error) {
        if (error instanceof NewPasswordRequiredException) {
          return navigate(url.toPasswordChange(uuid()), { state: error.data });
        }

        throw error;
      }
    },
  });

  return (
    <PageLayout
      title={lang.get('login.title')}
      heading={lang.get('login.heading')}
      caption={lang.get('login.caption')}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onFinish={loginMutation.mutateAsync}
      >
        <Form.Item name="email" label={lang.get('common.form.email.label')}>
          <Input type="email" placeholder={lang.get('common.form.email.placeholder')} />
        </Form.Item>
        <Form.Item name="password" label={lang.get('common.form.password.label')}>
          <Input type="password" placeholder={lang.get('common.form.password.placeholder')} />
        </Form.Item>
        <Form.Item>
          <Flex gap="small" vertical>
            <Button htmlType="submit" block loading={loginMutation.isPending}>
              {lang.get('login.submit')}
            </Button>
            <Link to={url.toPasswordReset()}>
              <Button type="link" block>
                {lang.get('login.forgot')}
              </Button>
            </Link>
          </Flex>
        </Form.Item>
      </Form>
    </PageLayout>
  );
};

export default LoginPage;

import React, { FC, ReactNode } from 'react';
import cx from 'classnames';

import { useLang } from 'hooks';
import { Override } from 'types/common';
import { FormItemProps } from 'types/components';

import Flex from '../Flex';

import styles from './styles.module.css';

import Item from './Item';

type ActionsItemProps = Override<FormItemProps, {
  children: ReactNode;
}>

const ActionsItem: FC<ActionsItemProps> = ({
  className,
  label,
  layout = 'horizontal',
  children,
  ...props
}) => {
  const lang = useLang();

  return (
    <Item
      className={cx(styles.actions, className)}
      label={label ?? lang.get('common.form.actions')}
      layout={layout}
      {...props}
    >
      <Flex gap="small" align="center" wrap="wrap">{children}</Flex>
    </Item>
  );
};

export default ActionsItem;

import kebabCase from 'lodash/kebabCase';
import * as XLSX from 'xlsx';

import { XlsxHeader, XlsxRow } from 'types/common';

const exportFile = (name: string, headers: XlsxHeader[], rows: XlsxRow[]) => {
  const worksheet = XLSX.utils.json_to_sheet(rows);
  const workbook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(workbook, worksheet);
  XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' });

  XLSX.writeFile(workbook, kebabCase(name) + '.xlsx');
};

const xlsx = {
  exportFile,
};

export default xlsx;

import React, { FC, useEffect } from 'react';

import { config, moment, yup } from 'data';
import { feeCommissionService } from 'services';
import { useForm, useFormWatch, useLang, useMutation, useQueryInvalidate } from 'hooks';
import { useAllFeeGroupsQuery } from 'hooks/queries';
import { Trash } from 'components/icons';
import { DateRangePicker, Form, Input, Modal, PopconfirmButton, Select, TextArea } from 'components/ui';
import { DateRangePickerValue, ModalBaseProps } from 'types/components';
import { FeeCommission, FeeCommissionLevel, TransactionFeeType } from 'types/models';
import { FeeCommissionParams } from 'types/services';

type FeeCommissionModalProps = ModalBaseProps & {
  feeCommission?: FeeCommission;
};

type FormValues = FeeCommissionParams & {
  period?: DateRangePickerValue;
};

const levels = Object.values(FeeCommissionLevel);
const feeTypes = Object.values(TransactionFeeType);

const initialValues: Omit<
  FormValues,
  | 'level'
  | 'transactionFee'
  | 'fxMarkup'
  | 'minTransactionFeeAmount'
  | 'maxTransactionFeeAmount'
> = {
  name: '',
  transactionFeeType: TransactionFeeType.PERCENT,
  currency: config.DEFAULT_CURRENCY,
};

const validationSchema = yup.object().shape({
  feeGroupId: yup
    .string()
    .notRequired()
    .uuid()
    .when('level', ([level], schema) => level === FeeCommissionLevel.GROUP ? schema.required() : schema),
  name: yup.string().required().trim().max(config.STRING_MAX_LENGTH),
  level: yup.string().required().oneOf(levels),
  transactionFeeType: yup.string().required().oneOf(feeTypes),
  transactionFee: yup
    .number()
    .required()
    .decimal()
    .transform((value) => value ? value : 0)
    .when('transactionFeeType', ([type], schema) => {
      if (type === TransactionFeeType.PERCENT) {
        return schema.percent();
      }

      return schema.min(config.FEE_COMMISSION_MIN).max(config.FEE_COMMISSION_MAX);
    }),
  fxMarkup: yup
    .number()
    .required()
    .percent()
    .decimal()
    .transform((value) => value ? value : 0),
  minTransactionFeeAmount: yup
    .number()
    .required()
    .decimal()
    .min(config.FEE_COMMISSION_MIN)
    .max(config.FEE_COMMISSION_MAX)
    .transform((value) => value ? value : 0)
    .when(['transactionFeeType', 'maxTransactionFeeAmount'], ([type, max], schema) => {
      if (type !== TransactionFeeType.PERCENT) {
        return schema.notRequired();
      }

      return max ? schema.max(max) : schema;
    }),
  maxTransactionFeeAmount: yup
    .number()
    .required()
    .decimal()
    .min(config.FEE_COMMISSION_MIN)
    .max(config.FEE_COMMISSION_MAX)
    .transform((value) => value ? value : 0)
    .when(['transactionFeeType', 'minTransactionFeeAmount'], ([type, min], schema) => {
      if (type !== TransactionFeeType.PERCENT) {
        return schema.notRequired();
      }

      return min ? schema.min(min) : schema;
    }),
  currency: yup.string().required().currency(),
  description: yup.string().notRequired().trim().max(config.TEXT_MAX_LENGTH),
}, [['minTransactionFeeAmount', 'maxTransactionFeeAmount']]);

const FeeCommissionModal: FC<FeeCommissionModalProps> = ({
  feeCommission,
  open,
  onClose,
}) => {
  const form = useForm<FormValues>();
  const currentLevel = useFormWatch('level', form);
  const currentFeeType = useFormWatch('transactionFeeType', form);
  const currentCurrency = useFormWatch('currency', form);
  const lang = useLang();
  const queryInvalidate = useQueryInvalidate();

  const isGroupLevel = currentLevel === FeeCommissionLevel.GROUP;
  const isPercentFeeType = currentFeeType === TransactionFeeType.PERCENT;
  const feeCommissionId = feeCommission?.id ?? '';
  const feeCommissionName = feeCommission?.name ?? '';

  const feeGroupsQuery = useAllFeeGroupsQuery();

  const invalidateFeeCommissionQueries = async () => {
    await queryInvalidate([config.FEE_COMMISSIONS_QUERY_KEY]);
    await queryInvalidate([config.FEE_GROUPS_QUERY_KEY]);
  };

  const createFeeCommissionMutation = useMutation({
    mutationFn: feeCommissionService.createFeeCommission,
    onSuccess: invalidateFeeCommissionQueries,
    successNotification: lang.get('feeCommission.modal.createSuccess'),
  });

  const updateFeeCommissionMutation = useMutation({
    mutationFn: (values: FormValues) => feeCommissionService.updateFeeCommission(feeCommissionId, values),
    onSuccess: invalidateFeeCommissionQueries,
    successNotification: lang.get('feeCommission.modal.updateSuccess', { name: feeCommissionName }),
  });

  const activateFeeCommissionMutation = useMutation({
    mutationFn: () => feeCommissionService.activateFeeCommission(feeCommissionId),
    onSuccess: invalidateFeeCommissionQueries,
    successNotification: lang.get('feeCommission.modal.activateSuccess', { name: feeCommissionName }),
  });

  const deactivateFeeCommissionMutation = useMutation({
    mutationFn: () => feeCommissionService.deactivateFeeCommission(feeCommissionId),
    onSuccess: invalidateFeeCommissionQueries,
    successNotification: lang.get('feeCommission.modal.deactivateSuccess', { name: feeCommissionName }),
  });

  const deleteFeeCommissionMutation = useMutation({
    mutationFn: () => feeCommissionService.deleteFeeCommission(feeCommissionId),
    onSuccess: invalidateFeeCommissionQueries,
    successNotification: lang.get('feeCommission.modal.deleteSuccess', { name: feeCommissionName }),
  });

  const handleSubmit = async (values: FormValues) => {
    const [startDate, endDate] = values.period ?? [];

    values.startDate = startDate ? startDate.startOf('day').toISOString() : null;
    values.endDate = endDate ? endDate.endOf('day').toISOString() : null;

    delete values.period;

    feeCommission
      ? await updateFeeCommissionMutation.mutateAsync(values)
      : await createFeeCommissionMutation.mutateAsync(values);

    onClose();
  };

  const handleActivate = async () => {
    await activateFeeCommissionMutation.mutateAsync();

    onClose();
  };

  const handleDeactivate = async () => {
    await deactivateFeeCommissionMutation.mutateAsync();

    onClose();
  };

  const handleDelete = async () => {
    await deleteFeeCommissionMutation.mutateAsync();

    onClose();
  };

  useEffect(() => {
    if (open && feeCommission) {
      form.setFieldsValue({
        ...feeCommission,
        period: [
          feeCommission.startDate && moment(feeCommission.startDate),
          feeCommission.endDate && moment(feeCommission.endDate),
        ],
      });
    }
  }, [feeCommission, open, form]);

  useEffect(() => {
    if (form.isFieldTouched('level')) {
      form.resetFields(['feeGroupId']);
    }
  }, [form, isGroupLevel]);

  useEffect(() => {
    if (form.isFieldTouched('transactionFeeType')) {
      form.resetFields(['transactionFee', 'minTransactionFeeAmount', 'maxTransactionFeeAmount']);
    }
  }, [form, isPercentFeeType]);

  const isEditing = Boolean(feeCommission);

  return (
    <Modal
      title={
        isEditing
          ? lang.get('feeCommission.modal.updateTitle', { name: feeCommissionName })
          : lang.get('feeCommission.modal.createTitle')
      }
      caption={
        isEditing
          ? lang.get('feeCommission.modal.updateCaption')
          : lang.get('feeCommission.modal.createCaption')
      }
      okText={isEditing ? lang.get('common.save') : lang.get('common.create')}
      extraActions={isEditing && (
        <PopconfirmButton
          title={lang.get('feeCommission.modal.deleteTitle')}
          icon={<Trash />}
          danger
          loading={deleteFeeCommissionMutation.isPending}
          onConfirm={handleDelete}
        >
          {lang.get('common.delete')}
        </PopconfirmButton>
      )}
      width="sm"
      open={open}
      confirmLoading={createFeeCommissionMutation.isPending || updateFeeCommissionMutation.isPending}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onFinish={handleSubmit}
      >

        <Form.ActionsItem hidden={!isEditing}>
          {feeCommission?.active ? (
            <PopconfirmButton
              title={lang.get('feeCommission.modal.deactivateTitle')}
              danger
              loading={deactivateFeeCommissionMutation.isPending}
              onConfirm={handleDeactivate}
            >
              {lang.get('feeCommission.modal.actions.deactivate')}
            </PopconfirmButton>
          ) : (
            <PopconfirmButton
              title={lang.get('feeCommission.modal.activateTitle')}
              type="primary"
              ghost
              loading={activateFeeCommissionMutation.isPending}
              onConfirm={handleActivate}
            >
              {lang.get('feeCommission.modal.actions.activate')}
            </PopconfirmButton>
          )}
        </Form.ActionsItem>

        <Form.Divider hidden={!isEditing} />

        <Form.Item name="name" label={lang.get('common.form.name.label')}>
          <Input placeholder={lang.get('common.form.name.placeholder')} />
        </Form.Item>
        <Form.Item name="level" label={lang.get('feeCommission.modal.level.label')}>
          <Select
            placeholder={lang.get('feeCommission.modal.level.placeholder')}
            options={levels.map((level) => ({
              value: level,
              label: lang.get(`feeCommission.levels.${level.toLowerCase()}`),
            }))}
          />
        </Form.Item>
        <Form.Item name="feeGroupId" label={lang.get('feeCommission.modal.group.label')} hidden={!isGroupLevel}>
          <Select
            placeholder={lang.get('feeCommission.modal.group.placeholder')}
            options={feeGroupsQuery.data?.map((group) => ({
              value: group.id,
              label: group.name,
            }))}
            loading={feeGroupsQuery.isFetching}
          />
        </Form.Item>
        <Form.Item name="currency" label={lang.get('common.form.currency.label')}>
          <Select.Currency disabled />
        </Form.Item>
        <Form.Item name="transactionFeeType" label={lang.get('feeCommission.modal.transactionFeeType.label')}>
          <Select
            placeholder={lang.get('feeCommission.modal.transactionFeeType.placeholder')}
            options={feeTypes.map((type) => ({
              value: type,
              label: lang.get(`feeCommission.feeTypes.${type.toLowerCase()}`),
            }))}
          />
        </Form.Item>
        <Form.Columns>
          <Form.Item name="transactionFee" label={lang.get('feeCommission.modal.transactionFee.label')}>
            <Input.Number placeholder={lang.get('feeCommission.modal.transactionFee.placeholder')} suffix={isPercentFeeType ? '%' : currentCurrency} />
          </Form.Item>
          <Form.Item name="fxMarkup" label={lang.get('feeCommission.modal.fxMarkup.label')}>
            <Input.Number placeholder={lang.get('feeCommission.modal.fxMarkup.placeholder')} suffix="%" />
          </Form.Item>
        </Form.Columns>
        <Form.Columns>
          <Form.Item
            name="minTransactionFeeAmount"
            label={lang.get('feeCommission.modal.minTransactionFeeAmount.label')}
            hidden={!isPercentFeeType}
          >
            <Input.Number placeholder={lang.get('feeCommission.modal.minTransactionFeeAmount.placeholder')} suffix={currentCurrency ?? ' '} />
          </Form.Item>
          <Form.Item
            name="maxTransactionFeeAmount"
            label={lang.get('feeCommission.modal.maxTransactionFeeAmount.label')}
            hidden={!isPercentFeeType}
          >
            <Input.Number placeholder={lang.get('feeCommission.modal.maxTransactionFeeAmount.placeholder')} suffix={currentCurrency ?? ' '} />
          </Form.Item>
        </Form.Columns>
        <Form.Item name="period" label={lang.get('feeCommission.modal.period.label')}>
          <DateRangePicker minDate={moment().startOf('day')} />
        </Form.Item>
        <Form.Item name="description" label={lang.get('common.form.description.label')}>
          <TextArea placeholder={lang.get('common.form.description.placeholder')} />
        </Form.Item>

      </Form>
    </Modal>
  );
};

export default FeeCommissionModal;

import React, { FC, useEffect } from 'react';
import map from 'lodash/map';

import { config, yup } from 'data';
import { businessAccountService } from 'services';
import { useForm, useLang, useMutation, useQueryInvalidate } from 'hooks';
import { useAllFeeCommissionsQuery, useAllFeeGroupsQuery, useBusinessAccountFeeQuery } from 'hooks/queries';
import { ActivityStatus } from 'components/layout';
import { Flex, Form, Modal, Select } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { BusinessAccount, FeeCommissionLevel } from 'types/models';
import { BusinessAccountUpdateFeeParams } from 'types/services';

type FeeModalProps = ModalBaseProps & {
  businessAccount: BusinessAccount;
};

type FormValues = BusinessAccountUpdateFeeParams;

const initialValues: FormValues = {
  feeGroupId: null,
  feeCommissionIds: [],
};

const validationSchema = yup.object({
  feeGroupId: yup.string().notRequired().uuid().default(initialValues.feeGroupId),
  feeCommissionIds: yup.array().required().of(
    yup.string().required().uuid(),
  ),
});

const FeeModal: FC<FeeModalProps> = ({
  businessAccount,
  open,
  onClose,
}) => {
  const form = useForm<FormValues>();
  const lang = useLang();
  const queryInvalidate = useQueryInvalidate();

  const businessAccountFeeQuery = useBusinessAccountFeeQuery(businessAccount.id);
  const feeGroupsQuery = useAllFeeGroupsQuery();
  const feeCommissionsQuery = useAllFeeCommissionsQuery({ level: FeeCommissionLevel.CLIENT });

  const updateBusinessAccountFeeMutation = useMutation({
    mutationFn: (values: FormValues) => businessAccountService.updateBusinessAccountFee(businessAccount.id, values),
    onSuccess: () => queryInvalidate([config.BUSINESS_ACCOUNT_FEE_QUERY_KEY, businessAccount.id]),
    successNotification: lang.get('businessAccount.fee.modal.success', { name: businessAccount.name }),
  });

  const handleSubmit = async (values: FormValues) => {
    await updateBusinessAccountFeeMutation.mutateAsync(values);

    onClose();
  };

  useEffect(() => {
    if (open && !businessAccountFeeQuery.isFetching && businessAccountFeeQuery.data) {
      form.setFieldsValue({
        ...businessAccountFeeQuery.data,
        feeCommissionIds: map(businessAccountFeeQuery.data.feeCommissions, 'id'),
      });
    }
  }, [open, form, businessAccountFeeQuery.isFetching, businessAccountFeeQuery.data]);

  return (
    <Modal
      title={lang.get('businessAccount.fee.modal.title', { name: businessAccount.name })}
      caption={lang.get('businessAccount.fee.modal.caption')}
      okText={lang.get('common.save')}
      width="sm"
      open={open}
      confirmLoading={updateBusinessAccountFeeMutation.isPending}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onFinish={handleSubmit}
      >
        <Form.Item name="feeGroupId" label={lang.get('businessAccount.fee.modal.feeGroup.label')}>
          <Select
            placeholder={lang.get('businessAccount.fee.modal.feeGroup.placeholder')}
            options={feeGroupsQuery.data?.map((group) => ({
              value: group.id,
              label: group.name,
            }))}
            loading={feeGroupsQuery.isFetching}
            allowClear
          />
        </Form.Item>
        <Form.Item name="feeCommissionIds" label={lang.get('businessAccount.fee.modal.feeCommissions.label')}>
          <Select
            placeholder={lang.get('businessAccount.fee.modal.feeCommissions.placeholder')}
            options={feeCommissionsQuery.data?.map((feeCommission) => ({
              value: feeCommission.id,
              label: (
                <Flex align="center" gap="small" wrap="wrap">
                  <span>{feeCommission.name}</span>
                  <ActivityStatus active={feeCommission.active} hidden={feeCommission.active} />
                </Flex>
              ),
            }))}
            mode="multiple"
            loading={feeCommissionsQuery.isFetching}
            allowClear
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FeeModal;

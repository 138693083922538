import React, { FC } from 'react';

import { IconProps } from 'types/components';

import Icon from './Icon';

const PieChart: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path d="M17.68 13.24a8.33 8.33 0 1 1-11-10.88M18.33 10A8.33 8.33 0 0 0 10 1.67V10Z" />
  </Icon>
);

export default PieChart;

import { useCallback, useMemo, useState } from 'react';
import { useSessionStorage } from '@uidotdev/usehooks';

import { config } from 'data';
import { PrimitiveType } from 'types/common';
import { TableChangeHandler, TableData, TablePagination } from 'types/components';

const STORAGE_KEY_PREFIX = 'table:';

type TableKey = PrimitiveType[];

type TableParams = Record<string, unknown>;

type TableState<DataType, ParamsType extends TableParams> = {
  pagination: TablePagination<DataType>;
  params: ParamsType;
};

const prepareStorageKey = (key: TableKey) => STORAGE_KEY_PREFIX + key.filter(Boolean).join(':');

const useTable = <
  DataType,
  ParamsType extends TableParams = TableParams
>(
  key: TableKey,
  initialParams: ParamsType = {} as ParamsType,
) => {
  const [exporting, setExporting] = useState(false);
  const [data, setData] = useState<TableData<DataType>>([]);

  const [state, saveState] = useSessionStorage<TableState<DataType, ParamsType>>(prepareStorageKey(key), {
    pagination: false,
    params: initialParams,
  });

  const setPagination = useCallback((pagination: TablePagination<DataType>) => {
    if (pagination) {
      pagination.current = pagination.current || 1;
      pagination.pageSize = pagination.pageSize || config.DEFAULT_PAGINATION_LIMIT;
      pagination.total = pagination.total || 0;

      let lastPage = pagination.total / pagination.pageSize;

      lastPage = Math.ceil(lastPage);
      lastPage = Math.max(lastPage, 1);

      if (pagination.current > lastPage) {
        pagination.current = lastPage;
      }
    }

    saveState((prevState) => ({ ...prevState, pagination }));
  }, [saveState]);

  const setParams = useCallback(<Key extends string & keyof ParamsType>(params: Record<Key, ParamsType[Key]>) => {
    saveState((prevState) => ({
      ...prevState,
      pagination: { ...prevState?.pagination, current: 1 },
      params: { ...prevState?.params, ...params },
    }));
  }, [saveState]);

  const setParam = useCallback(<Key extends string & keyof ParamsType>(key: Key, value: ParamsType[Key]) => {
    setParams({ [key]: value } as Record<Key, ParamsType[Key]>);
  }, [setParams]);

  const handleChange = useCallback<TableChangeHandler<DataType>>((pagination) => {
    saveState((prevState) => ({ ...prevState, pagination }));
  }, [saveState]);

  return useMemo(() => ({
    exporting,
    data,
    pagination: state.pagination ?? false,
    page: state.pagination ? state.pagination.current : 1,
    params: state.params ?? initialParams,
    setExporting,
    setData,
    setPagination,
    setParam,
    setParams,
    onChange: handleChange,
  }), [initialParams, exporting, data, state, setPagination, setParam, setParams, handleChange]);
};

export default useTable;

import React, { FC } from 'react';

import { IconProps } from 'types/components';

import Icon from './Icon';

const Logout: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path d="M7.5 17.5H4.167A1.667 1.667 0 0 1 2.5 15.833V4.167A1.667 1.667 0 0 1 4.167 2.5H7.5m5.833 11.667L17.5 10m0 0-4.167-4.167M17.5 10h-10" />
  </Icon>
);

export default Logout;

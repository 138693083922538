import React, { FC, useEffect } from 'react';
import map from 'lodash/map';

import { config, yup } from 'data';
import { userService } from 'services';
import { useForm, useLang, useMutation, useQueryInvalidate } from 'hooks';
import { Form, Modal } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { User } from 'types/models';
import { UserUpdateRolesParams } from 'types/services';

import RoleSelect from './RoleSelect';

type RolesModalProps = ModalBaseProps & {
  user: User;
};

type FormValues = UserUpdateRolesParams;

const initialValues: FormValues = {
  roleIds: [],
};

const validationSchema = yup.object({
  roleIds: yup.array().required().min(1).of(
    yup.string().required().uuid(),
  ),
});

const RolesModal: FC<RolesModalProps> = ({
  user,
  open,
  onClose,
}) => {
  const form = useForm<FormValues>();
  const lang = useLang();
  const queryInvalidate = useQueryInvalidate();

  const invalidateUserQueries = async () => {
    await queryInvalidate([config.USERS_QUERY_KEY]);
    await queryInvalidate([config.USER_QUERY_KEY, user.id]);
  };

  const updateUserRolesMutation = useMutation({
    mutationFn: (values: FormValues) => userService.updateUserRoles(user.id, values),
    onSuccess: invalidateUserQueries,
    successNotification: lang.get('user.rolesModal.success', { email: user.email }),
  });

  const handleSubmit = async (values: FormValues) => {
    await updateUserRolesMutation.mutateAsync(values);

    onClose();
  };

  useEffect(() => {
    if (open && user) {
      form.setFieldsValue({ roleIds: map(user.roles, 'id') });
    }
  }, [user, open, form]);

  return (
    <Modal
      title={lang.get('user.rolesModal.title', { email: user.email })}
      caption={lang.get('user.rolesModal.caption')}
      okText={lang.get('common.save')}
      width="sm"
      open={open}
      confirmLoading={updateUserRolesMutation.isPending}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onFinish={handleSubmit}
      >
        <Form.Item name="roleIds" label={lang.get('user.modal.roles.label')}>
          <RoleSelect mode="multiple" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RolesModal;

import React, { FC } from 'react';
import { LayoutProps } from 'antd';

import { Layout as BaseLayout } from 'components/ui';

const Layout: FC<LayoutProps> = (props) => (
  <BaseLayout {...props} />
);

export default Layout;

import React, { FC, useState } from 'react';
import debounce from 'lodash/debounce';
import cx from 'classnames';

import { Search } from 'components/icons';
import { Override } from 'types/common';
import { SelectProps as BaseSelectProps } from 'types/components';

import Spin from '../Spin';

import styles from './styles.module.css';

import Select from '.';

type SelectOptions = NonNullable<BaseSelectProps['options']>;

type SelectProps = Override<BaseSelectProps, {
  onSearch: (query: string) => Promise<SelectOptions>;
}>;

const AjaxSelect: FC<SelectProps> = ({
  className,
  suffixIcon = <Search />,
  onSearch,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<SelectOptions>([]);

  const handleSearch = async (query: string) => {
    setOptions([]);
    setLoading(true);

    try {
      const options = await onSearch(query);

      setOptions(options);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Select
      {...props}
      className={cx(styles.ajax, className)}
      options={options}
      notFoundContent={loading ? <Spin size="small" /> : null}
      suffixIcon={suffixIcon}
      showSearch
      loading={loading}
      onSearch={debounce(handleSearch, 500)}
    />
  );
};

export default AjaxSelect;

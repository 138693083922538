import React from 'react';

import { useLang } from 'hooks';
import { DashboardLayout } from 'layouts';
import { SinglePage } from 'components/layout';
import { Grid } from 'components/ui';

import Members from './Members';
import View from './View';

const BusinessAccountPage = () => {
  const lang = useLang();

  return (
    <SinglePage.BusinessAccount render={(businessAccount) => (
      <DashboardLayout title={lang.get('businessAccount.single.title', { name: businessAccount.name })}>
        <Grid.Row gutter={[8, 8]}>
          <Grid.Col span={24}>
            <View businessAccount={businessAccount} />
          </Grid.Col>
          <Grid.Col span={24}>
            <Members businessAccount={businessAccount} />
          </Grid.Col>
        </Grid.Row>
      </DashboardLayout>
    )} />
  );
};

export default BusinessAccountPage;

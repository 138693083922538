import React, { FC } from 'react';

import { IconProps } from 'types/components';

import Icon from './Icon';

const Calendar: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path d="M13.333 1.667V5M6.667 1.667V5M2.5 8.333h15m-13.333-5h11.666c.92 0 1.667.747 1.667 1.667v11.667c0 .92-.746 1.666-1.667 1.666H4.167c-.92 0-1.667-.746-1.667-1.666V5c0-.92.746-1.667 1.667-1.667Z" />
  </Icon>
);

export default Calendar;

import React, { FC } from 'react';

import { Override } from 'types/common';
import { ButtonProps, PopconfirmProps } from 'types/components';

import Button from '../Button';
import Popconfirm from '../Popconfirm';

type PopconfirmButtonProps = Override<ButtonProps, Pick<PopconfirmProps, 'title' | 'description' | 'onConfirm'>>;

const PopconfirmButton: FC<PopconfirmButtonProps> = ({
  title,
  description,
  type = 'default',
  danger,
  disabled,
  loading,
  onConfirm,
  ...props
}) => (
  <Popconfirm
    title={title}
    description={description}
    okButtonProps={{ danger, loading }}
    cancelButtonProps={{ danger }}
    disabled={disabled}
    onConfirm={onConfirm}
  >
    <Button
      type={type}
      danger={danger}
      disabled={disabled}
      {...props}
    />
  </Popconfirm>
);

export default PopconfirmButton;

import React, { FC } from 'react';

import { useLang } from 'hooks';
import { useAllCurrenciesQuery } from 'hooks/queries';
import { SelectProps } from 'types/components';

import Select from '.';

const CurrencySelect: FC<SelectProps> = ({
  placeholder,
  showSearch = true,
  ...props
}) => {
  const lang = useLang();

  const currenciesQuery = useAllCurrenciesQuery();

  return (
    <Select
      {...props}
      placeholder={placeholder ?? lang.get('common.form.currency.placeholder')}
      options={currenciesQuery.data?.map((currency) => ({
        value: currency.code,
        label: currency.code,
      }))}
      showSearch={showSearch}
      loading={currenciesQuery.isFetching}
    />
  );
};

export default CurrencySelect;

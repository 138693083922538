import { addMethod, StringSchema } from 'yup';

import { config } from 'data';

import { createError } from '../helpers';

addMethod(StringSchema, 'email', function validate() {
  const EMAIL_REGEXP = /^[\da-z.+_-]+@[\da-z-]+(\.[\da-z]{2,})+$/i;

  return this.trim()
    .lowercase()
    .max(config.EMAIL_MAX_LENGTH)
    .test((value, context) => {
      if (!value || value.match(EMAIL_REGEXP)) {
        return true;
      }

      return createError(context, 'email');
    });
});

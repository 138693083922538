import { config } from 'data';
import { providerService } from 'services';
import { useQuery } from 'hooks';
import { ProvidersParams } from 'types/services';

const useProvidersQuery = (params?: ProvidersParams) => useQuery({
  queryKey: [config.PROVIDERS_QUERY_KEY, params],
  queryFn: () => providerService.getProviders(params),
});

export default useProvidersQuery;

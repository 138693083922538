import React, { FC } from 'react';

import { IconProps } from 'types/components';

import Icon from './Icon';

const ChevronDown: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path d="m5 7.5 5 5 5-5" />
  </Icon>
);

export default ChevronDown;

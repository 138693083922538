import React, { FC, useEffect, useState } from 'react';

import { config } from 'data';
import { formatter } from 'helpers';
import { useLang, useModal, useTable, useTableQuery } from 'hooks';
import { useFeeGroupsQuery } from 'hooks/queries';
import { Eye, Plus } from 'components/icons';
import { TableView } from 'components/layout';
import { Button, Modal, Table } from 'components/ui';
import { ModalBaseProps, TableColumns } from 'types/components';
import { FeeGroup } from 'types/models';

import styles from './styles.module.css';

import SingleModal from './SingleModal';

const FeeGroupsModal: FC<ModalBaseProps> = ({ open, onClose }) => {
  const lang = useLang();
  const modal = useModal();
  const table = useTable<FeeGroup>([config.FEE_GROUPS_QUERY_KEY]);

  const [feeGroup, setFeeGroup] = useState<FeeGroup>();

  const feeGroupsQuery = useFeeGroupsQuery({ page: table.page });

  const handleCreateClick = () => {
    setFeeGroup(undefined);

    modal.open();
  };

  const handleViewClick = (feeGroup: FeeGroup) => () => {
    setFeeGroup(feeGroup);

    modal.open();
  };

  useTableQuery(table, feeGroupsQuery);

  useEffect(() => {
    if (!open) {
      modal.close();
    }
  }, [open, modal]);

  const columns: TableColumns<FeeGroup> = [
    {
      className: styles.table__name,
      key: 'name',
      title: lang.get('feeGroup.list.name'),
      render: (_, feeGroup) => <Table.Truncate>{feeGroup.name}</Table.Truncate>,
    }, {
      className: styles.table__date,
      key: 'date',
      title: lang.get('feeGroup.list.date'),
      render: (_, feeGroup) => formatter.formatDateTime(feeGroup.updatedAt ?? feeGroup.createdAt),
    }, {
      key: 'actions',
      title: lang.get('common.list.actions'),
      render: (_, feeGroup) => (
        <Table.Actions
          buttons={[{
            title: lang.get('common.view'),
            icon: <Eye />,
            onClick: handleViewClick(feeGroup),
          }]}
        />
      ),
    },
  ];

  return (
    <Modal
      cancelText={lang.get('common.close')}
      okButtonProps={{ hidden: true }}
      open={open}
      onCancel={onClose}
    >

      <TableView
        title={lang.get('feeGroup.list.title')}
        caption={lang.choice('feeGroup.list.caption', feeGroupsQuery.data?.total ?? 0)}
        actions={(
          <Button icon={<Plus />} onClick={handleCreateClick}>
            {lang.get('feeGroup.list.create')}
          </Button>
        )}
      >
        <Table<FeeGroup>
          columns={columns}
          dataSource={table.data}
          pagination={table.pagination}
          rowKey={(feeGroup) => feeGroup.id}
          loading={feeGroupsQuery.isFetching}
          clickable
          onRow={(feeGroup) => ({ onClick: handleViewClick(feeGroup) })}
          onChange={table.onChange}
        />
      </TableView>

      <SingleModal
        feeGroup={feeGroup}
        open={modal.opened}
        onClose={modal.close}
      />

    </Modal>
  );
};

export default FeeGroupsModal;

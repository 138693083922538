import React, { FC } from 'react';

import { IconProps } from 'types/components';

import Icon from './Icon';

const ArrowRight: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path d="M4.168 10h11.667m0 0L10 4.167M15.835 10 10 15.833" />
  </Icon>
);

export default ArrowRight;

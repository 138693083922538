import { axios } from 'data';
import { Uuid } from 'types/common';
import { TransactionStatus } from 'types/models';

import {
  TransactionCreateParams,
  TransactionsParams,
  TransactionsResponse,
  TransactionUpdateParams,
} from 'types/services';

import { preparePaginatableParams, preparePaginatedData, prepareTimezonableParams } from './helpers';

const getTransactions = async (params: TransactionsParams = {}) => {
  params = prepareTimezonableParams(params);
  params = preparePaginatableParams(params);

  const response = await axios.get<TransactionsResponse>('/payments/admin/transaction', { params });

  return preparePaginatedData(response.data);
};

const createTransaction = async (params: TransactionCreateParams) => {
  await axios.post<never>('/payments/admin/transaction', params);
};

const updateTransaction = async (id: Uuid, params: TransactionUpdateParams) => {
  await axios.patch<never>(`/payments/admin/transaction/${id}`, params);
};

const approveTransaction = async (id: Uuid) => {
  await updateTransaction(id, { status: TransactionStatus.COMPLETED });
};

const rejectTransaction = async (id: Uuid) => {
  await updateTransaction(id, { status: TransactionStatus.CANCELED });
};

const transactionService = {
  getTransactions,
  createTransaction,
  updateTransaction,
  approveTransaction,
  rejectTransaction,
};

export default transactionService;

import React, { FC } from 'react';

import { useLang } from 'hooks';
import { Tag } from 'components/ui';
import { Override } from 'types/common';
import { TagProps } from 'types/components';

type ActivityStatusProps = Override<Omit<TagProps, 'color' | 'children'>, {
  active: boolean;
}>;

const ActivityStatus: FC<ActivityStatusProps> = ({ active, ...props }) => {
  const lang = useLang();

  return (
    <Tag color={active ? 'success' : 'default'} {...props}>
      {lang.get(active ? 'common.activityStatuses.active' : 'common.activityStatuses.inactive')}
    </Tag>
  );
};

export default ActivityStatus;

import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { url } from 'helpers';
import { useAuth, usePreloader } from 'hooks';
import { PrivateRoute, PublicRoute } from 'components/routes';

import {
  AuditLogsPage,
  AuthPage,
  BusinessAccountDailyBalancePage,
  BusinessAccountDailyTransactionsPage,
  BusinessAccountPage,
  BusinessAccountsPage,
  BusinessAccountTransactionsPage,
  ClientsPage,
  CostManagementPage,
  CurrenciesPage,
  DailyRemittancePage,
  DashboardPage,
  ErrorPage,
  FeeManagementPage,
  LoginPage,
  PasswordChangePage,
  PasswordResetPage,
  TransactionsPage,
  UsersPage,
} from 'pages';

const router = createBrowserRouter([
  {
    errorElement: <ErrorPage />,
    children: [
      {
        path: url.toAuth(':hash'),
        element: <AuthPage />,
      }, {
        path: url.toPasswordChange(':hash'),
        element: <PasswordChangePage />,
      }, {
        path: url.toLogin(),
        element: <PublicRoute component={<LoginPage />} />,
      }, {
        path: url.toPasswordReset(),
        element: <PublicRoute component={<PasswordResetPage />} />,
      }, {
        path: url.toDashboard(),
        element: <PrivateRoute component={<DashboardPage />} />,
      }, {
        path: url.toClients(),
        element: <PrivateRoute component={<ClientsPage />} />,
      }, {
        path: url.toBusinessAccounts(),
        element: <PrivateRoute component={<BusinessAccountsPage />} />,
      }, {
        path: url.toBusinessAccount(':id'),
        element: <PrivateRoute component={<BusinessAccountPage />} />,
      }, {
        path: url.toBusinessAccountTransactions(':id'),
        element: <PrivateRoute component={<BusinessAccountTransactionsPage />} />,
      }, {
        path: url.toBusinessAccountDailyBalance(':id'),
        element: <PrivateRoute component={<BusinessAccountDailyBalancePage />} />,
      }, {
        path: url.toBusinessAccountDailyTransactions(':id'),
        element: <PrivateRoute component={<BusinessAccountDailyTransactionsPage />} />,
      }, {
        path: url.toTransactions(),
        element: <PrivateRoute component={<TransactionsPage />} />,
      }, {
        path: url.toDailyRemittance(),
        element: <PrivateRoute component={<DailyRemittancePage />} />,
      }, {
        path: url.toAuditLogs(),
        element: <PrivateRoute component={<AuditLogsPage />} />,
      }, {
        path: url.toCostManagement(),
        element: <PrivateRoute component={<CostManagementPage />} />,
      }, {
        path: url.toCurrencies(),
        element: <PrivateRoute component={<CurrenciesPage />} />,
      }, {
        path: url.toFeeManagement(),
        element: <PrivateRoute component={<FeeManagementPage />} />,
      }, {
        path: url.toUsers(),
        element: <PrivateRoute component={<UsersPage />} />,
      },
    ],
  },
]);

const AppProvider = () => {
  const auth = useAuth();
  const preloader = usePreloader();

  useEffect(() => {
    preloader.toggle(!auth.initialized);
  }, [auth.initialized, preloader]);

  return auth.initialized && <RouterProvider router={router} />;
};

const App = {
  Provider: AppProvider,
};

export default App;

import { Uuid } from 'types/common';

const toAuditLogs = () => toSettings('/audit-logs');

const toAuth = (hash: Uuid) => `/auth/${hash}`;

const toBusinessAccounts = (path = '') => toDashboard(`/business-accounts${path}`);

const toBusinessAccount = (id: Uuid, path = '') => toBusinessAccounts(`/${id}${path}`);

const toBusinessAccountDailyBalance = (id: Uuid) => toBusinessAccountReports(id, '/daily-balance');

const toBusinessAccountDailyTransactions = (id: Uuid) => toBusinessAccountReports(id, '/daily-transactions');

const toBusinessAccountReports = (id: Uuid, path: string) => toBusinessAccount(id, `/reports${path}`);

const toBusinessAccountTransactions = (id: Uuid) => toBusinessAccount(id, '/transactions');

const toClients = () => toDashboard('/clients');

const toCostManagement = () => toSettings('/cost-management');

const toCurrencies = () => toSettings('/currencies');

const toDailyRemittance = () => toReports('/daily-remittance');

const toDashboard = (path = '') => `/dashboard${path}`;

const toFeeManagement = () => toSettings('/fee-management');

const toLogin = () => '/';

const toPasswordChange = (hash: Uuid) => `/password-change/${hash}`;

const toPasswordReset = () => '/password-reset';

const toReports = (path: string) => toDashboard(`/reports${path}`);

const toSettings = (path = '') => toDashboard(`/settings${path}`);

const toTransactions = () => toDashboard('/transactions');

const toUsers = () => toSettings('/users');

const url = {
  toAuditLogs,
  toAuth,
  toBusinessAccounts,
  toBusinessAccount,
  toBusinessAccountDailyBalance,
  toBusinessAccountDailyTransactions,
  toBusinessAccountTransactions,
  toClients,
  toCostManagement,
  toCurrencies,
  toDailyRemittance,
  toDashboard,
  toFeeManagement,
  toLogin,
  toPasswordChange,
  toPasswordReset,
  toTransactions,
  toUsers,
};

export default url;

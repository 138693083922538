import React, { FC, ReactNode } from 'react';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';

import Text from '../Text';

type DiffViewerProps = {
  leftTitle?: ReactNode;
  rightTitle?: ReactNode;
  oldValue: unknown;
  newValue: unknown;
};

const DiffViewer: FC<DiffViewerProps> = ({
  leftTitle,
  rightTitle,
  oldValue,
  newValue,
}) => (
  <ReactDiffViewer
    leftTitle={leftTitle ? <Text strong>{leftTitle}</Text> : ''}
    rightTitle={rightTitle ? <Text strong>{rightTitle}</Text> : ''}
    oldValue={oldValue ? JSON.stringify(oldValue, null, 2) : ''}
    newValue={newValue ? JSON.stringify(newValue, null, 2) : ''}
    compareMethod={DiffMethod.JSON}
    styles={{
      variables: {
        light: {
          diffViewerBackground: 'var(--color-light)',
          diffViewerColor: 'var(--color-dark)',
          addedBackground: 'var(--color-success-lightest)',
          addedColor: 'var(--color-dark)',
          removedBackground: 'var(--color-error-lightest)',
          removedColor: 'var(--color-dark)',
          wordAddedBackground: 'transparent',
          wordRemovedBackground: 'transparent',
          addedGutterBackground: 'var(--color-success-lightest)',
          removedGutterBackground: 'var(--color-error-lightest)',
          gutterBackground: 'var(--color-gray-100)',
          gutterBackgroundDark: 'var(--color-gray-200)',
          emptyLineBackground: 'var(--color-gray-100)',
          gutterColor: 'var(--color-dark)',
          addedGutterColor: 'var(--color-dark)',
          removedGutterColor: 'var(--color-dark)',
          diffViewerTitleBackground: 'var(--color-gray-100)',
          diffViewerTitleColor: 'var(--color-dark)',
          diffViewerTitleBorderColor: 'var(--color-gray-200)',
        },
      },
      diffContainer: {
        pre: {
          fontSize: '0.85em',
          lineHeight: '1.5em',
          wordBreak: 'break-all',
        },
      },
    }}
    showDiffOnly={false}
    hideMarkers
  />
);

export default DiffViewer;

import { config } from 'data';
import { transactionService } from 'services';
import { useQuery } from 'hooks';
import { TransactionsParams } from 'types/services';

const useTransactionsQuery = (params?: TransactionsParams) => useQuery({
  queryKey: [config.TRANSACTIONS_QUERY_KEY, params],
  queryFn: () => transactionService.getTransactions(params),
});

export default useTransactionsQuery;

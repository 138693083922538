import { useEffect } from 'react';
import { AxiosError } from 'axios';

import {
  DefaultError,
  useMutation as useBaseMutation,
  UseMutationOptions as UseBaseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';

import { Override } from 'types/common';

import useLang from './lang';
import useTheme from './theme';

const KEY = 'mutation';

type UseMutationOptions<
  TData = unknown,
  TError extends DefaultError = AxiosError,
  TVariables = void,
  TContext = unknown,
> = Override<UseBaseMutationOptions<TData, TError, TVariables, TContext>, {
  successNotification?: string;
}>;

type UseMutation = <
  TData = unknown,
  TError extends DefaultError = AxiosError,
  TVariables = void,
  TContext = unknown,
>(options: UseMutationOptions<TData, TError, TVariables, TContext>) => UseMutationResult<TData, TError, TVariables, TContext>;

const useMutation: UseMutation = (options) => {
  const lang = useLang();
  const theme = useTheme();

  const mutation = useBaseMutation({
    ...options,
    onSuccess: (...args) => {
      if (options.successNotification) {
        theme.notification?.success({
          message: lang.get('common.mutation.success'),
          description: options.successNotification,
        });
      }

      if (options.onSuccess) {
        options.onSuccess(...args);
      }
    },
  });

  useEffect(() => {
    if (mutation.isPending) {
      theme.message?.loading({
        key: KEY,
        content: lang.get('common.mutation.loading'),
        duration: 0,
      });
    } else {
      theme.message?.destroy(KEY);
    }

    return () => {
      theme.message?.destroy(KEY);
    };
  }, [lang, theme.message, mutation.isPending]);

  useEffect(() => {
    if (mutation.isError) {
      theme.notification?.error({
        message: lang.get('common.mutation.error'),
        description: mutation.error.message,
        onClose: mutation.reset,
      });
    }
  }, [lang, theme.notification, mutation.isError, mutation.error, mutation.reset]);

  return mutation;
};

export default useMutation;

import { addMethod, MixedSchema } from 'yup';

import { file } from 'helpers';
import { UploadFile } from 'types/components';

import { createError } from '../helpers';

addMethod(MixedSchema<UploadFile>, 'fileExtension', function validate(extensions: string[]) {
  const EXTENSION_REGEXP = new RegExp(`\\.${extensions.join('|')}$`, 'i');

  return this.test((value, context) => {
    if (!file.isUploadFile(value) || value.name.match(EXTENSION_REGEXP)) {
      return true;
    }

    return createError(context, 'fileExtension', {
      extensions: extensions.join(', '),
    });
  });
});

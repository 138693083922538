import { axios } from 'data';
import { Profile } from 'types/models';
import { ProfileUpdateParams, ProfileUpdatePasswordParams } from 'types/services';

const getProfile = async () => {
  const response = await axios.get<Profile>('/core/admin/profile');

  return response.data;
};

const updateProfile = async (params: ProfileUpdateParams) => {
  await axios.patch<never>('/core/admin/profile', params);
};

const updatePassword = async (oldPassword: string, newPassword: string) => {
  const params: ProfileUpdatePasswordParams = { oldPassword, newPassword };

  await axios.post<never>('/core/admin/profile/change-password', params);
};

const profileService = {
  getProfile,
  updateProfile,
  updatePassword,
};

export default profileService;

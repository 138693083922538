import React, { FC } from 'react';
import { Upload as BaseUpload, UploadProps as BaseUploadProps } from 'antd';
import cx from 'classnames';

import { useLang } from 'hooks';
import { Upload } from 'components/icons';
import { Override } from 'types/common';

import Button from '../Button';

import styles from './styles.module.css';

type UploadProps = Override<Omit<BaseUploadProps, 'accept'>, {
  extensions?: string[];
}>;

const UploadInput: FC<UploadProps> = ({
  className,
  maxCount = 1,
  extensions = [],
  beforeUpload = () => false,
  ...props
}) => {
  const lang = useLang();

  return (
    <BaseUpload
      className={cx(styles.upload, className)}
      maxCount={maxCount}
      accept={`.${extensions.join(',.')}`}
      multiple={maxCount !== 1}
      beforeUpload={beforeUpload}
      {...props}
    >
      <Button
        className={styles.upload__button}
        type="default"
        icon={<Upload />}
        block
      >
        {lang.choice('common.form.upload.files', maxCount)}
      </Button>
    </BaseUpload>
  );
};

export default UploadInput;

import { config } from 'data';
import { currencyService } from 'services';
import { useQuery } from 'hooks';
import { CurrencyRatesParams } from 'types/services';

const useCurrencyRatesQuery = (params?: CurrencyRatesParams) => useQuery({
  queryKey: [config.CURRENCY_RATES_QUERY_KEY, params],
  queryFn: () => currencyService.getCurrencyRates(params),
});

export default useCurrencyRatesQuery;

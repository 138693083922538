import React, { FC } from 'react';
import { Input as BaseInput } from 'antd';
import cx from 'classnames';

import { InputProps } from 'types/components';

import styles from './styles.module.css';

import Email from './Email';
import Number from './Number';
import Phone from './Phone';
import Upload from './Upload';

const Input: FC<InputProps> = ({
  className,
  size = 'large',
  ...props
}) => (
  <BaseInput
    className={cx(styles.root, className)}
    size={size}
    {...props}
  />
);

export default Object.assign(Input, { Email, Number, Phone, Upload });

import React, { FC } from 'react';

import { useLang } from 'hooks';
import { useAllCountriesQuery } from 'hooks/queries';
import { SelectProps } from 'types/components';

import Select from '.';

const CountrySelect: FC<SelectProps> = ({
  placeholder,
  showSearch = true,
  ...props
}) => {
  const lang = useLang();

  const countriesQuery = useAllCountriesQuery();

  return (
    <Select
      {...props}
      placeholder={placeholder ?? lang.get('common.form.country.placeholder')}
      options={countriesQuery.data?.map((country) => ({
        value: country.code,
        label: country.name,
      }))}
      loading={countriesQuery.isFetching}
      showSearch={showSearch}
    />
  );
};

export default CountrySelect;

import React, { FC, useEffect } from 'react';

import { config, yup } from 'data';
import { feeGroupService } from 'services';
import { useForm, useLang, useMutation, useQueryInvalidate } from 'hooks';
import { Trash } from 'components/icons';
import { Form, Input, Modal, PopconfirmButton, TextArea } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { FeeGroup } from 'types/models';
import { FeeGroupParams } from 'types/services';

type FeeGroupModalProps = ModalBaseProps & {
  feeGroup?: FeeGroup;
};

type FormValues = FeeGroupParams;

const initialValues: FormValues = {
  name: '',
  description: '',
};

const validationSchema = yup.object({
  name: yup.string().required().trim().min(config.STRING_MIN_LENGTH).max(config.STRING_MAX_LENGTH),
  description: yup.string().required().trim().min(config.TEXT_MIN_LENGTH).max(config.TEXT_MAX_LENGTH),
});

const FeeGroupModal: FC<FeeGroupModalProps> = ({
  feeGroup,
  open,
  onClose,
}) => {
  const form = useForm<FormValues>();
  const lang = useLang();
  const queryInvalidate = useQueryInvalidate();

  const feeGroupId = feeGroup?.id ?? '';
  const feeGroupName = feeGroup?.name ?? '';

  const invalidateGroupQueries = async () => {
    await queryInvalidate([config.FEE_GROUPS_QUERY_KEY]);
  };

  const createFeeGroupMutation = useMutation({
    mutationFn: feeGroupService.createFeeGroup,
    onSuccess: invalidateGroupQueries,
    successNotification: lang.get('feeGroup.modal.createSuccess'),
  });

  const updateFeeGroupMutation = useMutation({
    mutationFn: (values: FormValues) => feeGroupService.updateFeeGroup(feeGroupId, values),
    onSuccess: invalidateGroupQueries,
    successNotification: lang.get('feeGroup.modal.updateSuccess', { name: feeGroupName }),
  });

  const deleteFeeGroupMutation = useMutation({
    mutationFn: () => feeGroupService.deleteFeeGroup(feeGroupId),
    onSuccess: invalidateGroupQueries,
    successNotification: lang.get('feeGroup.modal.deleteSuccess', { name: feeGroupName }),
  });

  const handleSubmit = async (values: FormValues) => {
    feeGroup
      ? await updateFeeGroupMutation.mutateAsync(values)
      : await createFeeGroupMutation.mutateAsync(values);

    onClose();
  };

  const handleDelete = async () => {
    await deleteFeeGroupMutation.mutateAsync();

    onClose();
  };

  useEffect(() => {
    if (open && feeGroup) {
      form.setFieldsValue(feeGroup);
    }
  }, [feeGroup, open, form]);

  const isEditing = Boolean(feeGroup);
  const isAssigned = Boolean(feeGroup?.assigned);

  return (
    <Modal
      title={
        isEditing
          ? lang.get('feeGroup.modal.updateTitle', { name: feeGroupName })
          : lang.get('feeGroup.modal.createTitle')
      }
      caption={
        isEditing
          ? lang.get('feeGroup.modal.updateCaption')
          : lang.get('feeGroup.modal.createCaption')
      }
      okText={isEditing ? lang.get('common.save') : lang.get('common.create')}
      extraActions={isEditing && !isAssigned && (
        <PopconfirmButton
          title={lang.get('feeGroup.modal.deleteTitle')}
          icon={<Trash />}
          danger
          loading={deleteFeeGroupMutation.isPending}
          onConfirm={handleDelete}
        >
          {lang.get('common.delete')}
        </PopconfirmButton>
      )}
      width="sm"
      open={open}
      confirmLoading={createFeeGroupMutation.isPending || updateFeeGroupMutation.isPending}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onFinish={handleSubmit}
      >
        <Form.Item name="name" label={lang.get('common.form.name.label')}>
          <Input placeholder={lang.get('common.form.name.placeholder')} />
        </Form.Item>
        <Form.Item name="description" label={lang.get('common.form.description.label')}>
          <TextArea placeholder={lang.get('common.form.description.placeholder')} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FeeGroupModal;

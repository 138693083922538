import React, { FC } from 'react';

import { IconProps } from 'types/components';

import Icon from './Icon';

const WifiOff: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path d="M2.5,2.5l15,15M13.22,9.36a6.93,6.93,0,0,1,1.55,1m-9.54,0A7.36,7.36,0,0,1,8.75,8.75m.37-3.49A10.93,10.93,0,0,1,17.21,8M2.79,8A10.86,10.86,0,0,1,6,6M7.63,12.8a4.09,4.09,0,0,1,4.74,0M10,15.45h0" />
  </Icon>
);

export default WifiOff;

import { addMethod, StringSchema } from 'yup';

import { config } from 'data';

import { createError } from '../helpers';

addMethod(StringSchema, 'password', function validate() {
  // https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
  // - at least one uppercase basic Latin letter
  // - at least one lowercase basic Latin letter
  // - at least one Arabic numeral
  // - at least one special character: ^ $ * . [ ] { } ( ) ? " ! @ # % & / \ , > < ' : ; | _ ~ ` = + -
  // - no leading or trailing spaces
  // - 8-256 characters
  const PASSWORD_REGEXP = /^(?!\s+)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]).{8,256}(?<!\s)$/;

  return this.min(config.PASSWORD_MIN_LENGTH)
    .max(config.PASSWORD_MAX_LENGTH)
    .test((value, context) => {
      if (!value || value.match(PASSWORD_REGEXP)) {
        return true;
      }

      return createError(context, 'password');
    });
});

import React, { FC } from 'react';

import { config, moment } from 'data';
import { formatter, xlsx } from 'helpers';
import { reportService, transactionService } from 'services';
import { fetchPaginatedResponseFully } from 'services/helpers';
import { useLang, useQuery, useTable, useTableQuery } from 'hooks';
import { Download, Table as TableIcon } from 'components/icons';
import { TableView } from 'components/layout';
import { Button, DateRangePicker, Flex, Table } from 'components/ui';
import { XlsxHeader, XlsxRow } from 'types/common';
import { DateRangePickerChangeHandler, TableColumns } from 'types/components';
import { BusinessAccount, ReportDailyTransactionItem, TransactionStatus } from 'types/models';
import { ReportDailyTransactionsParams, TransactionsParams } from 'types/services';

import styles from './styles.module.css';

const EXPORT_FILE_NAME = 'report-daily-transactions';

type TableParams = {
  dateFrom: string;
  dateTo: string;
};

const initialTableParams: TableParams = {
  dateFrom: moment().subtract(1, 'day').startOf('day').toISOString(),
  dateTo: moment().subtract(1, 'day').endOf('day').toISOString(),
};

type DailyTransactionsViewProps = {
  businessAccount: BusinessAccount;
};

const DailyTransactionsView: FC<DailyTransactionsViewProps> = ({ businessAccount }) => {
  const lang = useLang();
  const table = useTable<ReportDailyTransactionItem, TableParams>([config.BUSINESS_ACCOUNT_DAILY_TRANSACTIONS_QUERY_KEY, businessAccount.id], initialTableParams);

  const reportParams: ReportDailyTransactionsParams = {
    clientId: businessAccount.id,
    page: table.page,
    dateFrom: table.params.dateFrom,
    dateTo: table.params.dateTo,
  };

  const reportQuery = useQuery({
    queryKey: [config.BUSINESS_ACCOUNT_DAILY_TRANSACTIONS_QUERY_KEY, reportParams],
    queryFn: () => reportService.getDailyTransactions(reportParams),
  });

  const handleFilterDatesChange: DateRangePickerChangeHandler = (dates) => {
    const [dateFrom, dateTo] = dates ?? [];

    table.setParams({
      dateFrom: dateFrom ? dateFrom.startOf('day').toISOString() : '',
      dateTo: dateTo ? dateTo.endOf('day').toISOString() : '',
    });
  };

  const handleExportClick = async () => {
    try {
      table.setExporting(true);

      const fileName = [
        EXPORT_FILE_NAME,
        businessAccount.name,
        moment(table.params.dateFrom).format(config.DATE_RAW_FORMAT),
        moment(table.params.dateTo).format(config.DATE_RAW_FORMAT),
      ].join('-');

      const data = await fetchPaginatedResponseFully(reportService.getDailyTransactions, reportParams);

      const headers: XlsxHeader[] = [
        lang.get('businessAccount.dailyTransactions.date'),
        lang.get('businessAccount.dailyTransactions.count'),
        lang.get('businessAccount.dailyTransactions.currency'),
        lang.get('businessAccount.dailyTransactions.amount'),
        lang.get('businessAccount.dailyTransactions.fees'),
      ];

      const rows: XlsxRow[] = data.map((item) => ({
        date: formatDateColumn(item),
        count: formatCountColumn(item),
        currency: formatCurrencyColumn(item),
        amount: formatAmountColumn(item),
        fees: formatFeesColumn(item),
      }));

      xlsx.exportFile(fileName, headers, rows);
    } finally {
      table.setExporting(false);
    }
  };

  const handleDetailsExportClick = (item: ReportDailyTransactionItem) => async () => {
    try {
      table.setExporting(true);

      const date = moment(item.date);

      const fileName = [
        EXPORT_FILE_NAME,
        businessAccount.name,
        date.format(config.DATE_RAW_FORMAT),
      ].join('-');

      const params: TransactionsParams = {
        clientId: businessAccount.id,
        dateFrom: date.startOf('day').toISOString(),
        dateTo: date.endOf('day').toISOString(),
        status: TransactionStatus.COMPLETED,
      };

      const data = await fetchPaginatedResponseFully(transactionService.getTransactions, params);

      const headers: XlsxHeader[] = [
        lang.get('businessAccount.dailyTransactions.details.flowId'),
        lang.get('businessAccount.dailyTransactions.details.id'),
        lang.get('businessAccount.dailyTransactions.details.date'),
        lang.get('businessAccount.dailyTransactions.details.beneficiary'),
        lang.get('businessAccount.dailyTransactions.details.currency'),
        lang.get('businessAccount.dailyTransactions.details.amount'),
        lang.get('businessAccount.dailyTransactions.details.transactionFee'),
        lang.get('businessAccount.dailyTransactions.details.fxMarkup'),
        lang.get('businessAccount.dailyTransactions.details.providerFee'),
      ];

      const rows: XlsxRow[] = data.map((transaction) => ({
        flowId: transaction.flowId ?? '-',
        id: transaction.id,
        date: formatter.formatDate(transaction.statusChangedAt ?? transaction.createdAt),
        beneficiary: transaction.beneficiary ?? '-',
        currency: transaction.currency,
        amount: formatter.formatNumber(transaction.amount),
        transactionFee: formatter.formatNumber(transaction.transactionFee),
        fxMarkup: formatter.formatNumber(transaction.fxMarkupFee),
        providerFee: formatter.formatNumber(transaction.providerFee),
      }));

      xlsx.exportFile(fileName, headers, rows);
    } finally {
      table.setExporting(false);
    }
  };

  useTableQuery(table, reportQuery);

  const formatDateColumn = (item: ReportDailyTransactionItem) => formatter.formatDate(item.date);

  const formatCountColumn = (item: ReportDailyTransactionItem) => formatter.formatNumber(item.totalTransactions, 0);

  const formatCurrencyColumn = (item: ReportDailyTransactionItem) => item.currency;

  const formatAmountColumn = (item: ReportDailyTransactionItem) => formatter.formatNumber(item.totalAmount);

  const formatFeesColumn = (item: ReportDailyTransactionItem) => formatter.formatNumber(item.totalFees);

  const columns: TableColumns<ReportDailyTransactionItem> = [
    {
      className: styles.table__date,
      key: 'date',
      title: lang.get('businessAccount.dailyTransactions.date'),
      render: (_, item) => formatDateColumn(item),
    }, {
      key: 'count',
      title: lang.get('businessAccount.dailyTransactions.count'),
      render: (_, item) => formatCountColumn(item),
    }, {
      key: 'currency',
      title: lang.get('businessAccount.dailyTransactions.currency'),
      render: (_, item) => formatCurrencyColumn(item),
    }, {
      className: styles.table__amount,
      key: 'amount',
      title: lang.get('businessAccount.dailyTransactions.amount'),
      render: (_, item) => formatAmountColumn(item),
    }, {
      className: styles.table__balance,
      key: 'fees',
      title: lang.get('businessAccount.dailyTransactions.fees'),
      render: (_, item) => formatFeesColumn(item),
    }, {
      key: 'actions',
      title: lang.get('common.list.actions'),
      render: (_, item) => (
        <Table.Actions
          buttons={[{
            title: lang.get('businessAccount.dailyTransactions.exportDetails'),
            icon: <Download />,
            loading: table.exporting,
            disabled: table.exporting || !table.data.length,
            onClick: handleDetailsExportClick(item),
          }]}
        />
      ),
    },
  ];

  return (
    <TableView
      title={lang.get('businessAccount.dailyTransactions.title', { name: businessAccount.name })}
      actions={(
        <Button
          icon={<TableIcon />}
          loading={table.exporting}
          disabled={!table.data.length}
          onClick={handleExportClick}
        >
          {lang.get('common.exportXlsx')}
        </Button>
      )}
      filters={(
        <Flex gap="small" align="center" justify="flex-end" wrap="wrap">
          <DateRangePicker
            defaultValue={[moment(table.params.dateFrom), moment(table.params.dateTo)]}
            maxDate={moment().subtract(1, 'day').endOf('day')}
            allowClear={false}
            onChange={handleFilterDatesChange}
          />
        </Flex>
      )}
    >
      <Table<ReportDailyTransactionItem>
        columns={columns}
        dataSource={table.data}
        pagination={table.pagination}
        rowKey={(item) => item.date}
        loading={reportQuery.isFetching}
        onChange={table.onChange}
      />
    </TableView>
  );
};

export default DailyTransactionsView;
